import { Snippet } from '@microsoft/applicationinsights-web';
import { LibraryUsageContext } from '../context';
import { LibraryLoggingContext } from './types/library-logging-context';
import { TelemetryConfig } from './types/telemetry-config';
import { LibraryTelemetryConfig } from './library-telemetry-config';
import AppInsightsPortalLogger from './appinsights-portal-logger';
import { ErrorProperties, EventProperties, InformationProperties, KeyValuePair, PerformanceProperties } from './types/telemetry-entry';
import { ErrorInformation } from './types/error-information';

const telemetrySource = 'pcportal-shell-library';
let libraryUsageContext: LibraryUsageContext | undefined;
let libraryLoggingContext: LibraryLoggingContext | undefined;
let appInsightsLogger: AppInsightsPortalLogger | undefined;
let initialSetupCompleted: boolean = false;

/**
 * Used to setup information required when the logging requirements are asked for.
 * @internal
 */
const initialSetup = () => {
  if (initialSetupCompleted) {
    return;
  }
  initialSetupCompleted = true;
  libraryUsageContext = LibraryUsageContext.getInstance();

  libraryLoggingContext = {
    version: libraryUsageContext.Version,
    component: telemetrySource,
    referrer: document.referrer,
    sessionId: libraryUsageContext.SessionId,
    correlationId: libraryUsageContext.CorrelationId,
    appLoadUrl: window.location.href,
    currentUrl: window.location.href,
    locale: libraryUsageContext.Locale,
  };

  const telemetryConfig = LibraryTelemetryConfig.get(libraryUsageContext.Environment) as TelemetryConfig;

  const aiConfig: Snippet = {
    config: {
      instrumentationKey: telemetryConfig.instrumentationKey,
      disableAjaxTracking: true,
      enableUnhandledPromiseRejectionTracking: false,
      disableExceptionTracking: true,
    },
  };

  appInsightsLogger = new AppInsightsPortalLogger();

  appInsightsLogger.registerAiConfig(telemetryConfig.instrumentationKey, libraryUsageContext.Version, libraryUsageContext.CorrelationId, aiConfig);
};

/**
 * This function is called before any telemetry function will be executed.
 * @internal
 */
const updateTelemetryAppContext = () => {
  initialSetup();
  if (libraryLoggingContext) {
    libraryLoggingContext.currentUrl = window.location.href;
  }
};

/**
 * This is used to track Information with application insights.
 * @param message
 * @param customEvents
 * @param severityLevel
 */
export const trackInformation = (message: string, customEvents: KeyValuePair = {}, severityLevel?: number) => {
  updateTelemetryAppContext();

  const data: KeyValuePair = {};
  for (const event in customEvents) {
    data[event] = RemoveSignatureFromJwtToken(customEvents[event]);
  }

  const informationProperties: InformationProperties = {
    severityLevel: severityLevel ?? 3,
    data: JSON.stringify(data),
    ...(libraryLoggingContext as LibraryLoggingContext),
  };

  appInsightsLogger?.trackTrace({ message: message }, informationProperties);
};

/**
 * This is used to track Event with application insights.
 * @param eventName
 * @param customEvents
 */
export const trackEvent = (eventName: string, customEvents: KeyValuePair = {}) => {
  updateTelemetryAppContext();

  const data: KeyValuePair = {};
  for (const event in customEvents) {
    data[event] = RemoveSignatureFromJwtToken(customEvents[event]);
  }

  const eventProperties: EventProperties = {
    data: JSON.stringify(data),
    ...(libraryLoggingContext as LibraryLoggingContext),
  };
  appInsightsLogger?.trackEvent({ name: eventName }, eventProperties);
};

/**
 * This is used to track Error/Exceptions with application insights.
 * @param error
 * @param customEvents
 * @param severityLevel
 */
export const trackError = (error: string | Error, customEvents: ErrorInformation, severityLevel?: number) => {
  updateTelemetryAppContext();

  const data: KeyValuePair = {};
  for (const event in customEvents) {
    data[event] = RemoveSignatureFromJwtToken(customEvents[event]);
  }

  const errorProperties: ErrorProperties = {
    severityLevel: severityLevel || 3,
    data: JSON.stringify(data),
    ...(libraryLoggingContext as LibraryLoggingContext),
  };

  let errorObject: Error;

  if (typeof error === 'string') {
    errorObject = new Error(error);
  } else {
    errorObject = error;
  }

  appInsightsLogger?.trackException({ error: errorObject ?? '' }, errorProperties);
};

/**
 * This is used to track performance with application insights.
 * @param eventName
 * @param performanceValue
 * @param customEvents
 */
export const trackPerformance = (eventName: string, performanceValue: number, customEvents: KeyValuePair = {}) => {
  updateTelemetryAppContext();

  const data: KeyValuePair = {};
  for (const event in customEvents) {
    data[event] = RemoveSignatureFromJwtToken(customEvents[event]);
  }

  const performanceProperties: PerformanceProperties = {
    duration: performanceValue ?? '',
    data: JSON.stringify(data),
    ...(libraryLoggingContext as LibraryLoggingContext),
  };

  appInsightsLogger?.trackEvent({ name: eventName }, performanceProperties);
};

const RemoveSignatureFromJwtToken = (input: any): string => {
  try {
    if (input) {
      // Check if the input is a JWT token and remove the signature from it.
      if (typeof input === 'string' && (input.startsWith('eyJ') || input.startsWith('Bearer eyJ'))) {
        return input.substring(0, input.lastIndexOf('.') + 1);
      }
      // Check if the input is an object and recursively remove any signatures from tokens nested in the object
      else if (input && typeof input === 'object') {
        const newInput = { ...input };
        for (const key in input) {
          newInput[key] = RemoveSignatureFromJwtToken(newInput[key]);
        }
        return newInput;
      }
    }
  } catch (err: any) {
    appInsightsLogger?.trackException({ error: err ?? '' });
  }

  return input;
};
