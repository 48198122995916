export default {
  "back": "Atgal",
  "breadcrumb": "Naršymo kelias",
  "clear": "Valyti",
  "clear_entry": "Valyti įrašą",
  "close": "Uždaryti",
  "collapse_task_menu": "Sutraukti užduočių meniu",
  "collapse": "Sutraukti",
  "commands": "Komandos",
  "complete": "Atlikta",
  "day": "Diena",
  "dislike": "Nepatinka",
  "error": "Klaida",
  "expand_task_menu": "Išplėsti užduočių meniu",
  "expand": "Išplėsti",
  "filter": "Filtruoti",
  "finish": "Baigti",
  "first": "Pirmas",
  "go_to_page": "Eiti į puslapį {0}",
  "hide_password": "Slėpti slaptažodį",
  "last": "Paskutinis",
  "like": "Patinka",
  "loading": "Įkeliama...",
  "month": "Mėnuo",
  "more_options": "Daugiau parinkčių",
  "navigation": "Naršymas",
  "next": "Pirmyn",
  "next_month": "Kitas mėnuo",
  "not_started": "Nepradėta",
  "off": "Išjungta",
  "on": "Įjungta",
  "open_in_new_window": "Atidaryti naujame lange",
  "options": "Parinktys",
  "pagination": "Laužymas puslapiais",
  "plus_x_more": "ir dar {0} daugiau",
  "previous": "Ankstesnis",
  "previous_month": "Ankstesnis mėnuo",
  "progress": "Vykdoma",
  "quick_start": "Greitas pasirengimas darbui",
  "range_error_message": "Jūsų pasirinkta data nepatenka į datos diapazoną",
  "required_error_message": "Šis laukas yra būtinas",
  "results_found": "Rasta rezultatų: {0}",
  "search": "Ieškoti",
  "select_all": "Žymėti viską",
  "select_row": "Pasirinkti eilutę",
  "show_calendar": "Rodyti kalendorių",
  "show_password": "Rodyti slaptažodį",
  "step_x_of_y": "{0} veiksmas iš {1}",
  "today": "Šiandien",
  "unavailable_date_error_message": "Jūsų pasirinkta data nepasiekiama",
  "warning": "Įspėjimas",
  "year": "Metai"
}