export default {
  "back": "Späť",
  "breadcrumb": "Popis cesty",
  "clear": "Vymazať",
  "clear_entry": "Vymazať položku",
  "close": "Zavrieť",
  "collapse_task_menu": "Zbaliť ponuku úloh",
  "collapse": "Zbaliť",
  "commands": "Príkazy",
  "complete": "Dokončené",
  "day": "Deň",
  "dislike": "Nepáči sa mi to",
  "error": "Chyba",
  "expand_task_menu": "Rozbaliť ponuku úloh",
  "expand": "Rozbaliť",
  "filter": "Filtrovať",
  "finish": "Dokončiť",
  "first": "Prvý",
  "go_to_page": "Prejsť na stránku {0}",
  "hide_password": "Skryť heslo",
  "last": "Posledný",
  "like": "Páči sa mi to",
  "loading": "Načítava sa...",
  "month": "Mesiac",
  "more_options": "Ďalšie možnosti",
  "navigation": "Navigácia",
  "next": "Ďalej",
  "next_month": "Nasledujúci mesiac",
  "not_started": "Nezačaté",
  "off": "Vypnuté",
  "on": "Zapnuté",
  "open_in_new_window": "Otvoriť v novom okne",
  "options": "Možnosti",
  "pagination": "Stránkovanie",
  "plus_x_more": "a ešte {0}",
  "previous": "Predchádzajúce",
  "previous_month": "Predchádzajúci mesiac",
  "progress": "Prebieha",
  "quick_start": "Rýchla konfigurácia",
  "range_error_message": "Dátum, ktorý ste vybrali, nie je v rozsahu dátumov",
  "required_error_message": "Toto pole je povinné",
  "results_found": "Počet nájdených výsledkov: {0}",
  "search": "Hľadať",
  "select_all": "Vybrať všetko",
  "select_row": "Vybrať riadok",
  "show_calendar": "Zobraziť kalendár",
  "show_password": "Zobraziť heslo",
  "step_x_of_y": "Krok {0} z {1}",
  "today": "Dnes",
  "unavailable_date_error_message": "Dátum, ktorý ste vybrali, nie je k dispozícii",
  "warning": "Upozornenie",
  "year": "Rok"
}