export default {
  "back": "Назад",
  "breadcrumb": "Элемент навигации",
  "clear": "Очистить",
  "clear_entry": "Очистить запись",
  "close": "Закрыть",
  "collapse_task_menu": "Свернуть меню задач",
  "collapse": "Свернуть",
  "commands": "Команды",
  "complete": "Готово",
  "day": "День",
  "dislike": "Не нравится",
  "error": "Ошибка",
  "expand_task_menu": "Развернуть меню задач",
  "expand": "Развернуть",
  "filter": "Фильтр",
  "finish": "Завершить",
  "first": "Первое",
  "go_to_page": "Перейти на страницу {0}",
  "hide_password": "Скрыть пароль",
  "last": "Последнее",
  "like": "Похоже на",
  "loading": "Загрузка...",
  "month": "Месяц",
  "more_options": "Другие параметры",
  "navigation": "Навигация",
  "next": "Далее",
  "next_month": "Следующий месяц",
  "not_started": "Не начато",
  "off": "Откл.",
  "on": "Вкл.",
  "open_in_new_window": "Открыть в новом окне",
  "options": "Параметры",
  "pagination": "Разбивка на страницы",
  "plus_x_more": "и еще {0}",
  "previous": "Назад",
  "previous_month": "Предыдущий месяц",
  "progress": "Выполняется",
  "quick_start": "Быстрое начало",
  "range_error_message": "Выбранная дата отсутствует в диапазоне дат",
  "required_error_message": "Это обязательное поле",
  "results_found": "Найдено результатов: {0}",
  "search": "Поиск",
  "select_all": "Выделить все",
  "select_row": "Выбрать строку",
  "show_calendar": "Показать календарь",
  "show_password": "Показать пароль",
  "step_x_of_y": "Шаг {0} из {1}",
  "today": "Сегодня",
  "unavailable_date_error_message": "Выбранная дата недоступна",
  "warning": "Предупреждение",
  "year": "Год"
}