export default {
  "back": "戻る",
  "breadcrumb": "階層リンク",
  "clear": "クリア",
  "clear_entry": "エントリのクリア",
  "close": "閉じる",
  "collapse_task_menu": "タスク メニューを折りたたむ",
  "collapse": "折りたたむ",
  "commands": "コマンド",
  "complete": "完了",
  "day": "日付",
  "dislike": "不満",
  "error": "エラー",
  "expand_task_menu": "タスク メニューの展開",
  "expand": "展開",
  "filter": "フィルター",
  "finish": "終了",
  "first": "最初",
  "go_to_page": "{0} ページに移動",
  "hide_password": "パスワードを非表示",
  "last": "最後",
  "like": "いいね!",
  "loading": "読み込んでいます...",
  "month": "月",
  "more_options": "その他のオプション",
  "navigation": "ナビゲーション",
  "next": "次へ",
  "next_month": "来月",
  "not_started": "開始されていません",
  "off": "オフ",
  "on": "オン",
  "open_in_new_window": "新しいウィンドウで開く",
  "options": "オプション",
  "pagination": "改ページ位置の自動修正",
  "plus_x_more": "さらに {0} 個",
  "previous": "前へ",
  "previous_month": "先月",
  "progress": "処理中",
  "quick_start": "クイック スタート",
  "range_error_message": "選択した日付が日付範囲内ではありません",
  "required_error_message": "このフィールドは必須項目です",
  "results_found": "{0} 件の結果が見つかりました",
  "search": "検索",
  "select_all": "すべて選択",
  "select_row": "行の選択",
  "show_calendar": "カレンダーの表示",
  "show_password": "パスワードを表示",
  "step_x_of_y": "手順 {0}/{1}",
  "today": "今日",
  "unavailable_date_error_message": "選択した日付は使用できません",
  "warning": "警告",
  "year": "年"
}