import { css } from 'lit';
import componentStyles from '../../internal/styles/component.styles';
import { SystemColors } from '../../utilities/system-colors';

export default css`
  ${componentStyles}

  :host {
    display: block;
  }
  :host([hidden]) {
    display: none;
  }

  ::slotted(.he-button) {
    height: var(--he-form-control-height-small) !important;
    font-size: var(--he-font-size-medium) !important;
    margin: 0 var(--he-spacing-2x-small);
  }

  .message-bar__wrapper {
    overflow: hidden;
  }

  .message-bar {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--he-color-surface-background);
    font-weight: var(--he-font-weight-normal);
    min-height: var(--he-form-control-height);
    width: 100%;
  }

  .message-bar--collapsed .message-bar__message-inner-container {
    max-height: var(--he-form-control-height);
  }

  .message-bar__content {
    display: flex;
    align-items: flex-start;
    width: 100%;
    line-height: var(--he-line-height-normal);
  }

  .message-bar-icon {
    flex: 0;
    font-size: var(--he-font-size-large);
    min-width: var(--he-font-size-large);
    min-height: var(--he-font-size-large);
    margin-top: var(--he-spacing-x-small);
    margin-inline-start: var(--he-spacing-small);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message-bar__message-container {
    flex: 1;
    font-family: var(--he-body-font);
    font-size: var(--he-font-size-small);
    font-weight: var(--he-font-weight-normal);
    line-height: var(--he-line-height-small);
    margin: var(--he-spacing-x-small) var(--he-spacing-x-small);
    display: flex;
    min-width: 0px;
  }

  .message-bar--collapsed .message-bar__message-container {
    max-height: var(--he-line-height-small);
  }

  .message-bar--collapsed .message-bar__message-inner-container,
  .message-bar--expanded .message-bar__message-inner-container {
    overflow: hidden;
  }

  .message-bar--collapsed .message-bar__message-inner-container {
    max-height: var(--he-form-control-height);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .message-bar--expanded .message-bar__message-inner-container {
    white-space: normal;
    word-break: break-word;
  }

  .message-bar__actions {
    flex: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: var(--he-form-control-height);
  }

  .message-bar__button .he-icon {
    font-size: var(--he-font-size-small);
  }

  .message-bar__footer {
    display: none;
  }

  .message-bar--has-actions .message-bar__footer {
    display: flex;
    margin: var(--he-spacing-2x-small) var(--he-spacing-x-small);
    justify-content: flex-end;
  }

  /* Appearance Styles */
  .message-bar--info {
    background-color: var(--he-color-neutral-100);
  }

  .message-bar--info .message-bar__button {
    --button-background-color-hover: var(--he-color-neutral-200);
    --button-background-color-active: var(--he-color-neutral-250);
  }

  .message-bar--notice {
    background-color: var(--he-color-primary-100);
  }

  .message-bar--notice .message-bar-icon {
    color: var(--he-color-neutral-700);
  }

  .message-bar--notice .message-bar__button {
    --button-background-color-hover: var(--he-color-primary-200);
    --button-background-color-active: var(--he-color-primary-300);
  }

  .message-bar--success {
    background-color: var(--he-color-success-100);
  }

  .message-bar--success .message-bar-icon {
    color: var(--he-color-success-700);
  }

  .message-bar--success .message-bar__button {
    --button-background-color-hover: var(--he-color-success-200);
    --button-background-color-active: var(--he-color-success-300);
  }

  .message-bar--warning {
    background-color: var(--he-color-warning-100);
  }

  .message-bar--warning .message-bar__button {
    --button-background-color-hover: var(--he-color-warning-200);
    --button-background-color-active: var(--he-color-warning-300);
  }

  .message-bar--error {
    background-color: var(--he-color-danger-100);
  }

  .message-bar--error .message-bar-icon {
    color: var(--he-color-danger-700);
  }

  .message-bar--error .message-bar__button {
    --button-background-color-hover: var(--he-color-danger-200);
    --button-background-color-active: var(--he-color-danger-300);
  }

  /* High contrast */
  @media screen and (forced-colors: active) {
    .message-bar {
      border: 1px solid ${SystemColors.CanvasText};
    }

    .message-bar-icon {
      color: ${SystemColors.CanvasText};
    }
  }
`;
