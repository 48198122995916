import { property } from 'lit/decorators.js';
import { html, literal, unsafeStatic } from 'lit/static-html.js';
import HarmonyElement from '../';
import { watch } from '../../internal/watch';
import styles from './text.styles';

export type TextTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
const TEXT_TAGS: TextTag[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span'];

/**
 * @tag he-text
 * @since 2.2
 * @status stable
 * @figma https://www.figma.com/file/dRwBPvZFZdYgWdAOCK375K/PC-Toolkit?node-id=686%3A800
 *
 * @slot - The text to style.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 */
export class Text extends HarmonyElement {
  static styles = styles;
  static baseName = 'text';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
  };

  /** The text's intended appearance. */
  @property({ reflect: true }) appearance:
    | 'heading-1'
    | 'heading-2'
    | 'heading-3'
    | 'heading-4'
    | 'heading-5'
    | 'heading-6'
    | 'paragraph'
    | 'small'
    | 'hero-title'
    | 'greeting-title'
    | 'email-header'
    | 'pane-header'
    | 'header'
    | 'subject-title'
    | 'body-text'
    | 'metadata'
    | 'label'
    | 'menu-item-heading';

  /**
   * The tag to render internally. Use this as needed to provide better semantics. When unset, no tag will be rendered.
   */
  @property({ reflect: true }) tag: TextTag;

  @watch('tag')
  tagChanged() {
    if (/^h[1-6]$/.test(this.tag)) {
      this.setAttribute('role', 'heading');
      this.setAttribute('aria-level', this.tag.slice(1));
    } else {
      this.removeAttribute('role');
      this.removeAttribute('aria-level');
    }
  }

  render() {
    const tag = TEXT_TAGS.find(tag => tag === this.tag);

    if (tag) {
      const literalTag = literal`${unsafeStatic(tag)}`;
      return html`
        <${literalTag}>
          <slot></slot>
        </${literalTag}>
      `;
    }

    return html`<slot></slot>`;
  }
}

export default Text;
