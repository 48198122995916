import { html } from 'lit/static-html.js';
import { queryAssignedElements } from 'lit/decorators.js';
import { MenuItem, MenuItemColumnCount } from '../menu-item/menu-item';
import HarmonyElement from '../';
import styles from './menu.styles';

/**
 * @tag he-menu
 * @since 1.3
 * @status stable
 * @figma https://www.figma.com/file/SfeKlUEZg2L7JsHwbx6TMBjP/Dev-Relations-%2F-PX-Toolkit?node-id=9131%3A55384
 *
 * @slot - Supports he-menu-item elements or elements with a role of 'menuitem', 'menuitemcheckbox', and 'menuitemradio'.
 *
 * @cssproperty [--he-elevation=8] - Elevation of the menu.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 */
export class Menu extends HarmonyElement {
  static styles = [styles];
  static baseName = 'menu';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
  };

  private expandedItem: MenuItem | null = null;
  private focusIndex: number = -1; // The index of the focusable element in menuItems
  menuItems?: Element[];

  @queryAssignedElements()
  items!: Array<HTMLElement>;

  connectedCallback() {
    super.connectedCallback();

    this.setAttribute('role', 'menu');

    setTimeout(() => {
      // wait until children have had a chance to
      // connect before setting/checking their props/attributes
      this.setItems();
    });

    this.addEventListener('he-change', this.handleChange);
    this.addEventListener('keydown', this.handleMenuKeyDown);
    this.addEventListener('focusout', this.handleFocusOut);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeItemListeners();
    this.menuItems = undefined;
    this.removeEventListener('he-change', this.handleChange);
    this.removeEventListener('keydown', this.handleMenuKeyDown);
    this.removeEventListener('focusout', this.handleFocusOut);
  }

  readonly isNestedMenu = (): boolean => {
    return (
      this.parentElement !== null &&
      this.parentElement instanceof HTMLElement &&
      (this.parentElement.getAttribute('role')?.startsWith('menuitem') ||
        this.parentElement.classList.contains('he-menu-item') ||
        false)
    );
  };

  /** Focuses the first item in the menu. */
  focus() {
    this.setFocus(0, 1);
  }

  /** Collapses any expanded menu items. */
  collapseExpandedItem() {
    if (this.expandedItem !== null) {
      this.expandedItem.expanded = false;
      this.expandedItem = null;
    }
  }

  handleMenuKeyDown = (e: KeyboardEvent) => {
    if (e.defaultPrevented || this.menuItems === undefined) {
      return;
    }
    switch (e.key) {
      case 'ArrowDown':
        // go forward one index
        this.setFocus(this.focusIndex + 1, 1);
        e.preventDefault();
        return;
      case 'ArrowUp':
        // go back one index
        this.setFocus(this.focusIndex - 1, -1);
        e.preventDefault();
        return;
      case 'End':
        // set focus on last item
        this.setFocus(this.menuItems.length - 1, -1);
        e.preventDefault();
        return;
      case 'Home':
        // set focus on first item
        this.setFocus(0, 1);
        e.preventDefault();
        return;

      default:
        // if we are not handling the event, do not prevent default
        return true;
    }
  };

  // if focus is moving out of the menu, reset to a stable initial state
  handleFocusOut = (e: FocusEvent) => {
    if (!this.contains(e.relatedTarget as Element) && this.menuItems !== undefined) {
      this.collapseExpandedItem();
      // find our first focusable element
      const focusIndex: number = 0; // this.menuItems.findIndex(this.isFocusableElement);
      // set the current focus index's tabindex to -1
      this.menuItems[this.focusIndex].setAttribute('tabindex', '-1');
      // set the first focusable element tabindex to 0
      this.menuItems[focusIndex].setAttribute('tabindex', '0');
      // set the focus index
      this.focusIndex = focusIndex;
    }
  };

  handleItemFocus = (e: FocusEvent) => {
    const targetItem: HTMLElement = e.target as HTMLElement;

    if (this.menuItems !== undefined && targetItem !== this.menuItems[this.focusIndex]) {
      this.menuItems[this.focusIndex].setAttribute('tabindex', '-1');
      this.focusIndex = this.menuItems.indexOf(targetItem);
      targetItem.setAttribute('tabindex', '0');
    }
  };

  handleExpandedChanged = (e: Event) => {
    if (
      e.defaultPrevented ||
      e.target === null ||
      this.menuItems === undefined ||
      this.menuItems.indexOf(e.target as HTMLElement) < 0
    ) {
      return;
    }

    e.preventDefault();
    const changedItem: MenuItem = e.target as MenuItem;

    // closing an expanded item without opening another
    if (this.expandedItem !== null && changedItem === this.expandedItem && changedItem.expanded === false) {
      this.expandedItem = null;
      return;
    }

    if (changedItem.expanded) {
      if (this.expandedItem !== null && this.expandedItem !== changedItem) {
        this.expandedItem.expanded = false;
      }
      this.menuItems[this.focusIndex].setAttribute('tabindex', '-1');
      this.expandedItem = changedItem;
      this.focusIndex = this.menuItems.indexOf(changedItem);
      changedItem.setAttribute('tabindex', '0');
    }
  };

  removeItemListeners = () => {
    if (this.menuItems !== undefined) {
      this.menuItems.forEach((item: HTMLElement) => {
        item.removeEventListener('he-expanded-change', this.handleExpandedChanged);
        item.removeEventListener('focus', this.handleItemFocus);
      });
    }
  };

  handleSlotChange = () => {
    // only update children after the component is connected and
    // the setItems has run on connectedCallback
    // (menuItems is undefined until then)
    if (this.isConnected && this.menuItems !== undefined) {
      this.setItems();
    }

    if (this.isNestedMenu()) {
      this.setAttribute('slot', 'submenu');
    } else if (this.getAttribute('slot') === 'submenu') {
      this.removeAttribute('slot');
    }
  };

  setItems = () => {
    const newItems: Element[] = this.domChildren();

    this.removeItemListeners();
    this.menuItems = newItems;

    const menuItems = this.menuItems.filter(this.isMenuItemElement);

    // if our focus index is not -1 we have items
    if (menuItems.length) {
      this.focusIndex = 0;
    }

    function elementIndent(el: HTMLElement): MenuItemColumnCount {
      // if menu item is a link, it will have role none as the menuitem role is on the link in the shadow root
      const role = el.getAttribute('role') === 'none' ? 'menuitem' : el.getAttribute('role');
      const startSlot = el.querySelector('[slot=start]');

      if ((el as MenuItem).quickNav && role === 'menuitemradio') {
        return startSlot === null ? 0 : 1;
      }

      if (role !== 'menuitem' && startSlot === null) {
        return 1;
      } else if (role === 'menuitem' && startSlot !== null) {
        return 1;
      } else if (role !== 'menuitem' && startSlot !== null) {
        return 2;
      } else {
        return 0;
      }
    }

    const indent: MenuItemColumnCount = menuItems.reduce((accum, current) => {
      const elementValue = elementIndent(current);

      return accum > elementValue ? accum : elementValue;
    }, 0);

    menuItems.forEach((item: HTMLElement, index: number) => {
      item.setAttribute('tabindex', index === 0 ? '0' : '-1');
      item.addEventListener('he-expanded-change', this.handleExpandedChanged);
      item.addEventListener('focus', this.handleItemFocus);

      if (item instanceof MenuItem) {
        item.startColumnCount = indent;
      }
    });
  };

  // handle change from child element
  handleChange = (e: CustomEvent) => {
    if (this.menuItems === undefined) {
      return;
    }
    const changedMenuItem: MenuItem = e.target as MenuItem;
    const changeItemIndex: number = this.menuItems.indexOf(changedMenuItem);

    if (changeItemIndex === -1) {
      return;
    }

    if (changedMenuItem.role === 'menuitemradio' && changedMenuItem.checked === true) {
      for (let i = changeItemIndex - 1; i >= 0; --i) {
        const item: Element = this.menuItems[i];
        const role: string | null = item.getAttribute('role');
        if (role === 'menuitemradio') {
          (item as MenuItem).checked = false;
        }
        if (role === 'separator') {
          break;
        }
      }
      const maxIndex: number = this.menuItems.length - 1;
      for (let i = changeItemIndex + 1; i <= maxIndex; ++i) {
        const item: Element = this.menuItems[i];
        const role: string | null = item.getAttribute('role');
        if (role === 'menuitemradio') {
          (item as MenuItem).checked = false;
        }
        if (role === 'separator') {
          break;
        }
      }
    }
  };

  // get an array of valid DOM children
  private domChildren(): Element[] {
    return Array.from(this.children).filter(child => !child.hasAttribute('hidden'));
  }

  // check if the item is a menu item
  isMenuItemElement = (el: Element): el is HTMLElement => {
    return (
      el instanceof HTMLElement &&
      (['menuitem', 'menuitemcheckbox', 'menuitemradio'].includes(el.getAttribute('role') as string) ||
        el.classList.contains('he-menu-item'))
    );
  };

  setFocus(focusIndex: number, adjustment: number) {
    if (this.menuItems === undefined) {
      return;
    }

    while (focusIndex >= 0 && focusIndex < this.menuItems.length) {
      const child: Element = this.menuItems[focusIndex];

      if (this.isMenuItemElement(child)) {
        // change the previous index to -1
        if (this.focusIndex > -1 && this.menuItems.length >= this.focusIndex - 1) {
          this.menuItems[this.focusIndex].setAttribute('tabindex', '-1');
        }

        // update the focus index
        this.focusIndex = focusIndex;

        // update the tabindex of next focusable element
        child.setAttribute('tabindex', '0');

        // focus the element
        child.focus();

        break;
      }

      focusIndex += adjustment;
    }
  }

  protected render() {
    return html`<slot @slotchange=${this.handleSlotChange}></slot>`;
  }
}

export default Menu;
