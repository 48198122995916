export default {
  "back": "Tillbaka",
  "breadcrumb": "Synlig sökväg",
  "clear": "Rensa",
  "clear_entry": "Rensa post",
  "close": "Stäng",
  "collapse_task_menu": "Dölj aktivitetsmenyn",
  "collapse": "Dölj",
  "commands": "Kommandon",
  "complete": "Slutfört",
  "day": "Dag",
  "dislike": "Ogillar",
  "error": "Fel",
  "expand_task_menu": "Visa aktivitetsmenyn",
  "expand": "Expandera",
  "filter": "Filter",
  "finish": "Slutför",
  "first": "Första",
  "go_to_page": "Gå till sidan {0}",
  "hide_password": "Dölj lösenord",
  "last": "Sista",
  "like": "Liknar",
  "loading": "Läser in...",
  "month": "Månad",
  "more_options": "Fler alternativ",
  "navigation": "Navigering",
  "next": "Nästa",
  "next_month": "Nästa månad",
  "not_started": "Inte startad",
  "off": "Av",
  "on": "På",
  "open_in_new_window": "Öppna i nytt fönster",
  "options": "Alternativ",
  "pagination": "Sidindelning",
  "plus_x_more": "+ ytterligare {0}",
  "previous": "Föregående",
  "previous_month": "Föregående månad",
  "progress": "Pågår",
  "quick_start": "Snabbstart",
  "range_error_message": "Det datum du har valt ligger inte inom datumintervallet",
  "required_error_message": "Det här fältet är obligatoriskt",
  "results_found": "{0} resultat hittades",
  "search": "Sök",
  "select_all": "Välj allt",
  "select_row": "Välj rad",
  "show_calendar": "Visa kalender",
  "show_password": "Visa lösenord",
  "step_x_of_y": "Steg {0} av {1}",
  "today": "I dag",
  "unavailable_date_error_message": "Det valda datumet är inte tillgängligt",
  "warning": "Varning",
  "year": "År"
}