export default {
  "back": "Geri",
  "breadcrumb": "İçerik haritası",
  "clear": "Temizle",
  "clear_entry": "Girişi temizle",
  "close": "Kapat",
  "collapse_task_menu": "Görev menüsünü daralt",
  "collapse": "Daralt",
  "commands": "Komutlar",
  "complete": "Tamamla",
  "day": "Gün",
  "dislike": "Beğenme",
  "error": "Hata",
  "expand_task_menu": "Görev menüsünü genişlet",
  "expand": "Genişlet",
  "filter": "Filtrele",
  "finish": "Son",
  "first": "İlk",
  "go_to_page": "{0} sayfasına git",
  "hide_password": "Parolayı gizle",
  "last": "Son",
  "like": "Şunun gibi",
  "loading": "Yükleniyor...",
  "month": "Ay",
  "more_options": "Diğer Seçenekler",
  "navigation": "Gezinme",
  "next": "İleri",
  "next_month": "Gelecek ay",
  "not_started": "Başlatılmadı",
  "off": "Kapalı",
  "on": "Açık",
  "open_in_new_window": "Yeni pencerede aç",
  "options": "Seçenekler",
  "pagination": "Sayfalandırma",
  "plus_x_more": "{0} hedef daha",
  "previous": "Geri",
  "previous_month": "Önceki ay",
  "progress": "Devam ediyor",
  "quick_start": "Hızlı başlangıç",
  "range_error_message": "Seçtiğiniz tarih, tarih aralığı içinde değil",
  "required_error_message": "Bu alan gereklidir",
  "results_found": "{0} sonuç bulundu",
  "search": "Ara",
  "select_all": "Tümünü seç",
  "select_row": "Satır seçin",
  "show_calendar": "Takvimi göster",
  "show_password": "Parolayı göster",
  "step_x_of_y": "Adım {0} / {1}",
  "today": "Bugün",
  "unavailable_date_error_message": "Seçtiğiniz tarih kullanılamıyor",
  "warning": "Uyarı",
  "year": "Yıl"
}