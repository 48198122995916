import { html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { watch } from '../../internal/watch';
import HarmonyElement from '../';
import componentStyles from '../../internal/styles/component.styles';
import formControlStyles from '../../internal/styles/form-control.styles';
import { keys } from '../../utilities/key-map';
import { HasSlotController } from '../../internal/slot';
import styles from './radio.styles';

/**
 * @tag he-radio
 * @since 2.0
 * @status stable
 *
 * @slot - The radio's label.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-blur - Emitted when the control loses focus.
 * @event he-focus - Emitted when the control gains focus.
 * @event he-selected - Emitted when the radio is selected.
 *
 * @csspart base - The component's internal wrapper.
 * @csspart control - The radio control.
 * @csspart checked-icon - The container the wraps the checked icon.
 * @csspart label - The radio label.
 */
export class Radio extends HarmonyElement {
  static styles = [componentStyles, formControlStyles, styles];
  static baseName = 'radio';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeBlur: new CustomEvent('he-blur'),
    onHeFocus: new CustomEvent('he-focus'),
    onHeSelected: new CustomEvent('he-selected'),
  };

  private readonly hasSlotController = new HasSlotController(this, '[default]');

  @state()
  private hasFocus = false;

  /** This toggles the selected status for the radio and is for use when a radio button is used outside of a radio group. When used within a radio group, this value be overridden by the radio group's value. */
  @property({ type: Boolean, reflect: true })
  checked = false;

  /** The radio's value attribute. */
  @property()
  value: string;

  /** Disables the radio. */
  @property({ type: Boolean, reflect: true })
  disabled = false;

  /** Focus on the radio on page load. */
  @property({ type: Boolean, reflect: true })
  autofocus: boolean;

  /** The input's label. Alternatively, you can use the default slot. */
  @property()
  label?: string;

  connectedCallback(): void {
    super.connectedCallback();
    this.setInitialAttributes();
    this.addEventListeners();
  }

  disconnectedCallback() {
    this.removeEventListeners();
    super.disconnectedCallback();
  }

  @watch('checked')
  handleCheckedChange() {
    this.setAttribute('aria-checked', this.checked ? 'true' : 'false');
    if (this.checked) {
      this.emit('he-selected');
    }
  }

  @watch('disabled', { waitUntilFirstUpdate: true })
  handleDisabledChange() {
    this.setAttribute('aria-disabled', this.disabled ? 'true' : 'false');
  }

  private handleBlur = () => {
    this.hasFocus = false;
    this.emit('he-blur');
  };

  private handleClick = () => {
    if (!this.disabled) {
      this.checked = true;
    }
  };

  private handleFocus = () => {
    this.hasFocus = true;
    this.emit('he-focus');
  };

  private handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === keys.Space && !this.disabled) {
      this.checked = true;
    }
  };

  private addEventListeners() {
    this.addEventListener('keydown', this.handleKeyDown);
    this.addEventListener('blur', this.handleBlur);
    this.addEventListener('click', this.handleClick);
    this.addEventListener('focus', this.handleFocus);
  }

  private removeEventListeners() {
    this.removeEventListener('keydown', this.handleKeyDown);
    this.removeEventListener('blur', this.handleBlur);
    this.removeEventListener('click', this.handleClick);
    this.removeEventListener('focus', this.handleFocus);
  }

  private setInitialAttributes() {
    this.setAttribute('role', 'radio');
    this.setAttribute('tabindex', '0');
    this.setAttribute('aria-disabled', this.disabled ? 'true' : 'false');
  }

  render() {
    return html`
      <span
        part="base"
        class=${classMap({
          radio: true,
          'radio--checked': this.checked,
          'radio--disabled': this.disabled,
          'radio--focused': this.hasFocus,
          'form-control--group-item': true,
          'form-control--has-label': this.label || this.hasSlotController.test('[default]'),
        })}
      >
        <span part="control" class="radio__control">
          <svg part="checked-icon" class="radio__icon" viewBox="0 0 12 12">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g fill="currentColor">
                <circle cx="6" cy="6" r="3.42857143"></circle>
              </g>
            </g>
          </svg>
        </span>

        <span part="label" class="radio__label form-control--group-item__label">
          <slot>${this.label}</slot>
        </span>
      </span>
    `;
  }
}

export default Radio;
