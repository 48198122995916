export default {
  "back": "Артқа",
  "breadcrumb": "Навигация элементі",
  "clear": "Тазалау",
  "clear_entry": "Жазбаны тазалау",
  "close": "Жабу",
  "collapse_task_menu": "Тапсырма мәзірін тасалау",
  "collapse": "Тасалау",
  "commands": "Пәрмендер",
  "complete": "Аяқтау",
  "day": "Күн",
  "dislike": "Ұнатпау",
  "error": "Қате",
  "expand_task_menu": "Тапсырмалар мәзірін кеңейту",
  "expand": "Кеңейту",
  "filter": "Сүзгі",
  "finish": "Дайын",
  "first": "Бірінші",
  "go_to_page": "{0} бетіне өту",
  "hide_password": "Құпиясөзді жасыру",
  "last": "Соңғы",
  "like": "Ұнайды",
  "loading": "Жүктелуде...",
  "month": "Ай",
  "more_options": "Қосымша параметрлер",
  "navigation": "Шарлау",
  "next": "Келесі",
  "next_month": "Келесі ай",
  "not_started": "Басталмады",
  "off": "Өшірулі",
  "on": "Қосулы",
  "open_in_new_window": "Жаңа терезеде ашу",
  "options": "Параметрлер",
  "pagination": "Беттеу",
  "plus_x_more": "+ тағы {0}",
  "previous": "Алдыңғы",
  "previous_month": "Өткен ай",
  "progress": "Орындалуда",
  "quick_start": "Жылдам бастау",
  "range_error_message": "Таңдалған күн күндер ауқымында емес",
  "required_error_message": "Бұл өріс міндетті болып табылады",
  "results_found": "{0} нәтиже табылды",
  "search": "Іздеу",
  "select_all": "Барлығын таңдау",
  "select_row": "Жолды таңдау",
  "show_calendar": "Күнтізбені көрсету",
  "show_password": "Құпиясөзді көрсету",
  "step_x_of_y": "Қадам {0}/{1}",
  "today": "Бүгін",
  "unavailable_date_error_message": "Таңдалған күн қолжетімді емес",
  "warning": "Ескерту",
  "year": "Жыл"
}