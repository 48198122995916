import { css } from 'lit';

import shared from './shared.styles';
import light from './light.styles';
import dark from './dark.styles';

export default css`
  ${light}
  ${dark}
  ${shared}
`;
