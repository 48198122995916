import { css } from 'lit';
import componentStyles from '../../internal/styles/component.styles';
import { litElevation } from '../../internal/styles/elevation';
import { SystemColors } from '../../utilities/system-colors';

export default css`
  ${componentStyles}

  :host {
    --he-elevation: 8;
    ${litElevation};
    display: block;
    background: var(--he-color-surface-background);
    border-radius: var(--he-menu-control-border-radius);
    margin: 0;
    padding: 0;
    max-width: 368px;
    min-width: 180px;
    width: max-content;
  }

  ::slotted(hr) {
    box-sizing: content-box;
    height: 0;
    margin: 0;
    border: none;
    border-top: var(--he-form-control-border-width) solid var(--he-color-neutral-200);
  }

  ::slotted(.he-menu-item:first-child) {
    border-top-left-radius: var(--he-menu-control-border-radius);
    border-top-right-radius: var(--he-menu-control-border-radius);
  }

  ::slotted(.he-menu-item:last-child) {
    border-bottom-left-radius: var(--he-menu-control-border-radius);
    border-bottom-right-radius: var(--he-menu-control-border-radius);
  }

  @media screen and (forced-colors: active) {
    :host {
      background: ${SystemColors.Canvas};
      color: ${SystemColors.CanvasText};
      border: 1px solid ${SystemColors.CanvasText};
    }
  }
`;
