/**
 * @description It Provides the list of supported Locale Enums in Partner Portal.
 * @since 0.0
 * @status experimental
 */
export enum SupportedLocaleEnums {
  ar_sa = 'ar-sa',
  bg_bg = 'bg-bg',
  ca_es = 'ca-es',
  cs_cz = 'cs-cz',
  da_dk = 'da-dk',
  de_de = 'de-de',
  el_gr = 'el-gr',
  es_es = 'es-es',
  et_ee = 'et-ee',
  eu_es = 'eu-es',
  en_us = 'en-us',
  fi_fi = 'fi-fi',
  fr_fr = 'fr-fr',
  gl_es = 'gl-es',
  he_il = 'he-il',
  hi_in = 'hi-in',
  hr_hr = 'hr-hr',
  hu_hu = 'hu-hu',
  id_id = 'id-id',
  it_it = 'it-it',
  ja_jp = 'ja-jp',
  kk_kz = 'kk-kz',
  ko_kr = 'ko-kr',
  lt_lt = 'lt-lt',
  lv_lv = 'lv-lv',
  ms_my = 'ms-my',
  nb_no = 'nb-no',
  nl_nl = 'nl-nl',
  pl_pl = 'pl-pl',
  pt_br = 'pt-br',
  pt_pt = 'pt-pt',
  ro_ro = 'ro-ro',
  ru_ru = 'ru-ru',
  sk_sk = 'sk-sk',
  sl_si = 'sl-si',
  sr_latn_rs = 'sr-latn-rs',
  sv_se = 'sv-se',
  th_th = 'th-th',
  tr_tr = 'tr-tr',
  uk_ua = 'uk-ua',
  vi_vn = 'vi-vn',
  zh_cn = 'zh-cn',
  zh_tw = 'zh-tw',
  default = 'en-us',
}
