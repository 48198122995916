import { html } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';
import HarmonyElement from '../';
import { HasSlotController } from '../../internal/slot';
import styles from './progress.styles';

/**
 * @tag he-progress
 * @since 1.3
 * @status stable
 * @figma https://www.figma.com/file/dRwBPvZFZdYgWdAOCK375K/PC-Toolkit?node-id=13%3A1148
 *
 * @slot - A label to show inside the indicator.
 *
 * @csspart base - The component's internal wrapper.
 * @csspart indicator - The progress bar indicator.
 * @csspart label - The progress bar label.
 *
 * @cssproperty --height - The progress bar's height.
 * @cssproperty --track-color - The track color.
 * @cssproperty --indicator-color - The indicator color.
 * @cssproperty --label-color - The label color.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 */
export class Progress extends HarmonyElement {
  static styles = styles;
  static baseName = 'progress';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
  };

  private readonly hasSlotController = new HasSlotController(this, '[default]');

  /** The current progress, 0 to 100. */
  @property({ type: Number, reflect: true }) value = 0;

  /** When true, percentage is ignored, the label is hidden, and the progress bar is drawn in an indeterminate state. */
  @property({ type: Boolean, reflect: true }) indeterminate = false;

  /** A custom label for the progress bar's aria label. */
  @property() label = '';

  render() {
    return html`
      <div
        part="base"
        class=${classMap({
          progress: true,
          'progress--indeterminate': this.indeterminate,
        })}
        role="progressbar"
        title=${ifDefined(this.title)}
        aria-labelledby="label"
        aria-valuemin="0"
        aria-valuemax="100"
        aria-valuenow=${this.indeterminate ? 0 : this.value}
      >
        <div part="indicator" class="progress__indicator" style=${styleMap({ width: `${this.value}%` })}>
          <div id="label" class="label visually-hidden">
            ${this.label || this.hasSlotController.test('[default]')
              ? html`<span part="label" class="progress__label">
                  <slot>${this.label}</slot>
                </span>`
              : this.localize.term('progress')}
          </div>
        </div>
      </div>
    `;
  }
}

export default Progress;
