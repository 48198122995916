/**
 * Defines the metadata for available workspaces.
 */
export type AuthWorkspace = {
  // Provides the href for landing page for the workspace.
  href: string;
  // It indicates the iconClass used for display purposes.
  iconClass: string;
  // It is indicating whether the workspace is internal.
  isInternal: boolean;
  // Workspace Id
  workspaceId: string;
  // Localized Workspace Name
  workspaceName: string;
  // Indicates whether the workspace is global in partner center or not.
  isGlobal: boolean;
  // Indicates whether the workspace is global in partner center or not.
  microFeedback: boolean;
  // The topic product family the workspace belongs to.
  // Used for Metadata Admin to separate workspaces by product family.
  topicProductFamily: string;
};

//TODO: Remove this once the backend returns the camel case object.

/**
 * Defines the metadata for available workspaces.
 */
export type PascalAuthWorkspace = {
  // Provides the href for landing page for the workspace.
  Href: string;
  // It indicates the iconClass used for display purposes.
  IconClass: string;
  // It is indicating whether the workspace is internal.
  IsInternal: boolean;
  // Workspace Id
  WorkspaceId: string;
  // Localized Workspace Name
  WorkspaceName: string;
  // Indicates whether the workspace is global in partner center or not.
  IsGlobal: boolean;
  // Indicates whether the workspace is global in partner center or not.
  MicroFeedback: boolean;
  // The topic product family the workspace belongs to.
  // Used for Metadata Admin to separate workspaces by product family.
  TopicProductFamily: string;
};

export const transformAuthWorkspace = (unknownAuthWorkspace: PascalAuthWorkspace | AuthWorkspace): AuthWorkspace => {
  const pascalAuthWorkspace = unknownAuthWorkspace as PascalAuthWorkspace;
  const authWorkspace = unknownAuthWorkspace as AuthWorkspace;
  const transformedAuthWorkspace: AuthWorkspace = {
    href: pascalAuthWorkspace.Href ?? authWorkspace.href,
    iconClass: pascalAuthWorkspace.IconClass ?? authWorkspace.iconClass,
    isInternal: pascalAuthWorkspace.IsInternal ?? authWorkspace.isInternal,
    workspaceId: pascalAuthWorkspace.WorkspaceId ?? authWorkspace.workspaceId,
    workspaceName: pascalAuthWorkspace.WorkspaceName ?? authWorkspace.workspaceName,
    isGlobal: pascalAuthWorkspace.IsGlobal ?? authWorkspace.isGlobal,
    microFeedback: pascalAuthWorkspace.MicroFeedback ?? authWorkspace.microFeedback,
    topicProductFamily: pascalAuthWorkspace.TopicProductFamily ?? authWorkspace.topicProductFamily,
  };
  return transformedAuthWorkspace;
};
