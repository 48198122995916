export default {
  "back": "Enrere",
  "breadcrumb": "Barra d'adreces",
  "clear": "Esborra",
  "clear_entry": "Esborra l'entrada",
  "close": "Tanca",
  "collapse_task_menu": "Redueix el menú de tasques",
  "collapse": "Redueix",
  "commands": "Ordres",
  "complete": "Complet",
  "day": "Dia",
  "dislike": "No m'agrada",
  "error": "Error",
  "expand_task_menu": "Expandeix el menú de tasques",
  "expand": "Expandeix",
  "filter": "Filtre",
  "finish": "Finalitza",
  "first": "Nom",
  "go_to_page": "Ves a la pàgina {0}",
  "hide_password": "Amaga la contrasenya",
  "last": "Últim",
  "like": "M'agrada",
  "loading": "S'està carregant...",
  "month": "Mes",
  "more_options": "Més opcions",
  "navigation": "Navegació",
  "next": "Següent",
  "next_month": "Mes següent",
  "not_started": "No s'ha iniciat",
  "off": "Desactivat",
  "on": "Activat",
  "open_in_new_window": "Obre en una finestra nova",
  "options": "Opcions",
  "pagination": "Paginació",
  "plus_x_more": "i {0} més",
  "previous": "Anterior",
  "previous_month": "Mes anterior",
  "progress": "En curs",
  "quick_start": "Inici ràpid",
  "range_error_message": "La data que heu seleccionat és fora de l'interval de dates",
  "required_error_message": "Aquest camp és necessari.",
  "results_found": "S'han trobat {0} resultats",
  "search": "Cerca",
  "select_all": "Selecciona-ho tot",
  "select_row": "Selecciona la fila",
  "show_calendar": "Mostra el calendari",
  "show_password": "Mostra la contrasenya",
  "step_x_of_y": "Pas {0} de {1}.",
  "today": "Avui",
  "unavailable_date_error_message": "La data que heu seleccionat no està disponible",
  "warning": "Advertiment",
  "year": "Any"
}