import { portalDomainsConnector } from '../../connectors/portal-domain-connector/portal-domains-connector';

export enum PortalEnvironment {
  prod = 'prod',
  tst = 'tst',
  dev = 'dev',
  int = 'int',
  ppe = 'ppe',
}

/**
 * Given that this library will have support different sets of domains. We will do a check over a list to
 * ensure a match happens for atleast one of the identifier for url.
 */
const urlIdentifierMap: Map<PortalEnvironment, string[]> = new Map([
  [PortalEnvironment.prod, ['microsoft.com', 'microsoftonline.cn', 'microsoftsales.crm.dynamics.com']],
  [PortalEnvironment.int, ['microsoft-int.com', 'msxuat.crm.dynamics.com']],
  [PortalEnvironment.tst, ['microsoft-tst.com', 'msxdev02.crm.dynamics.com', 'foundershub.startups.microsoft-tst.com']],
  [PortalEnvironment.dev, ['microsoft-dev.com', 'foundershub.startups.microsoft-dev.com']],
  [PortalEnvironment.ppe, ['microsoft-ppe.com']],
]);

/**
 * This is to derive the current environment for the Portal. This will be exposed via an environment connector. We
 * don't expect the environment connector to be used by teams but more to provide right standard in terms of what is the current
 * environment so that connectors behavior can also be standardized.
 *
 * @uses urlIdentifierMap
 * The urlIdentifierMap is a map from environment to an array of strings. These string values are substring to uniquely identify
 * the current environment. In case there is a match for 2 different sources, last one will be chosen.
 *
 * @internal
 */
export const deriveCurrentEnvironment = () => {
  const url = window.location.href;
  let detectedEnvironment: PortalEnvironment | undefined;

  urlIdentifierMap.forEach((value: string[], key: PortalEnvironment) => {
    value.forEach((urlIdentifier: string) => {
      if (url.includes(urlIdentifier)) {
        detectedEnvironment = key;
      }
    });
  });

  // The default environment is tst.
  if (!detectedEnvironment) {
    detectedEnvironment = PortalEnvironment.tst;
  }

  return detectedEnvironment;
};

/**
 * This is used to determine the Portal Type.
 * [IMP]: This is meant to be an internal implementation and should not be exposed to teams.
 * This is used to determine the portal type 'partner', 'seops', etc.
 *
 * @internal
 */
export const getPortalTypeIdentifier = async (): Promise<string> => {
  const hostname = window.location.hostname.toLowerCase();
  const domainIdentified = hostname.split('.')[0];

  const portalDomainConnector = await portalDomainsConnector();
  if (portalDomainConnector) {
    const portalIdentified = portalDomainConnector.domainPortals[domainIdentified];
    if (portalIdentified) {
      return portalIdentified;
    }
  }

  // Try to use the domain to determine the portal if portalDomainsConnector fails.
  return domainIdentified;
};
