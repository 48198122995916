import { httpRequestRetryStore } from '../../services/http-request-store';
import { HttpFunctions } from '../../services/http-service';
import { trackError } from '../../services/telemetry-service/library-telemetry-service';
import { ErrorInformation } from '../../services/telemetry-service/types/error-information';
import { ConnectorPromiseStore } from '../../services/types/window-connectors';
import { HarmonyConnectorError } from '../connector-error';
import { localeConnector } from '../locale-connector/locale-connector';
import { AuthContext, AuthContextType } from './auth-context-connector';

let authContextData: AuthContextPartnerPortalApi | undefined;
const authContextUrl = '/api/user/AuthContext';
const ErrorSource = 'AuthContextConnectorPartnerPortal';
const FailureAuthContext = 'Failure while trying to retrieve auth Context';

// This is the unique config Id for auth workspace connector. You need to change this if there is a change in design.
const CONFIG_ID = 'V2';

type AuthContextPartnerPortalApi = {
  msGraphToken: string;
  partnerCenterAppToken: string;
  partnerApiToken: string;
  armToken: string;
  copilotToken: string;
};

type TokenTypePartnerPortal = 'msGraphToken' | 'partnerCenterAppToken' | 'partnerApiToken' | 'armToken' | 'copilotToken';

const TokenTypeConversion = new Map<AuthContextType, TokenTypePartnerPortal>([
  [AuthContextType.MSGraph, 'msGraphToken'],
  [AuthContextType.PartnerCenterApp, 'partnerCenterAppToken'],
  [AuthContextType.PartnerApi, 'partnerApiToken'],
  [AuthContextType.ARM, 'armToken'],
  [AuthContextType.CopilotToken, 'copilotToken'],
]);

/**
 * Auth Context Connector for Partner Portal. This handles the behavior on how to get token on partner portal side. This is using
 * Classic Front Door Setup.
 * @param authContextType Auth context types as requested by workspace.
 * @returns The token.
 */
export const authContextConnectorPartnerPortal = async (authContextType: AuthContextType[], forceNew: boolean) => {
  const authContext: AuthContext = {};
  if (authContextData && !forceNew) {
    deriveTokenFromAuthContext(authContextType, authContext);
    if (authContext) return authContext;
  }

  const locale = localeConnector();
  const baseUrl = `${window.location.origin}/${locale}`;
  const url = `${baseUrl}${authContextUrl}`;
  const connectorPromiseStoreConfig: ConnectorPromiseStore = {
    connectorType: 'authContext',
    uniqueId: url + CONFIG_ID,
  };
  try {
    const authContextResponse = await httpRequestRetryStore<AuthContextPartnerPortalApi>(connectorPromiseStoreConfig, url, HttpFunctions.GET, null, null, 'same-origin', forceNew);
    if (authContextResponse) {
      authContextData = authContextResponse;
    }

    deriveTokenFromAuthContext(authContextType, authContext);
  } catch (error: any) {
    const message: string = error?.message ?? FailureAuthContext;
    const statusCode: string = error?.statusCode ?? '500';

    const errorInformation: ErrorInformation = {
      errorMessage: message,
      source: ErrorSource,
      statusCode: statusCode,
      errorObject: JSON.stringify(error) ?? '',
    };
    trackError('auth-context-failure', errorInformation);
    throw new HarmonyConnectorError(ErrorSource, FailureAuthContext);
  }

  return authContext;
};

/**
 * This is to derive the tokens from the auth context. In the partner portal we have 1 api which provides the list of all the tokens.
 * @param tokenType Token types that is exposed to library consumers.
 * @param authContext The auth context response to return in the authContextConnector.
 * @returns the modified token type with respect to Partner Portal.
 * */
const deriveTokenFromAuthContext = (tokenType: AuthContextType[], authContext: AuthContext): void => {
  if (authContextData) {
    tokenType.forEach(token => {
      const convertedTokenType = convertTokenType(token);
      const selectedToken = authContextData?.[convertedTokenType];
      if (selectedToken) {
        authContext[token] = selectedToken;
      } else {
        throw new HarmonyConnectorError('AuthContextConnectorPartnerPortal', `Token ${token} is not available in authContextData`);
      }
    });
    return;
  }

  throw new HarmonyConnectorError('AuthContextConnectorPartnerPortal', 'Response from Auth Context Api not available');
};

/**
 * The Token Type contract exposed via library is different from how different portal specific connectors handles the behavior.
 * Given that we want to expose a single contract across partner center and multi portal , we need to do the key conversion
 * while using the api response.
 * @param authContextType Token Type that is exposed to library consumers
 * @returns modified Token type with respect to Partner Portal.
 */
const convertTokenType = (authContextType: AuthContextType): TokenTypePartnerPortal => {
  if (TokenTypeConversion.has(authContextType)) {
    return TokenTypeConversion.get(authContextType) as TokenTypePartnerPortal;
  }

  throw new TypeError(`Invalid Token Type - ${authContextType}`);
};
