import { property, query, queryAssignedElements, queryAssignedNodes, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { html, literal } from 'lit/static-html.js';
import { when } from 'lit/directives/when.js';
import { FormSubmitController } from '../../internal/form';
import { HasSlotController } from '../../internal/slot';
import { getScope } from '../../utilities/scope';
import { isIconOnly } from '../../utilities/slot';
import icon from '../icon/icon';
import HarmonyElement from '../';
import styles from './button.styles';
import type { LooseString } from '../../types';

/**
 * Button appearance options.
 */
export type ButtonAppearance =
  | 'primary' // blue button
  | 'secondary' // clear button with a border - default
  | 'command' // has a blue icon, mainly for use in command bar
  | 'stealth' // button background only visible on hover, often used for icon buttons
  | 'link'; // looks identical to an anchor link

/**
 * @tag he-button
 * @since 1.3
 * @status stable
 * @design approved
 * @figma https://www.figma.com/file/dRwBPvZFZdYgWdAOCK375K/PC-Toolkit?node-id=5%3A889
 *
 * @dependency he-icon
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-blur - Emitted when the button loses focus.
 * @event he-focus - Emitted when the button gains focus.
 *
 * @slot - The button's content.
 * @slot start - Used to prepend an icon or similar element to the button.
 * @slot end - Used to append an icon or similar element to the button.
 *
 * @csspart control - The component's internal wrapper.
 * @csspart start - The start slot's container.
 * @csspart content - The button's content.
 * @csspart end - The suffix slot's container.
 * @csspart icons - The container of of the caret and external link icons.
 * @csspart caret-icon - The button's caret icon.
 * @csspart external-icon - The button's external icon.
 */
export class Button extends HarmonyElement {
  static styles = [styles];
  static baseName = 'button';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeFocus: new CustomEvent('he-focus'),
    onHeBlur: new CustomEvent('he-blur'),
  };

  scope = getScope(this);

  @query('.button') button: HTMLButtonElement | HTMLLinkElement;
  @query('.control') control: HTMLButtonElement | HTMLLinkElement;
  @queryAssignedNodes({ flatten: true }) private slottedNodes!: Array<Node>;
  @queryAssignedElements({ slot: 'start', flatten: true }) private startElements!: Array<HTMLElement>;
  @queryAssignedElements({ slot: 'end', flatten: true }) private endElements!: Array<HTMLElement>;

  private readonly formSubmitController = new FormSubmitController(this, {
    form: (input: HTMLButtonElement) => {
      // Buttons support a form attribute that points to an arbitrary form, so if this attribute it set we need to query
      // the form from the same root using its id
      if (this.form) {
        return this.findRootNode(this).getElementById(this.form) as HTMLFormElement;
      }

      // Fall back to the closest containing form
      return input.closest('form');
    },
  });

  private readonly hasSlotController = new HasSlotController(this, '[default]', 'start', 'end');

  @state() protected caretIcon = html`<${this.scope.tag('icon')}
    class="icon caret-icon"
    part="caret-icon"
    name="ChevronDown"
  ></${this.scope.tag('icon')}>`;

  @state() protected externalIcon = html`<${this.scope.tag('icon')}
    class="icon external-icon"
    part="external-icon"
    name="OpenInNewWindow"
    label=${this.localize.term('open_in_new_window')}
  ></${this.scope.tag('icon')}>`;

  @state() private hasFocus = false;

  /** The button's appearance. */
  @property({ reflect: true }) appearance: ButtonAppearance = 'secondary';

  /** Draws the button with a caret for use with dropdowns, popovers, etc. */
  @property({ type: Boolean, reflect: true }) caret: boolean;

  /** Disables the button. */
  @property({ type: Boolean, reflect: true }) disabled: boolean;

  /** Whether link should show the 'OpenInNewWindow' icon. */
  @property({ type: Boolean, reflect: true }) external: boolean;

  /**
   * The type of button. When the type is `submit`, the button will submit the surrounding form. Note that the default
   * value is `button` instead of `submit`, which is opposite of how native `<button>` elements behave.
   */
  @property({ reflect: true }) type: 'button' | 'submit' | 'reset' = 'button';

  /** An optional name for the button. Ignored when `href` is set. */
  @property() name?: string;

  /** An optional value for the button. Ignored when `href` is set. */
  @property() value?: string;

  /** When set, the underlying button will be rendered as an `<a>` with this `href` instead of a `<button>`. */
  @property() href?: string;

  /** Tells the browser where to open the link. Only used when `href` is set. */
  @property() target?: LooseString<'_blank' | '_parent' | '_self' | '_top'>;

  /** Tells the browser to download the linked file as this filename. Only used when `href` is set. */
  @property() download?: string;

  /**
   * The "form owner" to associate the button with. If omitted, the closest containing form will be used instead. The
   * value of this attribute must be an id of a form in the same document or shadow root as the button.
   */
  @property() form: string;

  /** Used to override the form owner's `action` attribute. */
  @property({ attribute: 'formaction' }) formAction: string;

  /** Used to override the form owner's `method` attribute. */
  @property({ attribute: 'formmethod' }) formMethod: 'post' | 'get';

  /** Used to override the form owner's `novalidate` attribute. */
  @property({ attribute: 'formnovalidate', type: Boolean, reflect: true }) formNoValidate: boolean;

  /** Used to override the form owner's `target` attribute. */
  @property({ attribute: 'formtarget' }) formTarget: LooseString<'_self' | '_blank' | '_parent' | '_top'>;

  /** Defining which referrer is sent when fetching the resource. Only applies to links. */
  @property({ attribute: 'referrerpolicy' }) referrerPolicy: LooseString<
    | 'no-referrer'
    | 'no-referrer-when-downgrade'
    | 'origin'
    | 'origin-when-cross-origin'
    | 'same-origin'
    | 'strict-origin'
    | 'strict-origin-when-cross-origin'
    | 'unsafe-url'
  > = 'strict-origin-when-cross-origin';

  /** Focus on the button on page load. */
  @property({ type: Boolean, reflect: true }) autofocus: boolean;

  // Intentionally undocumented
  /** @internal */
  @property({ attribute: 'aria-expanded', reflect: true }) ariaExpanded: 'true' | 'false' | null;
  /** @internal */
  @property({ attribute: 'aria-pressed', reflect: true }) ariaPressed: 'true' | 'false' | null;

  /** Sets "aria-current" on the internal button or link. */
  @property({ reflect: true }) current: 'page' | 'step' | 'location' | 'date' | 'time' | 'true' | 'false' | null;

  constructor() {
    super();
    this.scope.registerComponent(icon);
  }

  /** Simulates a click on the button. */
  click() {
    this.button?.click();
  }

  /** Sets focus on the button. */
  focus(options?: FocusOptions) {
    this.button?.focus(options);
  }

  /** Removes focus from the button. */
  blur() {
    this.button?.blur();
  }

  protected handleBlur() {
    this.hasFocus = false;
    this.emit('he-blur');
  }

  protected handleFocus() {
    this.hasFocus = true;
    this.emit('he-focus');
  }

  protected handleClick(event: MouseEvent) {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (this.type === 'submit') {
      this.formSubmitController.submit(this);
    }

    if (this.type === 'reset') {
      this.formSubmitController.reset(this);
    }
  }

  render() {
    const isLink = this.href ? true : false;
    const tag = isLink ? literal`a` : literal`button`;

    return html`
      <${tag}
        part="control"
        class=${classMap({
          button: true,
          control: true,
          [`button--${this.appearance}`]: true,
          'button--caret': this.caret,
          'button--disabled': this.disabled,
          'button--focused': this.hasFocus,
          'button--standard': true,
          'button--has-content': this.hasSlotController.test('[default]'),
          'button--has-start': this.startElements.length > 0,
          'button--has-end': this.endElements.length > 0,
          'icon-only': isIconOnly(this.slottedNodes),
        })}
        type=${ifDefined(isLink ? undefined : this.type)}
        name=${ifDefined(isLink ? undefined : this.name)}
        value=${ifDefined(isLink ? undefined : this.value)}
        href=${ifDefined(isLink ? this.href : undefined)}
        target=${ifDefined(isLink ? this.target : undefined)}
        download=${ifDefined(isLink ? this.download : undefined)}
        rel=${ifDefined(isLink && this.target ? 'noreferrer noopener' : undefined)}
        referrerpolicy=${ifDefined(isLink ? this.referrerPolicy : undefined)}
        aria-disabled=${this.disabled ? 'true' : 'false'}
        aria-current=${ifDefined(this.current ? this.current : undefined)}
        aria-expanded=${ifDefined(this.ariaExpanded ? this.ariaExpanded : undefined)}
        aria-pressed=${ifDefined(this.ariaPressed ? this.ariaPressed : undefined)}
        tabindex=${isLink && this.disabled ? '-1' : '0'}
        ?autofocus=${this.autofocus}
        @blur=${this.handleBlur}
        @focus=${this.handleFocus}
        @click=${this.handleClick}
      >
        <span part="start" class="start">
          <slot name="start"></slot>
        </span>
        <span part="content" class="content">
          <slot></slot>
        </span>
        <span part="end" class="end">
          <slot name="end"></slot>
        </span>
        ${when(
          this.caret || this.external,
          () => html`
            <span part="icons" class="internal-icons">
              ${this.caret ? this.caretIcon : ''} ${this.external ? this.externalIcon : ''}
            </span>
          `
        )}
      </${tag}>
    `;
  }
}

export default Button;
