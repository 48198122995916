export default {
  "back": "Voltar",
  "breadcrumb": "Navegação Estrutural",
  "clear": "Limpar",
  "clear_entry": "Limpar entrada",
  "close": "Fechar",
  "collapse_task_menu": "Recolher menu de tarefas",
  "collapse": "Recolher",
  "commands": "Comandos",
  "complete": "Concluir",
  "day": "Dia",
  "dislike": "Descurtir",
  "error": "Erro",
  "expand_task_menu": "Expandir menu de tarefas",
  "expand": "Expandir",
  "filter": "Filtro",
  "finish": "Concluir",
  "first": "Primeiro",
  "go_to_page": "Ir para a página {0}",
  "hide_password": "Ocultar senha",
  "last": "Último",
  "like": "Curtir",
  "loading": "Carregando...",
  "month": "Mês",
  "more_options": "Mais Opções",
  "navigation": "Navegação",
  "next": "Avançar",
  "next_month": "Próximo mês",
  "not_started": "Não iniciado",
  "off": "Desativado",
  "on": "Ativado",
  "open_in_new_window": "Abrir em nova janela",
  "options": "Opções",
  "pagination": "Paginação",
  "plus_x_more": "+{0} mais",
  "previous": "Anterior",
  "previous_month": "Mês anterior",
  "progress": "Em andamento",
  "quick_start": "Início rápido",
  "range_error_message": "A data que você selecionou não está dentro do intervalo de datas",
  "required_error_message": "Este campo é obrigatório",
  "results_found": "{0} resultados encontrados",
  "search": "Pesquisar",
  "select_all": "Selecionar tudo",
  "select_row": "Selecionar a linha",
  "show_calendar": "Mostrar calendário",
  "show_password": "Mostrar senha",
  "step_x_of_y": "Etapa {0} de {1}",
  "today": "Hoje",
  "unavailable_date_error_message": "A data que você selecionou não está disponível",
  "warning": "Aviso",
  "year": "Ano"
}