export default {
  "back": "Kembali",
  "breadcrumb": "Breadcrumb",
  "clear": "Bersihkan",
  "clear_entry": "Hapus entri",
  "close": "Tutup",
  "collapse_task_menu": "Ciutkan menu tugas",
  "collapse": "Tutup",
  "commands": "Perintah",
  "complete": "Selesai",
  "day": "Hari",
  "dislike": "Tidak suka",
  "error": "Kesalahan",
  "expand_task_menu": "Perluas menu tugas",
  "expand": "Buka",
  "filter": "Filter",
  "finish": "Selesai",
  "first": "Pertama",
  "go_to_page": "Buka halaman {0}",
  "hide_password": "Sembunyikan kata sandi",
  "last": "Terakhir",
  "like": "Suka",
  "loading": "Memuat...",
  "month": "Bulan",
  "more_options": "Opsi Lainnya",
  "navigation": "Navigasi",
  "next": "Berikutnya",
  "next_month": "Bulan berikutnya",
  "not_started": "Belum dimulai",
  "off": "Nonaktif",
  "on": "Aktif",
  "open_in_new_window": "Buka di jendela baru",
  "options": "Opsi",
  "pagination": "Penomoran halaman",
  "plus_x_more": "+{0} lainnya",
  "previous": "Sebelumnya",
  "previous_month": "Bulan sebelumnya",
  "progress": "Sedang berlangsung",
  "quick_start": "Mulai cepat",
  "range_error_message": "Tanggal yang Anda pilih tidak berada dalam rentang tanggal",
  "required_error_message": "Kolom ini wajib diisi",
  "results_found": "{0} hasil ditemukan",
  "search": "Cari",
  "select_all": "Pilih semua",
  "select_row": "Pilih baris",
  "show_calendar": "Tampilkan kalender",
  "show_password": "Tampilkan kata sandi",
  "step_x_of_y": "Langkah {0} dari {1}",
  "today": "Hari ini",
  "unavailable_date_error_message": "Tanggal yang Anda pilih tidak tersedia",
  "warning": "Peringatan",
  "year": "Tahun"
}