import { html, literal } from 'lit/static-html.js';
import { property, queryAssignedElements, queryAsync, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { when } from 'lit/directives/when.js';
import { getScope } from '../../utilities/scope';
import { watch } from '../../internal/watch';
import { Menu } from '../menu/menu';
import icon from '../icon/icon';
import popup from '../popup/popup';
import HarmonyElement from '../';
import { HasSlotController } from '../../internal/slot';
import styles from './menu-item.styles';

export type MenuItemColumnCount = 0 | 1 | 2;
export type MenuItemRole = 'menuitem' | 'menuitemcheckbox' | 'menuitemradio';

/**
 * @tag he-menu-item
 * @since 1.3
 * @status stable
 *
 * @dependency he-icon
 * @dependency he-popup
 *
 * @slot - The content of the menu item.
 * @slot start - Contents of the start slot are positioned before the item content.
 * @slot end - Contents of the end slot are positioned after the item content.
 * @slot submenu - The submenu slot - child menus are automatically placed here.
 * @slot checkbox-indicator - The checkbox indicator slot.
 * @slot menuItem-indicator - The menuItem indicator slot.
 * @slot expand-collapse-indicator - The expand/collapse indicator slot.
 *
 * @csspart start
 * @csspart content
 * @csspart end
 * @csspart submenu-region
 * @csspart checkbox
 * @csspart checkbox-indicator
 * @csspart radio
 * @csspart radio-indicator
 * @csspart expand-collapse
 * @csspart expand-collapse-glyph-container
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-change - Emitted when the item has been clicked or invoked via keyboard, and will be prevented if the menu item is disabled.
 * @event he-expanded-change - Emitted when the item has been expanded or collapsed.
 */
export class MenuItem extends HarmonyElement {
  static styles = [styles];
  static baseName = 'menu-item';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeChange: new CustomEvent('he-change'),
    onHeExpandedChange: new CustomEvent('he-expanded-change'),
  };

  private scope = getScope(this);
  private readonly hasSlotController = new HasSlotController(this, '[default]', 'start', 'end');
  @state() hasSubmenu: boolean = false;
  @state() startColumnCount: MenuItemColumnCount = 0;
  @state() quickNav: boolean = false; // he-quick-nav sets this on slotted menu items
  @state() private submenu?: HTMLElement;

  @queryAssignedElements({ slot: 'submenu', selector: '[role="menu"]', flatten: true })
  private slottedMenus!: Array<HTMLElement>;

  @queryAsync('a')
  private link: Promise<HTMLAnchorElement | null>;

  private get _role(): MenuItemRole {
    return this.getAttribute('role') === 'none' ? 'menuitem' : this.role;
  }

  private get isLink(): boolean {
    const canBeLink = !!this.href && !this.hasSubmenu && !this.disabled;

    // quicknav used to assign radio to all menu items. if a quicknav has a href, assume they want links and not radios.
    if (canBeLink && this.quickNav && this._role === 'menuitemradio') return true;

    return canBeLink && this._role === 'menuitem';
  }

  constructor() {
    super();
    this.scope.registerComponent(icon, popup);
  }

  /** @deprecated Use `href` instead. */
  @property({ attribute: 'nav-to' }) navTo?: string;

  /** @deprecated Use `target` instead. */
  @property({ attribute: 'nav-target' }) navTarget?: '_self' | '_blank' | '_parent' | '_top' | string;

  /**
   * URL to navigate to when the menu item is clicked. If this attribute is set, the menu item will render as an anchor
   * tag. This attribute is ignored if the menu item is a "menuitemcheckbox", "menuitemradio", or has a submenu.
   */
  @property({ reflect: true }) href?: string;

  /**
   * The target window or frame to open the link in. This attribute is ignored if the menu item is a "menuitemcheckbox",
   * "menuitemradio", or has a submenu.
   */
  @property() target?: '_self' | '_blank' | '_parent' | '_top' | string;

  /** For menu items with an `href`, marks this menu item as the current page in a navigation list. */
  @property({ type: Boolean, reflect: true }) current: boolean;

  @watch('navTo')
  handleNavToChange() {
    if (this.navTo) this.href = this.navTo;
  }

  @watch('navTarget')
  handleNavTargetChange() {
    if (this.navTarget) this.target = this.navTarget;
  }

  @watch('href', { waitUntilFirstUpdate: true })
  hrefChanged() {
    this.setRole();
  }

  /** Disables the menu item. */
  @property({ type: Boolean, reflect: true }) disabled: boolean = false;

  @watch('disabled')
  disabledChanged() {
    this.disabled ? this.setAttribute('aria-disabled', 'true') : this.removeAttribute('aria-disabled');
  }

  /** Open status of child submenus. */
  @property({ type: Boolean, reflect: true }) expanded: boolean = false;

  @watch('expanded')
  expandedChanged() {
    if (this.hasSubmenu) {
      this.emit('he-expanded-change', { bubbles: false });
    }
  }

  @watch('expanded')
  @watch('hasSubmenu')
  setAriaExpanded() {
    this.hasSubmenu
      ? this.setAttribute('aria-expanded', this.expanded.toString())
      : this.removeAttribute('aria-expanded');
  }

  /** The ARIA role of the menu item. */
  @property({ reflect: true }) role: MenuItemRole = 'menuitem';

  @watch('role', { waitUntilFirstUpdate: true })
  roleChanged() {
    this.setRole();
    this.setAriaChecked();

    // recalculate indents if role is changed
    if (this.parentElement instanceof Menu) this.parentElement.setItems();
  }

  /** The checked state for elements with a role of 'menuitemradio' or 'menuitemcheckbox'. */
  @property({ type: Boolean, reflect: true }) checked: boolean;

  @watch('checked', { waitUntilFirstUpdate: true })
  checkedChanged() {
    this.setAriaChecked();

    this.emit('he-change');
  }

  /** Enable this option to prevent the submenu from being clipped when the component is placed inside a container with `overflow: auto|hidden|scroll`. */
  @property({ attribute: 'submenu-fixed-placement', type: Boolean, reflect: true })
  submenuFixedPlacement: boolean = false;

  @watch('submenu')
  @watch('role')
  submenuChanged() {
    this.hasSubmenu = !!this.submenu && this._role === 'menuitem';
    this.hasSubmenu ? this.setAttribute('aria-haspopup', 'menu') : this.removeAttribute('aria-haspopup');
  }

  @watch('quickNav')
  quickNavChanged() {
    this.setRole();
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('keydown', this.handleMenuItemKeyDown);
    this.addEventListener('click', this.handleMenuItemClick);
    this.addEventListener('mouseover', this.handleMouseOver);
    this.addEventListener('mouseout', this.handleMouseOut);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('keydown', this.handleMenuItemKeyDown);
    this.removeEventListener('click', this.handleMenuItemClick);
    this.removeEventListener('mouseover', this.handleMouseOver);
    this.removeEventListener('mouseout', this.handleMouseOut);
    this.submenu = undefined;
  }

  firstUpdated() {
    this.setRole();
    this.setAriaChecked();
  }

  async click() {
    if (this.isLink && !this.disabled) (await this.link)?.click();
    else super.click();
  }

  private setAriaChecked() {
    this.role === 'menuitemradio' || this.role === 'menuitemcheckbox'
      ? this.setAttribute('aria-checked', (!!this.checked).toString())
      : this.removeAttribute('aria-checked');
  }

  private setRole() {
    this.setAttribute('role', this.isLink ? 'none' : this.quickNav ? 'menuitemradio' : this._role);
  }

  private async invoke() {
    if (this.disabled) {
      return;
    }

    switch (this._role) {
      case 'menuitemcheckbox':
        this.checked = !this.checked;
        break;

      case 'menuitem':
        if (this.hasSubmenu) {
          this.expandAndFocus();
        } else {
          this.emit('he-change');
        }
        break;

      case 'menuitemradio':
        if (!this.checked) {
          this.checked = true;
        }
        break;
    }
  }

  private handleMenuItemKeyDown = (e: KeyboardEvent): boolean => {
    if (e.defaultPrevented) {
      return false;
    }

    switch (e.key) {
      case 'Enter':
      case ' ':
        this.click();
        e.preventDefault();
        return false;

      case 'ArrowRight':
        //open/focus on submenu
        this.expandAndFocus();
        e.preventDefault();
        return false;

      case 'ArrowLeft':
        //close submenu
        if (this.expanded) {
          this.expanded = false;
          this.focus();
          e.preventDefault();
          return false;
        }
    }

    return true;
  };

  private handleMenuItemClick = async (e: MouseEvent) => {
    if (e.defaultPrevented || this.disabled) {
      return false;
    }

    this.invoke();

    return true;
  };

  private handleMouseOver = (e: MouseEvent): boolean => {
    if (this.disabled || !this.hasSubmenu || this.expanded) {
      return false;
    }

    this.expanded = true;

    return false;
  };

  private handleMouseOut = (e: MouseEvent): boolean => {
    if (
      !this.expanded ||
      this.contains(this.findRootNode(this)?.activeElement) ||
      (e.target !== this && this.contains(this.querySelector(':hover')))
    ) {
      return false;
    }

    this.expanded = false;

    return false;
  };

  expandAndFocus = async () => {
    if (!this.hasSubmenu) {
      return;
    }

    this.expanded = true;

    await this.updateComplete;
    (this.submenu as HTMLElement).focus();
    this.setAttribute('tabindex', '-1');
  };

  private handleSubmenuSlotChange = () => {
    this.submenu = this.slottedMenus[0] ? this.slottedMenus[0] : undefined;
  };

  private handleStartSlotChange = () => {
    // recalculate indents if start slot is changed
    if (this.parentElement instanceof Menu) this.parentElement.setItems();
  };

  protected render() {
    const tag = this.isLink ? literal`a` : literal`span`;

    const menuItem = html`
      <${tag}
        slot=${ifDefined(this.hasSubmenu ? 'anchor' : undefined)}
        part="base"
        class=${classMap({
          'menu-item': true,
          'menu-item--disabled': this.disabled,
          'menu-item--expanded': this.expanded,
          [`menu-item--indent-${this.startColumnCount}`]: true,
          'menu-item--quicknav': this.quickNav,
          [`menu-item--${this.dir}`]: true,
          'menu-item--has-content': this.hasSlotController.test('[default]'),
          'menu-item--has-start': this.hasSlotController.test('start'),
          'menu-item--has-end': this.hasSlotController.test('end'),
          'menu-item--has-submenu': this.hasSubmenu,
          'menu-item--link': this.isLink,
          'menu-item--current': this.isLink && this.current,
        })}
        role=${ifDefined(this.isLink ? 'menuitem' : undefined)}
        aria-current=${ifDefined(this.isLink && this.current ? 'page' : undefined)}
        href=${ifDefined(this.isLink ? this.href : undefined)}
        target=${ifDefined(this.isLink ? this.target : undefined)}
        tabindex=${ifDefined(this.isLink ? '-1' : undefined)}
      >
        ${when(
          this.role === 'menuitemcheckbox',
          () => html`
        <span part="input-container" class="input-container">
          <span part="checkbox" class="checkbox">
            <slot name="checkbox-indicator">
              <${this.scope.tag('icon')}
                name="checkmark"
                part="checkbox-indicator"
                class="checkbox-indicator"
              ></${this.scope.tag('icon')}>
            </slot>
          </span>
        </span>
      `
        )}
        ${when(
          this.role === 'menuitemradio' && !this.quickNav,
          () => html`
            <span part="input-container" class="input-container">
              <span part="radio" class="radio">
                <slot name="radio-indicator">
                  <span part="radio-indicator" class="radio-indicator"></span>
                </slot>
              </span>
            </span>
          `
        )}
        <span part="start" class="start">
          <slot name="start" @slotchange=${this.handleStartSlotChange}></slot>
        </span>
        <span class="content" part="content">
          <slot></slot>
        </span>
        <span part="end" class="end">
          <slot name="end"></slot>
        </span>
        ${when(
          this.hasSubmenu,
          () => html`
            <span part="expand-collapse-glyph-container" class="expand-collapse-glyph-container">
              <span part="expand-collapse" class="expand-collapse">
                <slot name="expand-collapse-indicator">
                  <${this.scope.tag('icon')}
                    name="ChevronRight"
                    class="expand-collapse-glyph"
                    part="expand-collapse-glyph"
                  ></${this.scope.tag('icon')}>
                </slot>
              </span>
            </span>
          `
        )}
      </${tag}>
    `;

    const submenuSlot = html`<slot name="submenu" @slotchange=${this.handleSubmenuSlotChange}></slot>`;

    return html`
      ${when(
        this.hasSubmenu,
        () => html`
          <${this.scope.tag('popup')}
            ?active=${this.expanded}
            placement=${this.dir === 'rtl' ? 'left-start' : 'right-start'}
            flip
            flip-fallback-strategy="best-fit"
            flip-fallback-placements="left right bottom top"
            strategy=${this.submenuFixedPlacement ? 'fixed' : 'absolute'}
            part="popup"
            class="popup"
          >
            ${menuItem}
            ${submenuSlot}
          </${this.scope.tag('popup')}>
        `,
        () => html`
          ${menuItem}
          <div hidden>${submenuSlot}</div>
        `
      )}
    `;
  }
}

export default MenuItem;
