export default {
  "back": "Natrag",
  "breadcrumb": "Breadcrumb",
  "clear": "Očisti",
  "clear_entry": "Očisti unos",
  "close": "Zatvori",
  "collapse_task_menu": "Sažmi izbornik zadataka",
  "collapse": "Sažmi",
  "commands": "Naredbe",
  "complete": "Dovrši",
  "day": "Dan",
  "dislike": "Ne sviđa mi se",
  "error": "Pogreška",
  "expand_task_menu": "Proširi izbornik zadataka",
  "expand": "Proširi",
  "filter": "Filtriraj",
  "finish": "Dovrši",
  "first": "Prvi",
  "go_to_page": "Idi na stranicu {0}",
  "hide_password": "Sakrij lozinku",
  "last": "Zadnje",
  "like": "Sviđa mi se",
  "loading": "Učitavanje...",
  "month": "Mjesec",
  "more_options": "Više mogućnosti",
  "navigation": "Navigacija",
  "next": "Dalje",
  "next_month": "Sljedeći mjesec",
  "not_started": "Nije započeto",
  "off": "Isključeno",
  "on": "Uključeno",
  "open_in_new_window": "Otvori u novom prozoru",
  "options": "Mogućnosti",
  "pagination": "Broj stranice",
  "plus_x_more": "+ još{0}",
  "previous": "Prethodno",
  "previous_month": "Prethodni mjesec",
  "progress": "U tijeku",
  "quick_start": "Brzi početak",
  "range_error_message": "Datum koji ste odabrali nije unutar datumskog raspona",
  "required_error_message": "Ovo je polje obavezno",
  "results_found": "Pronađeno je ovoliko rezultata: {0}",
  "search": "Pretraživanje",
  "select_all": "Odaberi sve",
  "select_row": "Odabir retka",
  "show_calendar": "Pokaži kalendar",
  "show_password": "Pokaži lozinku",
  "step_x_of_y": "Korak {0} od {1}",
  "today": "Danas",
  "unavailable_date_error_message": "Datum koji ste odabrali nije dostupan",
  "warning": "Upozorenje",
  "year": "Godina"
}