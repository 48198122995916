import { css } from 'lit';

export const visuallyHiddenStyles = css`
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  border: none !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  padding: 0 !important;
`;
