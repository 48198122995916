export default {
  "back": "Tilbake",
  "breadcrumb": "Søkebane",
  "clear": "Fjern",
  "clear_entry": "Fjern oppføring",
  "close": "Lukk",
  "collapse_task_menu": "Skjul oppgavemenyen",
  "collapse": "Skjul",
  "commands": "Kommandoer",
  "complete": "Fullfør",
  "day": "Dag",
  "dislike": "Liker ikke",
  "error": "Feil",
  "expand_task_menu": "Utvid oppgavemenyen",
  "expand": "Utvid",
  "filter": "Filter",
  "finish": "Fullfør",
  "first": "Første",
  "go_to_page": "Gå til side {0}",
  "hide_password": "Skjul passord",
  "last": "Last",
  "like": "Inneholder",
  "loading": "Laster inn ...",
  "month": "Måned",
  "more_options": "Flere alternativer",
  "navigation": "Navigasjon",
  "next": "Neste",
  "next_month": "Neste måned",
  "not_started": "Ikke startet",
  "off": "Av",
  "on": "På",
  "open_in_new_window": "Åpne i nytt vindu.",
  "options": "Alternativer",
  "pagination": "Sideinndeling",
  "plus_x_more": "+{0} mer",
  "previous": "Forrige",
  "previous_month": "Forrige måned",
  "progress": "Pågår",
  "quick_start": "Hurtigstart",
  "range_error_message": "Den valgte datoen er ikke innenfor datointervallet",
  "required_error_message": "Dette feltet er obligatorisk",
  "results_found": "{0} resultater funnet",
  "search": "Søk",
  "select_all": "Velg alle",
  "select_row": "Velg rad",
  "show_calendar": "Vis kalender",
  "show_password": "Vis passordet",
  "step_x_of_y": "Trinn {0} av {1}",
  "today": "I dag",
  "unavailable_date_error_message": "Den valgte datoen er ikke tilgjengelig",
  "warning": "Advarsel",
  "year": "År"
}