import { CookieKeys } from '../../helpers/constant';
import { deriveValueFromCookie } from '../../helpers/cookie-helper';
import { SupportedLocaleEnums } from '../../utilities/supported-locale-enums';

/**
 * @description It is the locale connector which provides the locale for the current session.
 * Used to identify the Locale in Partner Portal.
 * Considering both V1 and VNext scenarios.
 * Order of precedence:
 *  1. Locale specified in the MarketplaceSelectedLocale cookie
 *  2. Locale Specified in the URL.
 *  3. Default to en-us
 * @internal context.
 * @note You should create dependency on library usage context, instead of directly on the locale-helper.
 */
export const deriveCurrentLocale = (): SupportedLocaleEnums => {
  /** Tries to get the locale from cookie */
  const localeCookie = getLocaleFromCookie();
  if (localeCookie) {
    return localeCookie;
  }

  /** Tries to get the locale from URL */
  const localeURL = getLocaleFromPartnerPortalUrl();
  if (localeURL) {
    return localeURL;
  }

  /** Default Locale to be returned if none is found. */
  return SupportedLocaleEnums.en_us;
};

/**
 * This test whether the given string is a valid Locale
 * according to the Locale Enum.
 * @param potentialLocale string to test
 * @returns the Locale found or null.
 */
const isLocaleSupported = (potentialLocale: string): SupportedLocaleEnums | null => {
  const localesFound = Object.values(SupportedLocaleEnums).filter(value => (value as string).toLowerCase() === potentialLocale) as SupportedLocaleEnums[];

  //If empty returns null or the first Locale in the List.
  if (localesFound && localesFound.length >= 1) {
    return localesFound[0];
  }
  return null;
};

/**
 * Using cookie available on Client side to derive the current locale.
 * @returns Locale found from the cookie.
 */
const getLocaleFromCookie = (): SupportedLocaleEnums | null => {
  const cookieLocale = deriveValueFromCookie(CookieKeys.Locale);
  if (cookieLocale) {
    const localeEnumFromCookie = isLocaleSupported(cookieLocale);
    if (localeEnumFromCookie) {
      return localeEnumFromCookie;
    }
  }
  return null;
};

/**
 * Using Partner Centre Url to find the Locale.
 */
const getLocaleFromPartnerPortalUrl = (): SupportedLocaleEnums | null => {
  const locationPathName = location.pathname.split('/');
  if (locationPathName && locationPathName.length >= 2) {
    /**
     * Expected pathname '/fr-fr/..
     * So that is why selecting the index 1 for the locale.
     */
    const localeFromURL = isLocaleSupported(locationPathName[1]);
    if (localeFromURL) {
      return localeFromURL;
    }
  }

  return null;
};
