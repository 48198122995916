export default {
  "back": "Wstecz",
  "breadcrumb": "Łącza do stron nadrzędnych",
  "clear": "Wyczyść",
  "clear_entry": "Wyczyść wpis",
  "close": "Zamknij",
  "collapse_task_menu": "Zwiń menu zadań",
  "collapse": "Zwiń",
  "commands": "Polecenia",
  "complete": "Ukończ",
  "day": "Dzień",
  "dislike": "Nie lubię",
  "error": "Błąd",
  "expand_task_menu": "Rozwiń menu zadań",
  "expand": "Rozwiń",
  "filter": "Filtruj",
  "finish": "Zakończ",
  "first": "Pierwsze",
  "go_to_page": "Przejdź do strony {0} ",
  "hide_password": "Ukryj hasło",
  "last": "Ostatnie",
  "like": "Takie jak",
  "loading": "Trwa ładowanie...",
  "month": "Miesiąc",
  "more_options": "Więcej opcji",
  "navigation": "Nawigacja",
  "next": "Dalej",
  "next_month": "Następny miesiąc",
  "not_started": "Nie rozpoczęto",
  "off": "Wyłączone",
  "on": "Włączone",
  "open_in_new_window": "Otwórz w nowym oknie",
  "options": "Opcje",
  "pagination": "Inne strony",
  "plus_x_more": "+{0} więcej",
  "previous": "Wstecz",
  "previous_month": "Poprzedni miesiąc",
  "progress": "W toku",
  "quick_start": "Szybki start",
  "range_error_message": "Wybrana data nie mieści się w zakresie dat",
  "required_error_message": "To pole jest wymagane",
  "results_found": "Znalezione wyniki: {0}",
  "search": "Wyszukaj",
  "select_all": "Wybierz wszystkie",
  "select_row": "Wybierz wiersz",
  "show_calendar": "Pokaż kalendarz",
  "show_password": "Pokaż hasło",
  "step_x_of_y": "Krok {0} z {1}",
  "today": "Dzisiaj",
  "unavailable_date_error_message": "Wybrana data jest niedostępna",
  "warning": "Ostrzeżenie",
  "year": "Rok"
}