import { property, queryAssignedElements, queryAssignedNodes, state } from 'lit/decorators.js';
import { html } from 'lit/static-html.js';
import { when } from 'lit/directives/when.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { getScope } from '../../utilities/scope';
import { isIconOnly } from '../../utilities/slot';
import { watch } from '../../internal/watch';
import button from '../button/button';
import icon from '../icon/icon';
import HarmonyElement from '../';
import { HasSlotController } from '../../internal/slot';
import styles from './filter-pill.styles';

/**
 * @tag he-filter-pill
 * @since 5.6.0
 * @status stable
 * @design pending
 * @figma https://www.figma.com/file/dRwBPvZFZdYgWdAOCK375K/Harmony-Toolkit?node-id=23381%3A102819
 *
 * @slot - The filter label.
 * @slot value - Filter value(s).
 * @slot more - Text content that displays if there are more values than shown in the filter pill, defaults to "+{x} more".
 * @slot start - Contents of the start slot are positioned before the button content.
 * @slot end - Contents of the end slot are positioned after the button content.
 *
 * @csspart label - Wrapper around the default slot.
 * @csspart values - Wrapper around the `value` slot.
 * @csspart more - Container of the "+{x} more" string.
 * @csspart clear-icon - The clear icon when `clear` is set.
 * @csspart caret-icon - The caret icon when `caret` is set.
 * @csspart button - The `he-button` element.
 * @csspart button__control - The button's `control` part.
 * @csspart button__content - The button's `content` part.
 * @csspart button__start - The button's `start` part.
 * @csspart button__end - The button's `end` part.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 */
export class FilterPill extends HarmonyElement {
  static styles = [styles];
  static baseName = 'filter-pill';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
  };

  private scope = getScope(this);
  private readonly hasSlotController = new HasSlotController(this, '[default]', 'start', 'end');
  @state() private valuesLength: number = 0;
  @queryAssignedNodes({ flatten: true }) private slottedNodes!: Array<Node>;

  @queryAssignedElements({ slot: 'value', selector: ':not([hidden])', flatten: true })
  private slottedValues!: Array<HTMLElement>;

  constructor() {
    super();
    this.scope.registerComponent(icon, button);
  }

  /** The button's visual treatment. */
  @property({ reflect: true }) appearance: 'accent' | 'neutral' | 'error' = 'neutral';

  /** Include a downward pointing chevron icon. */
  @property({ type: Boolean, reflect: true }) caret: boolean;

  /** Include an X icon. */
  @property({ type: Boolean, reflect: true }) clear: boolean;

  /** Delimiter between displayed values. */
  @property({ reflect: true }) delimiter: string = ', ';

  /** Number of filter values to show in button. */
  @property({ attribute: 'value-count', type: Number, reflect: true }) valueCount: number = 3;

  /** Disables the filter pill button. */
  @property({ type: Boolean, reflect: true }) disabled: boolean;

  /**
   * Indicates whether the element, or another grouping element it controls, is currently expanded or collapsed.
   *
   * See [aria-expanded](https://www.w3.org/TR/wai-aria/#aria-expanded) for more information.
   */
  @property({ attribute: 'aria-expanded', reflect: true }) ariaExpanded: 'true' | 'false' | null;

  /**
   * Indicates the availability and type of interactive popup element, such as menu or dialog, that can be triggered by
   * an element.
   *
   * See [aria-haspopup](https://www.w3.org/TR/wai-aria-1.1/#aria-haspopup) for more information.
   */
  @property({ attribute: 'aria-haspopup', reflect: true })
  ariaHaspopup: 'false' | 'true' | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog' | string | null;

  /**
   * Array of values to display in button (will only show the first `valueCount` number of values).
   * Alternatively you can use the `value` slot (use of the slot will override this).
   */
  @property({ attribute: false, type: Array }) values?: string[];

  @watch('values')
  @watch('valueCount')
  setValues() {
    this.slottedValues?.forEach((item, i) => {
      item.classList.add('he-filter-value');
      item.classList.toggle('he-filter-value--hidden', i >= this.valueCount);
      item.classList.toggle('he-filter-value--last', i === this.slottedValues!.length - 1);
    });

    this.valuesLength = this.slottedValues?.length || this.values?.length || 0;
  }

  handleSlottedValuesChange = () => {
    this.setValues();
  };

  isMore() {
    return this.valuesLength - this.valueCount > 0;
  }

  moreString(): string {
    return this.isMore() ? this.localize.term('plus_x_more', [this.valuesLength - this.valueCount]) : '';
  }

  protected render() {
    return html`
      <${this.scope.tag('button')}
        class=${classMap({
          button: true,
          'icon-only': isIconOnly(this.slottedNodes),
        })}
        style="--value-delimiter:'${this.delimiter}'"
        part="button"
        exportparts="
          button:button,
          control:button__control,
          content:button__content,
          start:button__start,
          end:button__end,
          caret-icon:caret-icon
        "
        ?caret=${this.caret}
        ?disabled="${this.disabled}"
        aria-expanded="${ifDefined(this.ariaExpanded)}"
        aria-haspopup="${ifDefined(this.ariaHaspopup)}"
      >
        <slot name="start" slot=${ifDefined(this.hasSlotController.test('start') ? 'start' : undefined)}></slot>
        <span class="label" part="label"><slot></slot></span>
        <span class="values" part="values">
          <slot name="value" @slotchange=${this.handleSlottedValuesChange}>
            ${when(
              Array.isArray(this.values),
              () => html`
                ${this.values?.slice(0, this.valueCount).join(this.delimiter)}${this.isMore() ? this.delimiter : null}
              `
            )}
          </slot>
        </span>
        <span class="more" part="more" ?hidden=${!this.isMore()}>
          <slot name="more">${this.moreString()}</slot>
        </span>
        ${when(
          this.clear,
          () => html`
            <${this.scope.tag('icon')} name="cancel" class="clear" part="clear-icon"></${this.scope.tag('icon')}>
          `
        )}
        <slot name="end" slot=${ifDefined(this.hasSlotController.test('end') ? 'end' : undefined)}></slot>
      </${this.scope.tag('button')}>
    `;
  }
}

export default FilterPill;
