export default {
  "back": "Takaisin",
  "breadcrumb": "Navigointipolku",
  "clear": "Tyhjennä",
  "clear_entry": "Tyhjennä merkintä",
  "close": "Sulje",
  "collapse_task_menu": "Tiivistä tehtävävalikko",
  "collapse": "Tiivistä",
  "commands": "Komennot",
  "complete": "Valmis",
  "day": "Päivä",
  "dislike": "Älä tykkää",
  "error": "Virhe",
  "expand_task_menu": "Laajenna tehtävävalikko",
  "expand": "Laajenna",
  "filter": "Suodata",
  "finish": "Viimeistele",
  "first": "Etunimi",
  "go_to_page": "Siirry sivulle {0}",
  "hide_password": "Piilota salasana",
  "last": "Last",
  "like": "Tykkää",
  "loading": "Ladataan...",
  "month": "Kuukausi",
  "more_options": "Enemmän vaihtoehtoja",
  "navigation": "Siirtyminen",
  "next": "Seuraava",
  "next_month": "Seuraava kuukausi",
  "not_started": "Ei aloitettu",
  "off": "Ei käytössä",
  "on": "Käytössä",
  "open_in_new_window": "Avaa uudessa ikkunassa",
  "options": "Asetukset",
  "pagination": "Sivutus",
  "plus_x_more": "+{0} muuta",
  "previous": "Edellinen",
  "previous_month": "Edellinen kuukausi",
  "progress": "Kesken",
  "quick_start": "Pika-aloitus",
  "range_error_message": "Valitsemasi päivämäärä ei ole päivämääräalueella",
  "required_error_message": "Tämä kenttä on pakollinen",
  "results_found": "{0} tulosta löytyi",
  "search": "Hae",
  "select_all": "Valitse kaikki",
  "select_row": "Valitse rivi",
  "show_calendar": "Näytä kalenteri",
  "show_password": "Näytä salasana",
  "step_x_of_y": "Vaihe {0}/{1}",
  "today": "Tänään",
  "unavailable_date_error_message": "Valitsemasi päivämäärä ei ole käytettävissä",
  "warning": "Varoitus",
  "year": "Vuosi"
}