export default {
  "back": "Vorige",
  "breadcrumb": "Navigatiepad",
  "clear": "Wissen",
  "clear_entry": "Invoer wissen",
  "close": "Sluiten",
  "collapse_task_menu": "Taakmenu samenvouwen",
  "collapse": "Samenvouwen",
  "commands": "Opdrachten",
  "complete": "Voltooien",
  "day": "Dag",
  "dislike": "Vind ik niet leuk",
  "error": "Fout",
  "expand_task_menu": "Taakmenu uitvouwen",
  "expand": "Uitvouwen",
  "filter": "Filteren",
  "finish": "Voltooien",
  "first": "Eerste",
  "go_to_page": "Ga naar pagina {0}",
  "hide_password": "Wachtwoord verbergen",
  "last": "Laatste",
  "like": "Zoals",
  "loading": "Laden...",
  "month": "Maand",
  "more_options": "Meer opties",
  "navigation": "Navigatie",
  "next": "Volgende",
  "next_month": "Volgende maand",
  "not_started": "Niet gestart",
  "off": "Uit",
  "on": "Aan",
  "open_in_new_window": "In nieuw venster openen",
  "options": "Opties",
  "pagination": "Paginering",
  "plus_x_more": "+{0} meer",
  "previous": "Vorige",
  "previous_month": "Vorige maand",
  "progress": "Wordt uitgevoerd",
  "quick_start": "Snel starten",
  "range_error_message": "De datum die u hebt geselecteerd, valt niet binnen het datumbereik",
  "required_error_message": "Dit veld is vereist",
  "results_found": "Er zijn {0} resultaten gevonden",
  "search": "Zoeken",
  "select_all": "Alles selecteren",
  "select_row": "Rij selecteren",
  "show_calendar": "Kalender weergeven",
  "show_password": "Wachtwoord weergeven",
  "step_x_of_y": "Stap {0} van {1}",
  "today": "Vandaag",
  "unavailable_date_error_message": "De datum die u hebt geselecteerd, is niet beschikbaar",
  "warning": "Waarschuwing",
  "year": "Jaar"
}