export default {
  "back": "वापस जाएँ",
  "breadcrumb": "ब्रेडक्रम्ब",
  "clear": "साफ़ करें",
  "clear_entry": "प्रविष्टि साफ़ करें",
  "close": "बंद करें",
  "collapse_task_menu": "कार्य मेनू संक्षिप्त करें",
  "collapse": "संक्षिप्त करें",
  "commands": "आदेश",
  "complete": "पूरा करें",
  "day": "दिन",
  "dislike": "नापसंद",
  "error": "त्रुटि",
  "expand_task_menu": "कार्य मेनू विस्तृत करें",
  "expand": "विस्तृत करें",
  "filter": "फ़िल्टर करें",
  "finish": "समाप्त",
  "first": "प्रथम",
  "go_to_page": "पेज {0}पर जाएँ",
  "hide_password": "पासवर्ड छुपाएँ",
  "last": "अंतिम",
  "like": "पसंद करें",
  "loading": "लोड किया जा रहा है...",
  "month": "महीना",
  "more_options": "अधिक विकल्प",
  "navigation": "नेविगेशन",
  "next": "अगला",
  "next_month": "अगला महीना",
  "not_started": "शुरू नहीं हुआ है",
  "off": "बंद",
  "on": "चालू",
  "open_in_new_window": "नई विंडो में खोलें",
  "options": "विकल्प",
  "pagination": "पेज पर नंबर डालना",
  "plus_x_more": "+ {0} और",
  "previous": "पिछला",
  "previous_month": "पिछला महीना",
  "progress": "प्रगति में है",
  "quick_start": "तुरंत शुरू करें",
  "range_error_message": "आपके द्वारा चुना गया दिनांक, दिनांक सीमा में नहीं है",
  "required_error_message": "यह फ़ील्ड आवश्यक है",
  "results_found": "{0} परिणाम मिले",
  "search": "खोजें",
  "select_all": "सभी को चुनें",
  "select_row": "पंक्ति चुनें",
  "show_calendar": "कैलेंडर दिखाएँ",
  "show_password": "पासवर्ड दिखाएँ",
  "step_x_of_y": "{1} में से {0} चरण",
  "today": "आज",
  "unavailable_date_error_message": "आपके द्वारा चुना गया दिनांक उपलब्ध नहीं है",
  "warning": "चेतावनी",
  "year": "वर्ष"
}