export default {
  "back": "Atzera",
  "breadcrumb": "Nabigazio-haria",
  "clear": "Garbitu",
  "clear_entry": "Garbitu sarrera",
  "close": "Itxi",
  "collapse_task_menu": "Tolestu atazen menua",
  "collapse": "Tolestu",
  "commands": "Komandoak",
  "complete": "Osatuta",
  "day": "Eguna",
  "dislike": "Utzi atsegiteari",
  "error": "Errorea",
  "expand_task_menu": "Zabaldu atazen menua",
  "expand": "Zabaldu",
  "filter": "Iragazi",
  "finish": "Amaitu",
  "first": "Lehena",
  "go_to_page": "Joan {0} orrira",
  "hide_password": "Ezkutatu pasahitza",
  "last": "Azkena",
  "like": "Atsegin dut",
  "loading": "Kargatzen...",
  "month": "Hilabetea",
  "more_options": "Aukera gehiago",
  "navigation": "Nabigazioa",
  "next": "Hurrengoa",
  "next_month": "Hurrengo hilabetea",
  "not_started": "Ez da hasi",
  "off": "Desaktibatuta",
  "on": "Aktibatuta",
  "open_in_new_window": "Ireki beste leiho batean",
  "options": "Aukerak",
  "pagination": "Orrialdekatzea",
  "plus_x_more": "{0} gehiago",
  "previous": "Aurrekoa",
  "previous_month": "Aurreko hilabetea",
  "progress": "Abian",
  "quick_start": "Hasiera bizkorra",
  "range_error_message": "Hautatu duzun data ez dago data-tartearen barruan",
  "required_error_message": "Eremu hau beharrezkoa da",
  "results_found": "{0} emaitza aurkitu dira",
  "search": "Bilatu",
  "select_all": "Hautatu guztiak",
  "select_row": "Hautatu errenkada",
  "show_calendar": "Erakutsi egutegia",
  "show_password": "Erakutsi pasahitza",
  "step_x_of_y": "{0}/{1} urratsa",
  "today": "Gaur",
  "unavailable_date_error_message": "Hautatu duzun data ez dago erabilgarri",
  "warning": "Abisua",
  "year": "Urtea"
}