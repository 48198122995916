import { CustomEvents } from '../helpers/constant';
import LibraryUsageContext from './context/library-usage-context';
import { HttpFunctions, httpRequestRetry } from './http-service';
import { trackEvent } from './telemetry-service/library-telemetry-service';
import { AvailableConnectorPromise, ConnectorPromiseStore, WindowRef } from './types/window-connectors';

let libraryUsageContext: LibraryUsageContext | undefined;

/**
 * This is used to store promises that can be utilized by other instance of the library. It will be helpful in integrating
 * different versions of library where a connector has same data contract.
 *
 * @param connectorPromiseStoreConfig Provides the config information to prevent mismatch.
 * @uses httpRequestRetry
 */
export const httpRequestRetryStore = async <T>(
  connectorPromiseStoreConfig: ConnectorPromiseStore | undefined,
  requestUrl: string,
  httpFunction: HttpFunctions,
  headers?: HeadersInit | null,
  body?: BodyInit | null,
  credentials?: RequestCredentials | null,
  forceNew: boolean = false,
  retriesLeft: number = 3
) => {
  let httpPromise: Promise<T | null | undefined> | undefined;

  let availablePromiseGroup: AvailableConnectorPromise | undefined;

  // We first check if there is an existing promise that can be reused, unless forceNew is true.
  if (connectorPromiseStoreConfig && !forceNew) {
    const portalConnectors = (window as WindowRef).portalConnectors;
    if (!portalConnectors) {
      (window as WindowRef).portalConnectors = {};
    }

    availablePromiseGroup = (window as WindowRef).portalConnectors[connectorPromiseStoreConfig.connectorType];

    if (!availablePromiseGroup) {
      (window as WindowRef).portalConnectors[connectorPromiseStoreConfig.connectorType] = {};
    }

    availablePromiseGroup = (window as WindowRef).portalConnectors[connectorPromiseStoreConfig.connectorType];
    const availableResponsePromise = availablePromiseGroup && availablePromiseGroup[connectorPromiseStoreConfig.uniqueId];
    if (availableResponsePromise) {
      const eventLog = {
        method: httpFunction,
        url: requestUrl,
        headers: headers,
        usageVersion: availableResponsePromise.sourceVersion,
      };

      trackEvent(CustomEvents.PromiseStoreUsed, eventLog);

      httpPromise = availableResponsePromise.connectorPromise;
    }
  }

  if (!httpPromise) {
    httpPromise = httpRequestRetry(requestUrl, httpFunction, headers, body, credentials, retriesLeft);
    if (availablePromiseGroup && connectorPromiseStoreConfig) {
      if (!libraryUsageContext) {
        libraryUsageContext = LibraryUsageContext.getInstance();
      }
      availablePromiseGroup[connectorPromiseStoreConfig.uniqueId] = {
        sourceVersion: libraryUsageContext.Version,
        connectorPromise: httpPromise,
      };
    }
  }

  return httpPromise;
};
