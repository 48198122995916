import { html } from 'lit/static-html.js';
import { property, query, queryAssignedElements, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import Modal from '../../internal/modal';
import { animateTo, stopAnimations } from '../../internal/animations';
import { waitForEvent } from '../../utilities/wait-for-event';
import { getScope } from '../../utilities/scope';
import button from '../button/button';
import icon from '../icon/icon';
import { PanelSize } from '../../types';
import HarmonyElement from '../';
import { HasSlotController } from '../../internal/slot';
import { lockBodyScrolling, unlockBodyScrolling } from '../../utilities/scroll';
import { watch } from '../../internal/watch';
import visuallyHidden from '../visually-hidden/visually-hidden';
import styles from './fly-in-panel.styles';

export type FlyInPanelCloseSource = 'close-button' | 'keyboard' | 'overlay';

const KEY_FRAMES = {
  right: {
    open: [
      { opacity: 0, transform: 'translateX(100%)' },
      { opacity: 1, transform: 'translateX(0)' },
    ],
    close: [
      { opacity: 1, transform: 'translateX(0)' },
      { opacity: 0, transform: 'translateX(100%)' },
    ],
  },
  left: {
    open: [
      { opacity: 0, transform: 'translateX(-100%)' },
      { opacity: 1, transform: 'translateX(0)' },
    ],
    close: [
      { opacity: 1, transform: 'translateX(0)' },
      { opacity: 0, transform: 'translateX(-100%)' },
    ],
  },
  deny: [{ transform: 'scale(1)' }, { transform: 'scale(1.01)' }, { transform: 'scale(1)' }],
};

/**
 * @tag he-fly-in-panel
 * @since 1.5
 * @status stable
 * @figma https://www.figma.com/file/dRwBPvZFZdYgWdAOCK375K/PC-Toolkit?node-id=5%3A915
 *
 * @dependency he-button
 * @dependency he-icon
 *
 * @slot - The fly-in-panel's content.
 * @slot heading - The fly-in-panel's header label. Alternatively, you can use the heading prop.
 * @slot actions - The fly-in-panel's header actions, usually a back button.
 * @slot footer - The fly-in-panel's footer, usually one or more buttons representing various options.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-show - Emitted when the fly-in-panel opens.
 * @event he-after-show - Emitted after the fly-in-panel opens and all transitions are complete.
 * @event he-hide - Emitted when the fly-in-panel closes.
 * @event he-after-hide - Emitted after the fly-in-panel closes and all transitions are complete.
 * @event he-initial-focus - Emitted when the fly-in-panel opens and the panel gains focus. Calling
 *   `event.preventDefault()` will prevent focus and allow you to set it on a different element in the fly-in-panel,
 *   such as an input or button. You can also put `autofocus` attribute on the element instead to set initial focus.
 * @event {{ source: 'close-button' | 'keyboard' | 'overlay' }} he-request-close - Emitted when the user attempts to
 *   close the fly-in panel by clicking the close button, clicking the overlay, or pressing the escape key. Calling
 *   `event.preventDefault()` will prevent the panel from closing. You can check `event.detail.source` to determine how
 *   the request was initiated. Avoid using this unless closing the fly-in panel will result in destructive behavior
 *   such as data loss.
 *
 * @csspart base - The component's base wrapper.
 * @csspart overlay - The overlay.
 * @csspart panel - The fly-in-panel panel (where the fly-in-panel and its content is rendered).
 * @csspart header - The fly-in-panel header.
 * @csspart title - The fly-in-panel title.
 * @csspart close-button - The close button.
 * @csspart body - The fly-in-panel body.
 * @csspart footer - The fly-in-panel footer.
 *
 * @cssproperty --size - The preferred size of the fly-in-panel. This will be applied to the fly-in-panel's width or height.
 *   depending on its `placement`. Note that the fly-in-panel will shrink to accommodate smaller screens.
 * @cssproperty --header-padding-x - The amount of horizontal padding to use for the header.
 * @cssproperty --header-padding-y - The amount of vertical padding to use for the header.
 * @cssproperty --body-padding-x - The amount of horizontal padding to use for the body.
 * @cssproperty --body-padding-y - The amount of vertical padding to use for the body.
 * @cssproperty --footer-padding-x - The amount of horizontal padding to use for the footer.
 * @cssproperty --footer-padding-y - The amount of vertical padding to use for the footer.
 * @cssproperty [--he-elevation=64] - Elevation of the card.
 */
export class FlyInPanel extends HarmonyElement {
  static styles = styles;
  static baseName = 'fly-in-panel';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeShow: new CustomEvent('he-show'),
    onHeAfterShow: new CustomEvent('he-after-show'),
    onHeHide: new CustomEvent('he-hide'),
    onHeAfterHide: new CustomEvent('he-after-hide'),
    onHeInitialFocus: new CustomEvent('he-initial-focus'),
    onHeRequestClose: new CustomEvent<{ source: FlyInPanelCloseSource }>('he-request-close'),
  };

  scope = getScope(this);

  private hasSlotController = new HasSlotController(this, '[default]', 'heading', 'footer', 'actions');

  /** Indicates whether or not the fly-in-panel is open. You can use this in lieu of the show/hide methods. */
  @property({ type: Boolean, reflect: true }) open = false;

  /** Indicates the transition speed for animations. */
  @property({ type: Number, attribute: 'transition-speed' }) transitionSpeed = 300;

  /** @deprecated use `--size` css property instead. */
  @property() size?: PanelSize;

  /**
   * The fly-in-panel's label as displayed in the header. You should always include a relevant label even when using
   * `no-header`, as it is required for proper accessibility.
   */
  @property() heading = '';

  /**
   * The direction the fly-in-panel will open from.
   *
   * Note: "right" and "left" will be deprecated in a future version. Please use the logical properties "end" or
   * "start", which are based on language direction.
   */
  @property() placement: 'end' | 'start' | 'right' | 'left' = 'end';

  // placement converted to right or left based on direction
  @state()
  private get _placement() {
    switch (this.placement) {
      case 'start':
        return this.dir === 'rtl' ? 'right' : 'left';
      case 'end':
        return this.dir === 'rtl' ? 'left' : 'right';
      default:
        return this.placement;
    }
  }

  /**
   * By default, the fly-in-panel slides out of its containing block (usually the viewport). To make the fly-in-panel slide out of
   * its parent element, set this prop and add `position: relative` to the parent.
   */
  @property({ type: Boolean, reflect: true }) contained = false;

  /**
   * Removes the header. This will also remove the default close button. If using prevent default on he-request-close please provide a way for the user to close the fly-in-panel.
   */
  @property({ attribute: 'no-header', type: Boolean, reflect: true }) noHeader = false;

  @query('.fly-in__panel') panel: HTMLElement;
  @query('.fly-in__overlay') overlay: HTMLElement;
  @query('.fly-in') wrapper: HTMLElement;
  @query('.fly-in__close') closeButton: HTMLElement;
  @queryAssignedElements({ slot: 'actions', selector: '.he-button' }) actionButtons: HTMLElement[];

  private modal: Modal;
  private originalTrigger: HTMLElement | null;
  private hasPreventScroll: boolean = false;

  @state()
  get keyFrames() {
    switch (this._placement) {
      case 'left':
        return KEY_FRAMES.left;
      default:
        return KEY_FRAMES.right;
    }
  }

  constructor() {
    super();
    this.scope.registerComponent(button, icon, visuallyHidden);
    this.handleDocumentKeyDown = this.handleDocumentKeyDown.bind(this);

    document.createElement('div').focus({
      get preventScroll() {
        this.hasPreventScroll = true;
        return false;
      },
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.modal = new Modal(this);
  }

  firstUpdated() {
    this.wrapper.hidden = !this.open;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.modal.deactivate();
    unlockBodyScrolling(this);
    document.removeEventListener('keydown', this.handleDocumentKeyDown);
  }

  @watch('open')
  async openChange() {
    if (this.open) {
      // Show
      this.emit('he-show');
      this.originalTrigger = this.findRootNode(this)?.activeElement as HTMLElement | null;
      this.modal.activate();

      if (!this.contained) {
        lockBodyScrolling(this);
      }

      document.addEventListener('keydown', this.handleDocumentKeyDown);

      await this.updateComplete;
      await stopAnimations(this.wrapper);
      this.wrapper.hidden = false;

      // Browsers that support el.focus({ preventScroll }) can set initial focus immediately
      if (this.hasPreventScroll) {
        const heInitialFocus = this.emit('he-initial-focus', { cancelable: true });
        if (!heInitialFocus.defaultPrevented) {
          this.getAutofocus()?.focus({ preventScroll: true });
        }
      }

      await animateTo(this.panel, this.keyFrames.open, { duration: this.transitionSpeed, easing: 'ease' });
      if (!this.hasPreventScroll) {
        const heInitialFocus = this.emit('he-initial-focus', { cancelable: true });
        if (!heInitialFocus.defaultPrevented) {
          this.getAutofocus()?.focus({ preventScroll: true });
        }
      }

      this.emit('he-after-show');
    } else {
      if (!this.hasUpdated) return;

      // Hide
      this.emit('he-hide');
      this.modal.deactivate();

      await animateTo(this.panel, this.keyFrames.close, { duration: this.transitionSpeed, easing: 'ease' });
      this.wrapper.hidden = true;

      unlockBodyScrolling(this);
      document.removeEventListener('keydown', this.handleDocumentKeyDown);

      // Restore focus to the original trigger
      const trigger = this.originalTrigger;
      if (trigger && typeof trigger.focus === 'function') {
        setTimeout(() => trigger.focus());
      }

      this.emit('he-after-hide');
    }
  }

  /** Shows the fly-in-panel. */
  async show() {
    if (this.open) {
      return;
    }
    this.open = true;
    return waitForEvent(this, 'he-after-show');
  }

  /** Hides the fly-in-panel. */
  async hide() {
    if (!this.open) {
      return;
    }

    this.open = false;
    return waitForEvent(this, 'he-after-hide');
  }

  async requestClose(source: 'close-button' | 'keyboard' | 'overlay') {
    const heRequestClose = this.emit('he-request-close', { cancelable: true, detail: { source } });
    if (heRequestClose.defaultPrevented) {
      // add deny close animation style
      await animateTo(this.panel, KEY_FRAMES.deny, { duration: this.transitionSpeed, easing: 'ease' });
      return;
    }

    this.hide();
  }

  private handleDocumentKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      event.stopPropagation();
      this.requestClose('keyboard');
    }
    return true;
  }

  /**
   * Returns the first element with 'autofocus' attribute inside fly-in panel, if there is none it but there are buttons
   * in the actions slot it returns the first one, or if none, it returns the close button.
   */
  private getAutofocus(): HTMLElement {
    const autofocus: HTMLElement | null = this.querySelector('[autofocus]');
    return autofocus || this.actionButtons[0] || this.closeButton;
  }

  render() {
    return html`
      <div
        part="base"
        class=${classMap({
          'fly-in': true,
          'fly-in--open': this.open,
          [`fly-in--${this._placement}`]: true,
          'fly-in--contained': this.contained,
          'fly-in--fixed': !this.contained,
          'fly-in--has-footer': this.hasSlotController.test('footer'),
          'fly-in--has-actions': this.hasSlotController.test('actions'),
        })}
      >
        <div part="overlay" class="fly-in__overlay" @click=${() => this.requestClose('overlay')} tabindex="-1"></div>
        <div
          part="panel"
          class="fly-in__panel"
          role="dialog"
          aria-modal="true"
          aria-hidden=${this.open ? 'false' : 'true'}
          aria-label=${this.noHeader ? this.heading : ''}
          aria-labelledby=${!this.noHeader ? `${this.id}-title` : ''}
        >
          ${!this.noHeader
            ? html`
                  <header part="header" class="fly-in__header">
                    <span class="fly-in__header__slots">
                      <span class="fly-in__actions">
                        <slot name="actions"></slot>
                      </span>
                      <h2 part="title" class="fly-in__title" id=${`${this.id}-title`}>
                        <slot name="heading"> ${this.heading || String.fromCharCode(65279)} </slot>
                      </h2>
                    </span>
                    <${this.scope.tag('button')} class="fly-in__close" appearance="stealth" @click=${() =>
                this.requestClose('close-button')}>
                      <${this.scope.tag('icon')} name="cancel" label="${this.localize.term(
                'close'
              )}"></${this.scope.tag('icon')}>
                    </${this.scope.tag('button')}>
                  </header>
                `
            : html`<${this.scope.tag('visually-hidden')} class="fly-in__hidden-close">
              <${this.scope.tag('button')} class="fly-in__close" appearance="secondary" @click=${() =>
                this.requestClose('close-button')}>
                <${this.scope.tag('icon')} name="cancel" label="${this.localize.term('close')}"></${this.scope.tag(
                'icon'
              )}>
              </${this.scope.tag('button')}>
            </${this.scope.tag('visually-hidden')}>`}

          <div part="body" class="fly-in__body">
            <slot></slot>
          </div>
          <footer part="footer" class="fly-in__footer">
            <slot name="footer"></slot>
          </footer>
        </div>
      </div>
    `;
  }
}

export default FlyInPanel;
