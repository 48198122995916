import { LibraryUsageContext } from '../../services/context';
import { PortalEnvironment } from '../../services/context/environment-helper';

let libraryUsageContext: LibraryUsageContext | undefined;

/**
 * @description It is the environment connector which will provide the portal level environment. We need to ensure
 * that
 * @since 0.0
 * @status experimental
 */
export const environmentConnector = (): PortalEnvironment => {
  if (!libraryUsageContext) {
    libraryUsageContext = LibraryUsageContext.getInstance();
  }
  return libraryUsageContext.Environment;
};
