import { windowHandler } from '../helpers/window-helper';
import VersionPickerResponse, { emptyVersionPickerResponse } from './version-picker-response';
/**
 * @since 3.0.0
 * @status experimental
 * @part - This is version picker script referenced by shell library consumers on their pages and sets to the window.
 *
 */

const globalVersionPicker = getVersions();

export type ShellLibraryKeys = {
  [key: `shellLibraryConnectors-${string}`]: string;
};

export class VersionPicker {
  static instance: VersionPicker;
  private windowHandler: windowHandler = windowHandler.getInstance();
  private nameof = <T>(name: keyof T) => name;

  public static getInstance() {
    if (!VersionPicker.instance) {
      VersionPicker.instance = new VersionPicker();
    }

    return VersionPicker.instance;
  }

  public async loadShellLibrary(): Promise<any> {
    let version: string | null = null;
    const defaultVersion = '3.0.0';
    const splitVersion = defaultVersion.split('.');

    if (splitVersion?.length > 0 && splitVersion[0]) {
      const majorVersion = splitVersion[0];
      try {
        const versionPicker = await globalVersionPicker;
        if (versionPicker.versions) {
          const latestVersion = versionPicker.versions[majorVersion];
          if (latestVersion) {
            version = latestVersion;
          }
        }
      } catch (err) {
        // [TODO]: log error without introducing telemetry service twice and duplicating logs
      }
    }

    if (!version) {
      version = defaultVersion;
    }
    const key = this.getKey(version);
    let shellLibraryInstance = this.windowHandler.tryGetFromWindow(key);
    if (!shellLibraryInstance) {
      let shellLibraryPath = 'https://harmony-portalplatform.azureedge.net/he-shell-library/{version}/internal.js';
      shellLibraryPath = shellLibraryPath.replace('{version}', version);
      shellLibraryInstance = await import(shellLibraryPath);
      this.windowHandler.addToWindowObject(key, shellLibraryInstance);
    }

    return shellLibraryInstance;
  }

  public getKey(version: string): keyof ShellLibraryKeys {
    return this.nameof<ShellLibraryKeys>(`shellLibraryConnectors-${version}`);
  }
}

async function getVersions(): Promise<VersionPickerResponse> {
  const versionPickerResponse = emptyVersionPickerResponse;
  const versionPickerPath = 'https://harmony-portalplatform.azureedge.net/he-shell-library/he-shell-library.json';

  try {
    const response = await fetch(`${versionPickerPath}`, {
      method: 'GET',
    });

    if (response.ok) {
      const versions = (await response.json()) as { [key: string]: string };
      if (versions) {
        versionPickerResponse.versions = versions;
      }
    }
  } catch (err) {
    versionPickerResponse.errorMessage = JSON.stringify(err);
  }

  return versionPickerResponse;
}
