import { LibraryUsageContext } from '../../services/context';
import { SupportedLocaleEnums } from '../../utilities/supported-locale-enums';

let libraryUsageContext: LibraryUsageContext | undefined;

/**
 * @description It is the locale connector which provides the locale for the current session.
 * Used to identify the Locale in Partner Portal.
 * Considering both V1 and VNext scenarios.
 * Order of precedence:
 *  1. Locale specified in the MarketplaceSelectedLocale cookie
 *  2. Locale Specified in the URL.
 *  3. Default to en-us
 * @since 0.0
 * @status experimental
 */
export const localeConnector = (): SupportedLocaleEnums => {
  if (!libraryUsageContext) {
    libraryUsageContext = LibraryUsageContext.getInstance();
  }
  return libraryUsageContext.Locale;
};
