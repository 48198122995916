export default {
  "back": "Zpět",
  "breadcrumb": "Popis cesty",
  "clear": "Vymazat",
  "clear_entry": "Vymazat položku",
  "close": "Zavřít",
  "collapse_task_menu": "Sbalit nabídku úkolů",
  "collapse": "Sbalit",
  "commands": "Příkazy",
  "complete": "Hotovo",
  "day": "Den",
  "dislike": "Nelíbí se mi",
  "error": "Chyba",
  "expand_task_menu": "Rozbalit nabídku úkolu",
  "expand": "Rozbalit",
  "filter": "Filtr",
  "finish": "Dokončit",
  "first": "První",
  "go_to_page": "Přejít na stránku {0}",
  "hide_password": "Skrýt heslo",
  "last": "Poslední",
  "like": "Je podobné",
  "loading": "Načítání...",
  "month": "Měsíc",
  "more_options": "Další možnosti",
  "navigation": "Navigace",
  "next": "Další",
  "next_month": "Následující měsíc",
  "not_started": "Nezahájeno",
  "off": "Vypnuto",
  "on": "Zapnuto",
  "open_in_new_window": "Otevřít v novém okně",
  "options": "Možnosti",
  "pagination": "Stránkování",
  "plus_x_more": "+ ještě {0}",
  "previous": "Předchozí",
  "previous_month": "Předchozí měsíc",
  "progress": "Probíhá",
  "quick_start": "Rychlý start",
  "range_error_message": "Datum, které jste vybrali, nesplňuje rozsah kalendářních dat.",
  "required_error_message": "Toto pole je povinné.",
  "results_found": "Nalezené výsledky: {0}",
  "search": "Hledat",
  "select_all": "Vybrat vše",
  "select_row": "Vybrat řádek",
  "show_calendar": "Zobrazit kalendář",
  "show_password": "Zobrazit heslo",
  "step_x_of_y": "Krok {0} z {1}",
  "today": "Dnes",
  "unavailable_date_error_message": "Datum, které jste vybrali, není k dispozici.",
  "warning": "Upozornění",
  "year": "Rok"
}