export default {
  "back": "Atrás",
  "breadcrumb": "Ruta de navegación",
  "clear": "Borrar",
  "clear_entry": "Borrar entrada",
  "close": "Cerrar",
  "collapse_task_menu": "Contraer menú de tareas",
  "collapse": "Contraer",
  "commands": "Comandos",
  "complete": "Completar",
  "day": "Día",
  "dislike": "No me gusta",
  "error": "Error",
  "expand_task_menu": "Expandir menú de tareas",
  "expand": "Expandir",
  "filter": "Filtrar",
  "finish": "Finalizar",
  "first": "Primero",
  "go_to_page": "Ir a la página {0}",
  "hide_password": "Ocultar contraseña",
  "last": "Último",
  "like": "Es como",
  "loading": "Cargando...",
  "month": "Mes",
  "more_options": "Más opciones",
  "navigation": "Navegación",
  "next": "Siguiente",
  "next_month": "Próximo mes",
  "not_started": "Sin iniciar",
  "off": "Desactivado",
  "on": "Activado",
  "open_in_new_window": "Abrir en una nueva ventana",
  "options": "Opciones",
  "pagination": "Paginación",
  "plus_x_more": "+{0} más",
  "previous": "Anterior",
  "previous_month": "Mes anterior",
  "progress": "En curso",
  "quick_start": "Inicio rápido",
  "range_error_message": "La fecha que ha seleccionado no está dentro del intervalo de fechas",
  "required_error_message": "Este campo es obligatorio.",
  "results_found": "{0} resultados encontrados",
  "search": "Buscar",
  "select_all": "Seleccionar todo",
  "select_row": "Seleccionar fila",
  "show_calendar": "Mostrar calendario",
  "show_password": "Mostrar contraseña",
  "step_x_of_y": "Paso {0} de {1}",
  "today": "Hoy",
  "unavailable_date_error_message": "La fecha seleccionada no está disponible",
  "warning": "Advertencia",
  "year": "Año"
}