export default {
  "back": "Vissza",
  "breadcrumb": "Navigációs sáv",
  "clear": "Törlés",
  "clear_entry": "Bejegyzés törlése",
  "close": "Bezárás",
  "collapse_task_menu": "Feladat menü összecsukása",
  "collapse": "Összecsukás",
  "commands": "Parancsok",
  "complete": "Kész",
  "day": "Nap",
  "dislike": "Nem tetszik",
  "error": "Hiba",
  "expand_task_menu": "Feladat menü kibontása",
  "expand": "Kibontás",
  "filter": "Szűrés",
  "finish": "Befejezés",
  "first": "Első",
  "go_to_page": "Ugrás a(z) {0}. oldalra",
  "hide_password": "Jelszó elrejtése",
  "last": "Utolsó",
  "like": "Tetszik",
  "loading": "Betöltés...",
  "month": "Hónap",
  "more_options": "További beállítások",
  "navigation": "Navigáció",
  "next": "Következő",
  "next_month": "Következő hónap",
  "not_started": "Nem kezdődött el",
  "off": "Kikapcsolva",
  "on": "Bekapcsolva",
  "open_in_new_window": "Megnyitás új ablakban",
  "options": "Beállítások",
  "pagination": "Tördelés",
  "plus_x_more": "+ további {0}",
  "previous": "Előző",
  "previous_month": "Előző hónap",
  "progress": "Folyamatban",
  "quick_start": "Rövid útmutató",
  "range_error_message": "A kiválasztott dátum kívül esik a dátumtartományon",
  "required_error_message": "A mező kitöltése kötelező",
  "results_found": "{0} találat",
  "search": "Keresés",
  "select_all": "Az összes kijelölése",
  "select_row": "Sor kijelölése",
  "show_calendar": "Naptár megjelenítése",
  "show_password": "Jelszó megjelenítése",
  "step_x_of_y": "{0}/{1}. lépés",
  "today": "Ma",
  "unavailable_date_error_message": "A kiválasztott dátum nem érhető el",
  "warning": "Figyelmeztetés",
  "year": "Év"
}