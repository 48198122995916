import { isEmptyOrSpaces } from './adhoc-helper';

export class windowHandler {
  private static instance: windowHandler;

  public static getInstance = () => {
    if (!windowHandler.instance) {
      windowHandler.instance = new windowHandler();
    }

    return windowHandler.instance;
  };

  /**
   * try get window object
   * @param key
   * @param prefix
   * @returns
   */
  public tryGetFromWindow = (key: string) => {
    if (isEmptyOrSpaces(key.toString())) {
      return null;
    }

    return (window as any)[key];
  };

  /**
   * declare new property on the Window Object
   * @param key
   * @param value
   * @param prefix
   * @returns
   */
  public addToWindowObject(key: string, value: any): boolean {
    if (isEmptyOrSpaces(key.toString()) || !value || (typeof value === 'string' && isEmptyOrSpaces(value?.toString()))) {
      return false;
    }

    if (!(window as any)[key]) {
      (window as any)[key] = value;
      return true;
    }

    return (window as any)[key] && (window as any)[key] == value ? true : false;
  }
}
