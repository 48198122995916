export default {
  "back": "Zurück",
  "breadcrumb": "Breadcrumb",
  "clear": "Löschen",
  "clear_entry": "Eintrag löschen",
  "close": "Schließen",
  "collapse_task_menu": "Aufgabenmenü reduzieren",
  "collapse": "Reduzieren",
  "commands": "Befehle",
  "complete": "Abgeschlossen",
  "day": "Tag",
  "dislike": "Gefällt nicht",
  "error": "Fehler",
  "expand_task_menu": "Aufgabenmenü erweitern",
  "expand": "Erweitern",
  "filter": "Filter",
  "finish": "Ende",
  "first": "Erste",
  "go_to_page": "Zu Seite {0} wechseln",
  "hide_password": "Kennwort ausblenden",
  "last": "Letzte",
  "like": "Wie",
  "loading": "Laden...",
  "month": "Monat",
  "more_options": "Weitere Optionen",
  "navigation": "Navigation",
  "next": "Weiter",
  "next_month": "Nächster Monat",
  "not_started": "Nicht gestartet",
  "off": "Aus",
  "on": "Ein",
  "open_in_new_window": "In neuem Fenster öffnen",
  "options": "Optionen",
  "pagination": "Paginierung",
  "plus_x_more": "+{0} weitere",
  "previous": "Zurück",
  "previous_month": "Vorheriger Monat",
  "progress": "In Bearbeitung",
  "quick_start": "Schnellstart",
  "range_error_message": "Das von Ihnen ausgewählte Datum liegt nicht innerhalb des Datumsbereichs.",
  "required_error_message": "Dieses Feld ist erforderlich.",
  "results_found": "{0} Ergebnisse gefunden",
  "search": "Suchen",
  "select_all": "Alles auswählen",
  "select_row": "Zeile auswählen",
  "show_calendar": "Kalender anzeigen",
  "show_password": "Kennwort anzeigen",
  "step_x_of_y": "Schritt {0} von {1}",
  "today": "Aktuell",
  "unavailable_date_error_message": "Das von Ihnen ausgewählte Datum ist nicht verfügbar",
  "warning": "Warnung",
  "year": "Jahr"
}