export default {
  "back": "Tilbage",
  "breadcrumb": "Brødkrumme",
  "clear": "Ryd",
  "clear_entry": "Ryd post",
  "close": "Luk",
  "collapse_task_menu": "Skjul opgavemenuen",
  "collapse": "Skjul",
  "commands": "Kommandoer",
  "complete": "Fuldfør",
  "day": "Dag",
  "dislike": "Synes ikke godt om",
  "error": "Fejl",
  "expand_task_menu": "Udvid opgavemenuen",
  "expand": "Udvid",
  "filter": "Filter",
  "finish": "Udfør",
  "first": "Første",
  "go_to_page": "Gå til side {0}",
  "hide_password": "Skjul adgangskode",
  "last": "Sidste",
  "like": "Synes godt om",
  "loading": "Indlæser...",
  "month": "Måned",
  "more_options": "Flere indstillinger",
  "navigation": "Navigation",
  "next": "Næste",
  "next_month": "Næste måned",
  "not_started": "Ikke startet",
  "off": "Fra",
  "on": "Til",
  "open_in_new_window": "Åbn i nyt vindue",
  "options": "Indstillinger",
  "pagination": "Sidenummerering",
  "plus_x_more": "+{0} mere",
  "previous": "Forrige",
  "previous_month": "Forrige måned",
  "progress": "Igangværende",
  "quick_start": "Hurtig start",
  "range_error_message": "Den dato, du har valgt, er ikke inden for datointervallet",
  "required_error_message": "Dette felt skal udfyldes",
  "results_found": "Der blev fundet {0} resultater",
  "search": "Søg",
  "select_all": "Markér alt",
  "select_row": "Markér række",
  "show_calendar": "Vis kalender",
  "show_password": "Vis adgangskode",
  "step_x_of_y": "Trin {0} af {1}",
  "today": "I dag",
  "unavailable_date_error_message": "Den dato, du har valgt, er ikke tilgængelig",
  "warning": "Advarsel",
  "year": "År"
}