export default {
  "back": "Atrás",
  "breadcrumb": "Secuencia de navegación",
  "clear": "Borrar",
  "clear_entry": "Borrar entrada",
  "close": "Pechar",
  "collapse_task_menu": "Contraer o menú de tarefas",
  "collapse": "Contraer",
  "commands": "Comandos",
  "complete": "Completado",
  "day": "Día",
  "dislike": "Non me gusta",
  "error": "Erro",
  "expand_task_menu": "Expande o menú de tarefas",
  "expand": "Expandir",
  "filter": "Filtrar",
  "finish": "Finalizar",
  "first": "Primeiro",
  "go_to_page": "Ir á páxina {0}",
  "hide_password": "Ocultar contrasinal",
  "last": "A última",
  "like": "Gústame",
  "loading": "Cargando...",
  "month": "Mes",
  "more_options": "Máis opcións",
  "navigation": "Navegación",
  "next": "Seguinte",
  "next_month": "Mes seguinte",
  "not_started": "Non iniciada",
  "off": "Desactivado",
  "on": "Activado",
  "open_in_new_window": "Abrir nunha ventá nova",
  "options": "Opcións",
  "pagination": "Paxinación",
  "plus_x_more": "+{0} máis",
  "previous": "Anterior",
  "previous_month": "Mes anterior",
  "progress": "En curso",
  "quick_start": "Inicio rápido",
  "range_error_message": "A data que seleccionaches non está dentro do intervalo de datas",
  "required_error_message": "Este campo é obrigatorio",
  "results_found": "Atopáronse {0} resultados",
  "search": "Buscar",
  "select_all": "Seleccionar todo",
  "select_row": "Seleccionar fila",
  "show_calendar": "Mostrar calendario",
  "show_password": "Mostrar contrasinal",
  "step_x_of_y": "Paso {0} de {1}",
  "today": "Hoxe",
  "unavailable_date_error_message": "A data que seleccionaches non está dispoñible",
  "warning": "Aviso",
  "year": "Ano"
}