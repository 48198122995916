import { css } from 'lit';
import componentStyles from '../../internal/styles/component.styles';
import { SystemColors } from '../../utilities/system-colors';

export default css`
  ${componentStyles}

  :host {
    color: var(--he-type-primary);
  }

  :host([tag='span']) {
    display: inline;
    vertical-align: middle;
  }

  :host(:not([tag='span'])) {
    display: block;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
  }

  :host(:is([appearance='heading-1'], [appearance='hero-title'])) {
    font-family: var(--he-heading-font);
    font-size: var(--he-font-size-5x-large);
    font-weight: var(--he-heading-font-weight);
    line-height: var(--he-line-height-5x-large);
  }

  :host(:is([appearance='heading-2'], [appearance='greeting-title'])) {
    font-family: var(--he-heading-font);
    font-size: var(--he-font-size-4x-large);
    font-weight: var(--he-heading-font-weight);
    line-height: var(--he-line-height-4x-large);
  }

  :host(:is([appearance='heading-3'], [appearance='email-header'])) {
    font-family: var(--he-heading-font);
    font-size: var(--he-font-size-3x-large);
    font-weight: var(--he-heading-font-weight);
    line-height: var(--he-line-height-3x-large);
  }

  :host(:is([appearance='heading-4'], [appearance='pane-header'])) {
    font-family: var(--he-heading-font);
    font-size: var(--he-font-size-2x-large);
    font-weight: var(--he-heading-font-weight);
    line-height: var(--he-line-height-2x-large);
  }

  :host(:is([appearance='heading-5'], [appearance='header'])) {
    font-family: var(--he-heading-font);
    font-size: var(--he-font-size-x-large);
    font-weight: var(--he-heading-font-weight);
    line-height: var(--he-line-height-x-large);
  }

  :host(:is([appearance='heading-6'], [appearance='subject-title'])) {
    font-family: var(--he-heading-font);
    font-size: var(--he-font-size-large);
    font-weight: var(--he-heading-font-weight);
    line-height: var(--he-line-height-large);
  }

  :host(:is([appearance='paragraph'], [appearance='body-text'])) {
    font-size: var(--he-font-size-medium);
    font-weight: var(--he-font-weight-normal);
    line-height: var(--he-line-height-medium);
  }

  :host(:is([appearance='small'], [appearance='metadata'])) {
    display: inline;
    font-size: var(--he-font-size-small);
    font-weight: var(--he-font-weight-normal);
    line-height: var(--he-line-height-small);
  }

  :host([appearance='label']) {
    display: block;
    font-size: var(--he-font-size-medium);
    line-height: var(--he-line-height-medium);
    font-weight: var(--he-font-weight-semibold);
    margin-bottom: var(--he-spacing-2x-small);
  }

  :host([appearance='menu-item-heading']) {
    color: var(--he-accent-foreground-rest);
    font-weight: var(--he-font-weight-semibold);
    border: var(--he-form-control-border-width) solid transparent;
    padding: var(--he-spacing-2x-small) var(--he-spacing-x-small);
  }

  @media screen and (forced-colors: active) {
    :host {
      color: ${SystemColors.CanvasText};
    }
  }
`;
