export default {
  "back": "הקודם",
  "breadcrumb": "שביל ניווט",
  "clear": "נקה",
  "clear_entry": "נקה ערך",
  "close": "סגור",
  "collapse_task_menu": "כיווץ תפריט משימות",
  "collapse": "כווץ",
  "commands": "פקודות",
  "complete": "הושלם",
  "day": "יום",
  "dislike": "לא אוהב",
  "error": "שגיאה",
  "expand_task_menu": "הרחבת תפריט משימות",
  "expand": "הרחב",
  "filter": "מסנן",
  "finish": "סיום",
  "first": "ראשון",
  "go_to_page": "עבור לדף {0}",
  "hide_password": "הסתר סיסמה",
  "last": "אחרון",
  "like": "אהבתי",
  "loading": "טוען...",
  "month": "חודש",
  "more_options": "אפשרויות נוספות",
  "navigation": "ניווט",
  "next": "הבא",
  "next_month": "החודש הבא",
  "not_started": "לא החל",
  "off": "מושבתת",
  "on": "פועלת",
  "open_in_new_window": "פתח בחלון חדש",
  "options": "אפשרויות",
  "pagination": "חלוקה לעמודים",
  "plus_x_more": "ועוד {0}",
  "previous": "הקודם",
  "previous_month": "החודש הקודם",
  "progress": "מתבצע",
  "quick_start": "התחלה מהירה",
  "range_error_message": "התאריך שבחרת אינו בטווח התאריכים",
  "required_error_message": "שדה זה נדרש",
  "results_found": "נמצאו {0} תוצאות",
  "search": "חיפוש",
  "select_all": "בחר הכול",
  "select_row": "בחר שורה",
  "show_calendar": "הצג לוח שנה",
  "show_password": "הצג סיסמה",
  "step_x_of_y": "שלב {0} מתוך {1}",
  "today": "היום",
  "unavailable_date_error_message": "התאריך שבחרת אינו זמין",
  "warning": "אזהרה",
  "year": "שנה"
}