import { html } from 'lit';
import HarmonyElement from '../';
import styles from './visually-hidden.styles';

/**
 * @tag he-visually-hidden
 * @since 3.0
 * @status stable
 *
 * @slot - The content you'd like to be visually hidden.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 */
export class VisuallyHidden extends HarmonyElement {
  static styles = styles;
  static baseName = 'visually-hidden';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
  };

  render() {
    return html`<slot></slot>`;
  }
}

export default VisuallyHidden;
