import { isEmptyOrSpaces } from './adhoc-helper';

/**
 * This is used to derive value from cookie on client side.
 * @param name The key for which the value needs to be derived.
 * @returns The value for specified key or null if it does not exist.
 */
export const deriveValueFromCookie = (name: string): string | undefined => {
  if (name && !isEmptyOrSpaces(name)) {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieValues = decodedCookie?.split('; ');

    if (cookieValues && cookieValues.length > 0) {
      const keyValuePair = cookieValues.find(keyValue => keyValue?.toLowerCase().startsWith(name?.toLowerCase()));
      const value = keyValuePair?.split('=')[1];

      return value;
    }
  }

  return undefined;
};
