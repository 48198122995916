export default {
  "back": "返回",
  "breadcrumb": "階層連結",
  "clear": "清除",
  "clear_entry": "清除輸入",
  "close": "關閉",
  "collapse_task_menu": "摺疊工作功能表",
  "collapse": "摺疊",
  "commands": "命令",
  "complete": "完成",
  "day": "天",
  "dislike": "不喜歡",
  "error": "錯誤",
  "expand_task_menu": "展開工作功能表",
  "expand": "展開",
  "filter": "篩選",
  "finish": "完成",
  "first": "第一個",
  "go_to_page": "前往頁面 {0}",
  "hide_password": "隱藏密碼",
  "last": "最後一個",
  "like": "相似",
  "loading": "正在載入...",
  "month": "月",
  "more_options": "其他選項",
  "navigation": "瀏覽",
  "next": "下一步",
  "next_month": "下個月",
  "not_started": "未啟動",
  "off": "關閉",
  "on": "開啟",
  "open_in_new_window": "在新視窗中開啟",
  "options": "選項",
  "pagination": "分頁",
  "plus_x_more": "{0} 個以上",
  "previous": "上一個",
  "previous_month": "上個月",
  "progress": "進行中",
  "quick_start": "快速入門",
  "range_error_message": "您選取的日期不在日期範圍內",
  "required_error_message": "此為必要欄位",
  "results_found": "找到 {0} 個結果",
  "search": "搜尋",
  "select_all": "全選",
  "select_row": "選取資料列",
  "show_calendar": "顯示行事曆",
  "show_password": "顯示密碼",
  "step_x_of_y": "步驟 {0} / {1}",
  "today": "今天",
  "unavailable_date_error_message": "您選取的日期無法使用",
  "warning": "警告",
  "year": "年"
}