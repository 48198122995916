import { html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { unsafeSVG } from 'lit/directives/unsafe-svg.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { getScope } from '../../utilities/scope';
import { watch } from '../../internal/watch';
import HarmonyElement from '../';
import { requestIcon } from './request';
import styles from './icon.styles';
import { IconNames } from './icon-names';

const parser = new DOMParser();

/**
 * @tag he-icon
 * @since 1.1
 * @status stable
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-load - Emitted when the icon has loaded.
 * @event {{ status: number }} he-error - Emitted when the icon failed to load.
 *
 * @csspart base - The base wrapper around the icon.
 */
export class Icon extends HarmonyElement {
  static styles = styles;
  static baseName = 'icon';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeLoad: new CustomEvent('he-load'),
    onHeError: new CustomEvent<{ status: number }>('he-error'),
  };

  private scope = getScope(this);

  @state() svg = '';

  /** The name of the icon to draw. */
  @property({ reflect: true }) name?: IconNames;

  /** A description to use for accessibility. If omitted, `aria-hidden="true"` will be applied instead. */
  @property() label?: string;

  /** A string that points to an SVG. This is an escape hatch for loading icons not from the basePath. */
  @property() url?: string;

  private getUrl(): string | null {
    if (this.name) {
      return this.scope.makePath(`icons/${this.name.toLowerCase()}.svg`);
    }

    if (this.url) {
      return this.url;
    }

    return null;
  }

  async setIcon() {
    const url = this.getUrl();

    if (url == null) {
      // If we can't resolve a URL and an icon was previously set, remove it
      if (this.svg) this.svg = '';
      return;
    }

    try {
      const file = await requestIcon(url)!;

      if (url !== this.getUrl()) {
        // If the url has changed while fetching the icon, ignore this request.
        return;
      } else if (file.ok) {
        const doc = parser.parseFromString(file.svg, 'text/html');
        const svgEl = doc.body.querySelector('svg');

        if (svgEl) {
          this.svg = svgEl.outerHTML;
          this.emit('he-load');
        } else {
          this.svg = '';
          this.emit('he-error', { detail: { status: file.status } });
        }
      } else {
        this.svg = '';
        this.emit('he-error', { detail: { status: file.status } });
      }
    } catch {
      this.emit('he-error', { detail: { status: -1 } });
    }
  }

  @watch('name')
  @watch('url')
  handleNameChange() {
    this.setIcon();
  }

  render() {
    return html`
      <span
        part="base"
        role=${ifDefined(this.label ? 'img' : undefined)}
        aria-label=${ifDefined(this.label || undefined)}
        aria-hidden=${this.label ? 'false' : 'true'}
      >
        ${unsafeSVG(this.svg)}
      </span>
    `;
  }
}

export default Icon;
