import { httpRequestRetryStore } from '../../services/http-request-store';
import { HttpFunctions } from '../../services/http-service';
import { trackError, trackEvent } from '../../services/telemetry-service/library-telemetry-service';
import { ErrorInformation } from '../../services/telemetry-service/types/error-information';
import { ConnectorPromiseStore } from '../../services/types/window-connectors';
import { HarmonyConnectorError } from '../connector-error';
import { AuthContext, AuthContextType, TokenMetadata, UserMetadata, WorkspaceAuthContext } from './auth-context-connector';

let authContextData: AuthContextHarmonyPortalApi | undefined;
let workspaceAuthContextData: WorkspaceAuthContextHarmonyPortalApi;

const authContextUrl = '/api/user/accesstokens';
const ErrorSource = 'AuthContextConnectorHarmonyPortal';
const FailureAuthContext = 'Failure while trying to retreive auth context';
const MsToExpiry = 300000; // 5 mins

enum SessionStorageKeys {
  AuthContextData = 'AuthContextData',
  WorkspaceAuthContextData = 'WorkspaceAuthContextData',
}

// This is the unique config Id for auth workspace connector. You need to change this if there is a change in design.
const CONFIG_ID = 'V2';

export type AuthContextHarmonyPortalApi = {
  accountsFirstPartyApp?: string;
  apiPartner?: string;
  userMetadata?: string;
  userToken?: string;
  copilotToken?: string;
  tokenMetadata?: { [key: string]: TokenMetadata };
};

export type WorkspaceAuthContextHarmonyPortalApi = {
  [key: string]: TokenMetadata | UserMetadata | undefined;
};

export type TokenTypeHarmonyPortal = 'accountsFirstPartyApp' | 'apiPartner' | 'userMetadata' | 'userToken' | 'copilotToken';

const TokenTypeConversion = new Map<AuthContextType, TokenTypeHarmonyPortal>([
  [AuthContextType.PartnerCenterApp, 'accountsFirstPartyApp'],
  [AuthContextType.PartnerApi, 'apiPartner'],
  [AuthContextType.UserMetadata, 'userMetadata'],
  [AuthContextType.UserToken, 'userToken'],
  [AuthContextType.CopilotToken, 'copilotToken'],
]);

enum AuthContextApiFetch {
  name = 'AuthContextApiFetchDuration',
  start = 'auth-context-api-fetch-start',
  end = 'auth-context-api-fetch-end',
  measure = 'auth-context-api-fetch-measure',
}

enum AuthContextWorkspaceApiFetch {
  name = 'AuthContextWorkspaceApiFetchDuration',
  start = 'auth-context-workspace-api-fetch-start',
  end = 'auth-context-workspace-api-fetch-end',
  measure = 'auth-context-workspace-api-fetch-measure',
}

/**
 * Auth context connector for the Harmony Portal. This handles the behavior for retrieving tokens in the multiportal scenario.
 * @param authContextType A list of auth context types to fetch.
 * @param forceNew Indicates whether to make a new token request to FD.
 * @returns The list of auth context tokens.
 */
export const authContextConnectorHarmonyPortal = async (authContextType: AuthContextType[], forceNew: boolean) => {
  if (!authContextData) {
    const sessionValue = sessionStorage.getItem(SessionStorageKeys.AuthContextData);
    authContextData = sessionValue ? JSON.parse(sessionValue) : undefined;
  }
  const authContext: AuthContext = {};

  // Check if the tokens exist and are valid
  const tokensToFetch: TokenTypeHarmonyPortal[] = [];
  authContextType.forEach(token => {
    const convertedTokenType = convertTokenType(token);
    if (authContextData && !forceNew) {
      if (convertedTokenType.toLowerCase() === AuthContextType.UserMetadata.toLowerCase()) {
        authContext[token] = authContextData.userMetadata;
      } else {
        const selectedToken = authContextData.tokenMetadata?.[convertedTokenType];
        if (selectedToken && isTokenValid(selectedToken)) {
          authContext[token] = selectedToken.accessToken;
        } else {
          // forceNew to ensure existing promise is not reused
          forceNew = true;
          tokensToFetch.push(convertedTokenType);
        }
      }
    } else {
      tokensToFetch.push(convertedTokenType);
    }
  });

  // Form the request for the tokens to fetch
  if (tokensToFetch.length > 0) {
    const baseUrl = `${window.location.origin}`;
    let url = `${baseUrl}${authContextUrl}`;
    const tokenResources: string[] = [];
    tokensToFetch.forEach(token => {
      if (token !== 'userMetadata') {
        tokenResources.push(token);
      }
    });
    if (tokenResources.length > 0) url += `?tokenResources=${tokenResources.join('&tokenResources=')}`;

    const connectorPromiseStoreConfig: ConnectorPromiseStore = {
      connectorType: 'authContext',
      uniqueId: url + CONFIG_ID,
    };

    try {
      performance.mark(AuthContextApiFetch.start);
      const authContextDataResponse = await httpRequestRetryStore<AuthContextHarmonyPortalApi>(connectorPromiseStoreConfig, url, HttpFunctions.GET, null, null, 'same-origin', forceNew);
      performance.mark(AuthContextApiFetch.end);
      performance.measure(AuthContextApiFetch.measure, AuthContextApiFetch.start, AuthContextApiFetch.end);
      trackEvent(AuthContextApiFetch.name, {
        duration: performance.getEntriesByName(AuthContextApiFetch.measure)[0].duration,
        start: performance.getEntriesByName(AuthContextApiFetch.start)[0].startTime,
        end: performance.getEntriesByName(AuthContextApiFetch.end)[0].startTime,
      });

      if (authContextDataResponse) {
        if (!authContextData) {
          authContextData = {
            tokenMetadata: authContextDataResponse.tokenMetadata,
            userMetadata: authContextDataResponse.userMetadata,
          };
        } else {
          authContextData.tokenMetadata = { ...authContextData.tokenMetadata, ...authContextDataResponse.tokenMetadata };
        }

        deriveTokenFromAuthContext(authContextType, authContext);
      }
    } catch (error: any) {
      const message: string = error?.message ?? FailureAuthContext;
      const statusCode: string = error?.statusCode ?? '500';

      const errorInformation: ErrorInformation = {
        errorMessage: message,
        source: ErrorSource,
        statusCode: statusCode,
        errorObject: JSON.stringify(error) ?? '',
      };
      trackError('auth-context-failure', errorInformation);
      throw new HarmonyConnectorError(ErrorSource, FailureAuthContext);
    }
  }

  sessionStorage.setItem(SessionStorageKeys.AuthContextData, JSON.stringify(authContextData));
  return authContext;
};

/**
 * This is to derive tokens from the auth context API.
 * @param authContextType Tokens to fetch from the API.
 * @param authContext Auth context returned to the library consumers.
 * @returns void
 */
const deriveTokenFromAuthContext = (authContextType: AuthContextType[], authContext: any): void => {
  if (authContextData) {
    authContextType.forEach(token => {
      let tokenValue: string | UserMetadata | undefined;
      if (token.toLowerCase() === AuthContextType.UserMetadata.toLowerCase()) {
        tokenValue = authContextData?.userMetadata;
      } else {
        const convertedTokenType = convertTokenType(token);
        tokenValue = authContextData?.tokenMetadata?.[convertedTokenType]?.accessToken;
      }

      if (tokenValue) {
        authContext[token] = tokenValue;
      } else {
        throw new HarmonyConnectorError('AuthContextConnectorHarmonyPortal', `Response from authContextApi is not available for ${token}`);
      }
    });
  } else {
    throw new HarmonyConnectorError('AuthContextConnectorHarmonyPortal', 'Response from authContextApi not available');
  }
};

/**
 * The Token Type contract exposed via library is different from how different portal specific connectors handles the behavior.
 * Given that we want to expose a single contract across partner center and multi portal , we need to do the key conversion
 * while using the api response.
 * @param tokenType Token Type that is exposed to library consumers
 * @returns modified Token type with respect to Partner Portal.
 */
const convertTokenType = (tokenType: AuthContextType): TokenTypeHarmonyPortal => {
  if (TokenTypeConversion.has(tokenType)) {
    return TokenTypeConversion.get(tokenType) as TokenTypeHarmonyPortal;
  }

  throw new TypeError(`Invalid Token Type - ${tokenType}`);
};

/**
 * Auth Context Workspace Connector for Harmony Portal (Multi Portal). This handles the behavior on how to get token on multi portal side.
 * This is using multi portal Front Door Setup.
 * @param entity Indicates the workspace id or component name of the token.
 * @param tokenNames Indicates the token names
 * @param forceNew Indicates whether to make a new token request to FD.
 * @returns
 */
export const authContextConnectorWorkspaceHarmonyPortal = async (entity: string, tokenNames: string[], forceNew: boolean = false): Promise<WorkspaceAuthContextHarmonyPortalApi> => {
  if (!workspaceAuthContextData) {
    const sessionValue = sessionStorage.getItem(SessionStorageKeys.WorkspaceAuthContextData);
    workspaceAuthContextData = sessionValue ? JSON.parse(sessionValue) : undefined;
  }
  const authContext: WorkspaceAuthContext = {};

  // Check if the tokens exist and are valid
  const tokensToFetch: string[] = [];
  tokenNames.forEach(tokenName => {
    if (workspaceAuthContextData && !forceNew) {
      if (tokenName.toLowerCase() === AuthContextType.UserMetadata.toLowerCase()) {
        authContext[tokenName] = workspaceAuthContextData.userMetadata;
      } else {
        const mpTokenType = `${entity}_${tokenName}`;
        const selectedToken = workspaceAuthContextData[mpTokenType] as TokenMetadata;
        if (selectedToken && isTokenValid(selectedToken)) {
          authContext[tokenName] = selectedToken;
        } else {
          // forceNew to ensure existing promise is not reused
          forceNew = true;
          tokensToFetch.push(tokenName);
        }
      }
    } else {
      tokensToFetch.push(tokenName);
    }
  });

  // Form the request for the tokens to fetch.
  if (tokensToFetch.length > 0) {
    const baseUrl = `${window.location.origin}`;
    let url = `${baseUrl}${authContextUrl}?entity=${entity}`;
    tokensToFetch.forEach(tokenName => {
      if (tokenName != AuthContextType.UserMetadata) {
        url += `&tokenResources=${tokenName}`;
      }
    });

    const connectorPromiseStoreConfig: ConnectorPromiseStore = {
      connectorType: 'authContext',
      uniqueId: url + CONFIG_ID,
    };

    try {
      performance.mark(AuthContextWorkspaceApiFetch.start);
      const authContextResponse = await httpRequestRetryStore<WorkspaceAuthContextHarmonyPortalApi>(connectorPromiseStoreConfig, url, HttpFunctions.GET, null, null, 'same-origin', forceNew);
      performance.mark(AuthContextWorkspaceApiFetch.end);
      performance.measure(AuthContextWorkspaceApiFetch.measure, AuthContextWorkspaceApiFetch.start, AuthContextWorkspaceApiFetch.end);
      trackEvent(AuthContextWorkspaceApiFetch.name, {
        duration: performance.getEntriesByName(AuthContextWorkspaceApiFetch.measure)[0].duration,
        start: performance.getEntriesByName(AuthContextWorkspaceApiFetch.start)[0].startTime,
        end: performance.getEntriesByName(AuthContextWorkspaceApiFetch.end)[0].startTime,
      });

      if (authContextResponse) {
        // If workspaceAuthContextData is not defined, initialize it with userMetadata
        if (!workspaceAuthContextData) {
          workspaceAuthContextData = { userMetadata: authContextResponse.userMetadata, tokenMetadata: authContextResponse.tokenMetadata };
        } else {
          const tokenMetadata: any = { ...workspaceAuthContextData.tokenMetadata, ...authContextResponse.tokenMetadata };
          workspaceAuthContextData.tokenMetadata = tokenMetadata;
        }

        deriveTokenFromAuthContextWorkspace(tokensToFetch, authContext, entity, authContextResponse.tokenMetadata);
      }
    } catch (error: any) {
      const message: string = error?.message ?? FailureAuthContext;
      const statusCode: string = error?.statusCode ?? '500';

      const errorInformation: ErrorInformation = {
        errorMessage: message,
        source: ErrorSource,
        statusCode: statusCode,
        errorObject: JSON.stringify(error) ?? '',
      };
      trackError('auth-context-workspace-failure', errorInformation);
      throw new HarmonyConnectorError(ErrorSource, FailureAuthContext);
    }
  }

  sessionStorage.setItem(SessionStorageKeys.WorkspaceAuthContextData, JSON.stringify(workspaceAuthContextData));
  return authContext;
};

/**
 * This is to derive token from auth context.
 * @param tokensToFetch Tokens to fetch
 * @param authContext Auth Context
 * @param entity Entity
 * @param tokensResponse Tokens Response
 * @returns void
 */
const deriveTokenFromAuthContextWorkspace = (tokensToFetch: string[], authContext: WorkspaceAuthContext, entity: string, tokenMetataResponse: any): void => {
  tokensToFetch.forEach(tokenName => {
    if (tokenName.toLowerCase() === AuthContextType.UserMetadata.toLowerCase()) {
      authContext[tokenName] = workspaceAuthContextData.userMetadata;
    } else {
      const mpTokenType = `${entity}_${tokenName}`;
      const camelCaseTokenName = tokenName[0].toLocaleLowerCase() + tokenName.substring(1);

      const apiTokenResponse = tokenMetataResponse[camelCaseTokenName];
      if (apiTokenResponse) {
        workspaceAuthContextData[mpTokenType] = apiTokenResponse;
        authContext[tokenName] = workspaceAuthContextData[mpTokenType];
        return;
      } else {
        throw new HarmonyConnectorError('AuthContextWorkspaceConnectorHarmonyPortal', `Response from accessTokens is not available for ${tokenName}`);
      }
    }
  });
};

/**
 * Check the expiry of the token.
 * @param token token to check
 * @returns true if the token is expired, false otherwise
 */
const isTokenValid = (token: TokenMetadata): boolean => {
  const expiresOn = Date.parse(token.expiresOn);
  const isValid = expiresOn - Date.now() - MsToExpiry > 0;
  return isValid;
};
