export default {
  "back": "Πίσω",
  "breadcrumb": "Δυναμική διαδρομή",
  "clear": "Απαλοιφή",
  "clear_entry": "Απαλοιφή καταχώρησης",
  "close": "Κλείσιμο",
  "collapse_task_menu": "Σύμπτυξη μενού εργασιών",
  "collapse": "Σύμπτυξη",
  "commands": "Εντολές",
  "complete": "Ολοκληρώθηκε",
  "day": "Ημέρα",
  "dislike": "Δεν μου αρέσει",
  "error": "Σφάλμα",
  "expand_task_menu": "Ανάπτυξη μενού εργασιών",
  "expand": "Ανάπτυξη",
  "filter": "Φίλτρο",
  "finish": "Τέλος",
  "first": "Πρώτο",
  "go_to_page": "Μετάβαση στη σελίδα {0}",
  "hide_password": "Απόκρυψη κωδικού πρόσβασης",
  "last": "Τελευταίο",
  "like": "Όπως",
  "loading": "Φόρτωση...",
  "month": "Μήνας",
  "more_options": "Περισσότερες Επιλογές",
  "navigation": "Περιήγηση",
  "next": "Επόμενο",
  "next_month": "Επόμενος μήνας",
  "not_started": "Δεν έχει ξεκινήσει",
  "off": "Ανενεργό",
  "on": "Ενεργό",
  "open_in_new_window": "Άνοιγμα σε νέο παράθυρο",
  "options": "Επιλογές",
  "pagination": "Σελιδοποίηση",
  "plus_x_more": "+{0} ακόμη",
  "previous": "Προηγούμενο",
  "previous_month": "Προηγούμενος μήνας",
  "progress": "Σε εξέλιξη",
  "quick_start": "Γρήγορη εκκίνηση",
  "range_error_message": "Η ημερομηνία που επιλέξατε δεν βρίσκεται εντός του εύρους ημερομηνιών",
  "required_error_message": "Αυτό το πεδίο είναι απαιτούμενο",
  "results_found": "Βρέθηκαν {0} αποτελέσματα",
  "search": "Αναζήτηση",
  "select_all": "Επιλογή όλων",
  "select_row": "Επιλογή γραμμής",
  "show_calendar": "Εμφάνιση ημερολογίου",
  "show_password": "Εμφάνιση κωδικού πρόσβασης",
  "step_x_of_y": "Βήμα {0} από {1}",
  "today": "Σήμερα",
  "unavailable_date_error_message": "Η ημερομηνία που επιλέξατε δεν είναι διαθέσιμη",
  "warning": "Προειδοποίηση",
  "year": "Έτος"
}