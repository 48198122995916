import { css } from 'lit';
import componentStyles from '../../internal/styles/component.styles';
import { visuallyHiddenStyles } from '../../internal/styles/visually-hidden';

export default css`
  ${componentStyles}

  :host(:not(:focus-within)) {
    ${visuallyHiddenStyles}
  }
`;
