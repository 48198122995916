import { CustomEvents } from '../../helpers/constant';
import { determinePortalConfig, PortalConfig } from '../../services/portal-config';
import { trackError, trackEvent } from '../../services/telemetry-service/library-telemetry-service';
import { ErrorInformation } from '../../services/telemetry-service/types/error-information';
import { VersionPicker } from '../../utilities/version-picker';
import { HarmonyConnectorError } from '../connector-error';
import { authContextConnectorHarmonyPortal, authContextConnectorWorkspaceHarmonyPortal } from './auth-context-connector-harmony-portal';
import { authContextConnectorPartnerPortal } from './auth-context-connector-partner-portal';
import { IAuthContextConnector, IAuthContextWorkspaceConnector } from './i-auth-context-connector';

/**
 * @description It is the Enum for Auth Context Types that is exposed for consumption.
 *
 * @since 0.0.0
 * @status experimental
 */
export enum AuthContextType {
  MSGraph = 'MSGraph',
  PartnerCenterApp = 'PartnerCenterApp',
  PartnerApi = 'PartnerApi',
  ARM = 'ARM',
  CopilotToken = 'CopilotToken',
  UserMetadata = 'UserMetadata',
  UserToken = 'UserToken',
}

export type UserMetadata = {
  tenantId: string;
  isMsa: boolean;
  isWobo: boolean;
  persona: string;
};

export type TokenMetadata = {
  accessToken: string;
  appId: string;
  audience: string;
  expiresOn: string;
  tokenType: string;
};

export type AuthContext = {
  [key: string]: string | UserMetadata | undefined;
};

export type WorkspaceAuthContext = {
  [key: string]: TokenMetadata | UserMetadata | undefined;
};

let versionPicker: VersionPicker;

/**
 * @description This is the auth context connector that is responsible for providing list of supported tokens on the client side.
 * @param authContextType Indicates the token types that needs to be passed.
 * @param forceNew Indicates whether to make a new token request to FD.
 * @param caller Optional parameter to indicate where the connector is called from.
 *
 * @since 0.0.0
 * @status experimental
 */
export const authContextConnector: IAuthContextConnector = async (authContextType: AuthContextType[], forceNew: boolean = false, caller?: string): Promise<AuthContext> => {
  // version picker
  if (!versionPicker) {
    versionPicker = VersionPicker.getInstance();
  }

  const versionPickerEventLog = {
    connector: 'authContextConnector',
    scope: window.location.origin,
    href: window.location.href,
    caller: caller,
  };

  const library = await versionPicker?.loadShellLibrary();
  if (library?.authContextConnectorCore) {
    trackEvent(CustomEvents.VersionPickerUsed, versionPickerEventLog);
    return library.authContextConnectorCore(authContextType, forceNew, caller);
  } else {
    trackEvent(CustomEvents.VersionPickerFallback, versionPickerEventLog);
  }
  return authContextConnectorCore(authContextType, forceNew, caller);
};

export const authContextConnectorCore: IAuthContextConnector = async (authContextType: AuthContextType[], forceNew: boolean = false, caller?: string): Promise<AuthContext> => {
  const detectPortalConfig = await determinePortalConfig();

  let authContext: Promise<AuthContext>;
  if (detectPortalConfig === PortalConfig.PartnerPortal) {
    authContext = authContextConnectorPartnerPortal(authContextType, forceNew);
  } else {
    authContext = authContextConnectorHarmonyPortal(authContextType, forceNew);
  }

  // Log the authContextType to track where they are being called from
  const eventLog = {
    authContextType: authContextType,
    scope: window.location.origin,
    href: window.location.href,
    caller: caller,
  };
  trackEvent(CustomEvents.TokenAccess, eventLog);

  return authContext;
};

/**
 * @description It is the auth context workspace connector for workspaces that is responsible for providing list of supported tokens on the client side for workspace.
 * @param entity Indicates the workspace id or component name of the token.
 * @param tokenNames Indicates the token names.
 * @param forceNew Indicates whether to make a new token request to FD.
 * @param caller Optional parameter to indicate where the connector is called from.
 *
 * @since 0.0.0
 * @status experimental
 */
export const authContextWorkspaceConnector: IAuthContextWorkspaceConnector = async (
  entity: string,
  tokenNames: string[],
  forceNew: boolean = false,
  caller?: string
): Promise<WorkspaceAuthContext | undefined> => {
  // version picker
  if (!versionPicker) {
    versionPicker = VersionPicker.getInstance();
  }

  const versionPickerEventLog = {
    connector: 'authContextWorkspaceConnector',
    scope: window.location.origin,
    href: window.location.href,
    caller: caller,
  };

  const library = await versionPicker?.loadShellLibrary();
  if (library?.authContextWorkspaceConnectorCore) {
    trackEvent(CustomEvents.VersionPickerUsed, versionPickerEventLog);
    return library.authContextWorkspaceConnectorCore(entity, tokenNames, forceNew, caller);
  } else {
    trackEvent(CustomEvents.VersionPickerFallback, versionPickerEventLog);
  }

  return authContextWorkspaceConnectorCore(entity, tokenNames, forceNew, caller);
};

export const authContextWorkspaceConnectorCore: IAuthContextWorkspaceConnector = async (
  entity: string,
  tokenNames: string[],
  forceNew: boolean = false,
  caller?: string
): Promise<WorkspaceAuthContext | undefined> => {
  const detectPortalConfig = await determinePortalConfig();
  if (detectPortalConfig === PortalConfig.PartnerPortal) {
    const message = 'authContextWorkspaceConnector is not available for Partner Portal';
    const errorSource = 'authContextWorkspaceConnector';
    const errorInformation: ErrorInformation = {
      errorMessage: message,
      source: errorSource,
      statusCode: '400',
      errorObject: '',
    };
    trackError('auth-context-workspace-failure', errorInformation);
    throw new HarmonyConnectorError(errorSource, message);
  }

  const authContext = authContextConnectorWorkspaceHarmonyPortal(entity, tokenNames, forceNew);

  // Log the authContextType to track where they are being called from
  const eventLog = {
    authContextType: tokenNames,
    scope: window.location.origin,
    href: window.location.href,
    caller: caller,
  };
  trackEvent(CustomEvents.TokenAccess, eventLog);

  return authContext;
};
