export default {
  "back": "Indietro",
  "breadcrumb": "Barra di navigazione",
  "clear": "Cancella",
  "clear_entry": "Cancella voce",
  "close": "Chiudi",
  "collapse_task_menu": "Comprimi menu attività",
  "collapse": "Comprimi",
  "commands": "Comandi",
  "complete": "Completo",
  "day": "Giorno",
  "dislike": "Non mi piace",
  "error": "Errore",
  "expand_task_menu": "Espandi menu attività",
  "expand": "Espandi",
  "filter": "Filtro",
  "finish": "Fine",
  "first": "Primo",
  "go_to_page": "Vai alla pagina {0}",
  "hide_password": "Nascondi password",
  "last": "Ultimo",
  "like": "Mi piace",
  "loading": "Caricamento...",
  "month": "Mese",
  "more_options": "Altre opzioni",
  "navigation": "Navigazione",
  "next": "Avanti",
  "next_month": "Mese successivo",
  "not_started": "Non avviato",
  "off": "Off",
  "on": "On",
  "open_in_new_window": "Apri in un'altra finestra",
  "options": "Opzioni",
  "pagination": "Paginazione",
  "plus_x_more": "+ altri {0}",
  "previous": "Precedente",
  "previous_month": "Mese precedente",
  "progress": "In corso",
  "quick_start": "Avvio rapido",
  "range_error_message": "La data selezionata non è compresa nell'intervallo di date",
  "required_error_message": "Questo campo è obbligatorio",
  "results_found": "{0} risultati trovati",
  "search": "Cerca",
  "select_all": "Seleziona tutto",
  "select_row": "Seleziona riga",
  "show_calendar": "Mostra calendario",
  "show_password": "Mostra password",
  "step_x_of_y": "Passaggio {0} di {1}",
  "today": "Oggi",
  "unavailable_date_error_message": "La data selezionata non è disponibile",
  "warning": "Avviso",
  "year": "Anno"
}