import { css } from 'lit';

/** @internal */
export const litAmbientShadow = css`0 0 calc((var(--he-elevation) * 0.225px) + 2px) rgba(0, 0, 0, calc(.11 * (2 - var(--he-background-luminance, 1))))`;
/** @internal */
export const litDirectionalShadow = css`0 calc(var(--he-elevation) * 0.4px) calc((var(--he-elevation) * 0.9px)) rgba(0, 0, 0, calc(.13 * (2 - var(--he-background-luminance, 1))))`;
/** @internal */
export const litElevation = css`
  box-shadow: ${litAmbientShadow}, ${litDirectionalShadow};
`;

/**
 * Define shadow algorithms.
 *
 * @internal
 */
export const ambientShadow = litAmbientShadow.toString();

/**
 * @internal
 */
export const directionalShadow = litDirectionalShadow.toString();

/**
 * Applies the box-shadow CSS rule set to the elevation formula.
 * Control this formula with the --he-elevation CSS Custom Property
 * by setting --he-elevation to a number.
 *
 * Example:
 * :host { --he-elevation: 16; }
 * .classWithShadow { ${elevation} }
 *
 * @public
 */
export const elevation = litElevation.toString();
