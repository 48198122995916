import { css } from 'lit';
import componentStyles from '../../internal/styles/component.styles';

export default css`
  ${componentStyles}

  :host {
    display: inline-block;
    font-size: max(1em, var(--he-icon-size));
    width: 1em;
    height: 1em;
    contain: strict;
    box-sizing: content-box !important;
    vertical-align: middle;
  }

  svg {
    display: block;
    height: 100%;
    width: 100%;
    fill: currentColor;
  }
`;
