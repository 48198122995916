import { html } from 'lit/static-html.js';
import { property, query, state } from 'lit/decorators.js';
import { getScope } from '../../utilities/scope';
import { waitForEvent } from '../../utilities/wait-for-event';
import { Popup, default as popupComponent } from '../popup/popup';
import HarmonyElement from '../';
import { watch } from '../../internal/watch';
import { LooseString } from '../../types';
import styles from './dropdown.styles';

import type { Menu } from '../menu/menu';
import type { MenuItem } from '../menu-item/menu-item';

export const DropdownPosition = {
  top: 'top',
  topStart: 'top-start',
  topEnd: 'top-end',
  topCenter: 'top-center',
  bottom: 'bottom',
  bottomStart: 'bottom-start',
  bottomEnd: 'bottom-end',
  bottomCenter: 'bottom-center',
} as const;

export type DropdownPositionValues = (typeof DropdownPosition)[keyof typeof DropdownPosition];

export type DropdownCloseSource = LooseString<'change' | 'trigger' | 'document' | 'keyboard'>;

// id for slotted trigger (will only be added if one doesn't exist)
let dropdownButtonId = 0;

// id for slotted menu (will only be added if one doesn't exist)
let menuId = 0;

/**
 * @tag he-dropdown
 * @since 3.0
 * @status stable
 * @figma https://www.figma.com/file/dRwBPvZFZdYgWdAOCK375K/PC-Toolkit?node-id=1226%3A0
 *
 * @dependency he-popup
 *
 * @slot - The default slot containing the menu to show when the trigger is clicked.
 * @slot trigger - The button or other element that will trigger the dropdown. (If more than one element is slotted, it will only use the first one.)
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-show - Emitted when the dropdown menu is shown
 * @event he-after-show - Emitted after the dropdown menu is shown and all transitions are complete
 * @event he-hide - Emitted when the dropdown menu closes.
 * @event he-after-hide - Emitted after the dropdown menu closes and all transitions are complete.
 * @event {{ source: 'change' | 'trigger' | 'document' | 'keyboard' }} he-request-close - Emitted when the user attempts to
 *   close the dropdown menu by selecting a menu item, clicking the trigger, clicking outside of the menu, or pressing the
 *   escape key. Calling `event.preventDefault()` will prevent the menu from closing. You can check `event.detail.source`
 *   to determine how the request was initiated. Avoid using this unless closing the menu will result in destructive
 *   behavior and you have provided another method to close it.
 *
 * @csspart menu-wrapper - The wrapper containing the default slot.
 * @csspart popup - The popup component.
 * @csspart popup__popup - The popup component's container. Useful for adjusting z-index if the menu is showing behind other elements.
 *
 * @cssproperty [--he-elevation=8] - Elevation of the menu.
 * @cssproperty [--max-menu-height=360px] - Maximum height of the menu (will still be restricted to available space in the viewport).
 */
export class Dropdown extends HarmonyElement {
  static styles = styles;
  static baseName = 'dropdown';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeShow: new CustomEvent('he-show'),
    onHeAfterShow: new CustomEvent('he-after-show'),
    onHeHide: new CustomEvent('he-hide'),
    onHeAfterHide: new CustomEvent('he-after-hide'),
    onHeRequestClose: new CustomEvent<{ source: DropdownCloseSource }>('he-request-close'),
  };

  @state() trigger: HTMLElement | undefined;
  @state() menu: Menu | undefined;
  @state() viewportElement: HTMLElement | null | undefined;
  @state() menuDefaultSlot: HTMLSlotElement | null | undefined;
  @state() items: MenuItem[];

  @query('.he-popup') popup: Popup;

  /** Whether the menu is open or not. */
  @property({ reflect: true, type: Boolean }) open: boolean = false;

  /**
   * Controls the placement of the menu to the trigger. When the position is undefined, it is placed above or below the
   * trigger based on available space.
   */
  @property({ reflect: true }) position: DropdownPositionValues = 'bottom-start';

  /**
   * Whether the anchored region is positioned using css "position: fixed". Otherwise the region uses "position: absolute".
   * Fixed placement allows the dropdown contents to break out of parent containers.
   */
  @property({ reflect: true, attribute: 'fixed-placement', type: Boolean }) fixedPlacement: boolean = false;

  @watch('open', { waitUntilFirstUpdate: true })
  handleOpenChanged() {
    this.trigger?.setAttribute('aria-expanded', this.open.toString());
    this.open ? this.emit('he-show', { cancelable: true }) : this.emit('he-hide', { cancelable: true });
  }

  @watch('trigger')
  @watch('menu')
  setAriaLabel() {
    if (!this.menu || !this.trigger) return;

    this.trigger.setAttribute('aria-expanded', this.open.toString());
    this.trigger.setAttribute('aria-haspopup', 'menu');

    // TODO: This causes axe test to fail, as FAST sets aria-controls on the <button> control inside the he-button shadowroot
    // this.trigger.setAttribute('aria-controls', this.menu.id);

    if (!this.trigger.id) {
      this.trigger.id = `he-dropdown-button-${++dropdownButtonId}`;
    }

    if (!this.menu.id) {
      this.menu.id = `he-menu-${++menuId}`;
    }

    if (!this.menu.getAttribute('aria-labelledby')) {
      this.menu.setAttribute('aria-labelledby', this.trigger.id);
    }
  }

  handleTriggerSlotChange(e: Event) {
    const target = e.target as HTMLSlotElement;

    // don't change trigger if slot change event is coming from slots inside button
    if (target.name !== 'trigger') return;

    const elements = target.assignedElements({ flatten: true }) as HTMLElement[];

    if (!elements.length) return;

    // remove event listener from old trigger
    this.trigger?.removeEventListener('click', this.handleClick);

    this.trigger = elements[0];

    // add listener to new trigger
    this.trigger?.addEventListener('click', this.handleClick);

    this.setAriaLabel();
  }

  handleDefaultSlotChange(e: Event) {
    const target = e.target as HTMLSlotElement;

    const menuElements = target
      .assignedElements({ flatten: true })
      .filter(el => el.getAttribute('role') === 'menu') as Menu[];

    if (menuElements.length <= 0) return;

    this.menu = menuElements[0];

    this.removeMenuListener();
    this.attachMenuListener();
    this.setAriaLabel();
  }

  attachMenuListener() {
    this.menuDefaultSlot = this.menu?.shadowRoot?.querySelector('slot:not([name])');
    this.menuDefaultSlot?.addEventListener('slotchange', this.handleMenuDefaultSlotChange);
    this.handleMenuDefaultSlotChange();
  }

  removeMenuListener() {
    this.menuDefaultSlot?.removeEventListener('slotchange', this.handleMenuDefaultSlotChange);
  }

  // for notifier on slotted menu
  handleMenuDefaultSlotChange = async () => {
    await this.updateComplete;

    this.items = [...this.querySelectorAll('.he-menu-item')] as MenuItem[];

    this.items.forEach((item: MenuItem) => {
      item.addEventListener('keydown', this.boundHandleMenuItemKeyDown);
      item.submenuFixedPlacement = true;
    });
  };

  scope = getScope(this);

  boundHandleKeyDown = this.handleKeyDown.bind(this);
  boundHandleMenuItemKeyDown = this.handleMenuItemKeyDown.bind(this);

  constructor() {
    super();
    this.scope.registerComponent(popupComponent);
  }

  async connectedCallback() {
    super.connectedCallback();

    document.addEventListener('click', this.handlePageClick);
    document.addEventListener('keydown', this.handleKeyboardClose);
    this.addEventListener('he-change', this.handleDropdownChange);
    this.addEventListener('keydown', this.boundHandleKeyDown);

    await this.updateComplete;

    this.trigger?.setAttribute('aria-expanded', this.open.toString());
  }

  handlePageClick = (e: Event) => {
    if (e.composedPath().includes(this) || !this.open) return;

    this.requestClose('document');
  };

  handleKeyboardClose = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && this.open) {
      this.requestClose('keyboard');
    }
  };

  firstUpdated() {
    this.attachMenuListener();
    this.trigger?.setAttribute('aria-expanded', this.open.toString());
  }

  disconnectedCallback() {
    document.removeEventListener('click', this.handlePageClick);
    document.removeEventListener('keydown', this.handleKeyboardClose);
    this.removeMenuListener();
    this.removeEventListener('he-change', this.handleDropdownChange);
    this.removeEventListener('keydown', this.boundHandleKeyDown);
    this.removeEventListener('he-request-close', this.handleRequestClose);
    super.disconnectedCallback();
  }

  /**
   * Shows the dropdown. Returns a promise that resolves when the dropdown is visible and the transition has completed.
   *
   * @example
   * dropdown.show().then(../.);
   * // or
   * await dropdown.show();
   *
   * @returns {Promise} Promise for `he-after-show`
   */
  async show(): Promise<void> {
    if (this.open) return;
    // timeout added to prevent close from external trigger
    setTimeout(() => (this.open = true));
    return waitForEvent(this, 'he-after-show');
  }

  /**
   * Hides the dropdown. Returns a promise that resolves when the dropdown is no longer visible and the transition has
   * completed.
   *
   * @example
   * dropdown.hide().then(../.);
   * // or
   * await dropdown.hide();
   *
   * @returns {Promise} Promise for `he-after-hide`
   */
  async hide(): Promise<void> {
    if (!this.open) return;

    this.open = false;
    return waitForEvent(this, 'he-after-hide');
  }

  /**
   * Shows or hides the dropdown depending on its current visibility. Returns a promise that resolves when the
   * transition has completed.
   *
   * @returns {Promise} Promise for `he-after-show` or `he-after-hide`
   */
  async toggle(): Promise<void> {
    return this.open ? this.hide() : this.show();
  }

  async handlePopupTransition(e: TransitionEvent) {
    await this.updateComplete;
    this.open ? this.emit('he-after-show') : this.emit('he-after-hide');
  }

  get triggerIsDisabled(): boolean {
    if (this.trigger == null) return true;

    const trigger = this.trigger;

    return trigger.hasAttribute('disabled') || trigger.ariaDisabled === 'true';
  }

  handleKeyDown(e: KeyboardEvent) {
    if (e.defaultPrevented) return;
    if (!this.menu || !this.trigger) return;
    if (this.triggerIsDisabled) return;

    if (this.open) {
      // keyboard functions when menu is open
      switch (e.key) {
        case 'ArrowDown':
          if (e.target !== this.trigger) return true;
          e.preventDefault();

          // focus on first item
          this.menu.focus();
          return false;

        case 'ArrowUp':
          if (e.target !== this.trigger) return true;
          e.preventDefault();

          // focus on last item
          this.menu.handleMenuKeyDown(new KeyboardEvent('keydown', { key: 'End' }));
          return false;

        case 'Home':
        case 'End':
          if (e.target !== this.trigger) return true;

          // trigger key event in menu
          this.menu.handleMenuKeyDown(new KeyboardEvent('keydown', { key: e.key }));
          return false;

        case 'Tab':
          if (e.shiftKey) {
            if (e.target === this.trigger && this.open) {
              e.preventDefault();
              this.requestClose('keyboard');
            }
            return true;
          }

          e.preventDefault();
          if (e.target === this.trigger) {
            // focus on first item
            this.menu.focus();
          } else {
            // close menu and focus on trigger
            this.requestClose('keyboard');
            this.trigger.focus();
          }

          return false;

        default:
          // if we are not handling the event, do not prevent default
          return true;
      }
    } else {
      // keyboard functions when menu is closed
      switch (e.key) {
        case 'ArrowDown':
        case 'ArrowUp':
          if (!e.composedPath().includes(this.trigger)) return true;

          e.preventDefault();
          this.open = true;
          return false;

        default:
          return true;
      }
    }
  }

  handleDropdownChange = (e: Event) => {
    // close menu when menuitem is picked
    const role = (e.target as MenuItem)?.role;

    if (!role) return;

    if (role.startsWith('menuitem')) {
      this.requestClose('change');
    }
  };

  handleMenuItemKeyDown(e: KeyboardEvent): boolean {
    // focus back on trigger element if change is triggered through keyboard
    if ((e.key === 'Enter' || e.key === ' ') && !this.open) this.trigger?.focus();
    return true;
  }

  handleRequestClose = (event: CustomEvent) => {
    if (event.defaultPrevented) {
      event.stopPropagation();
      this.open = true;
      return;
    }

    this.open = false;
    this.trigger?.focus();
  };

  requestClose(source: DropdownCloseSource) {
    const evt = this.emit('he-request-close', {
      cancelable: true,
      detail: { source },
    });

    if (evt.defaultPrevented) return;
    this.handleRequestClose(evt);
  }

  handleClick = (e: MouseEvent) => {
    if (this.triggerIsDisabled || e.defaultPrevented) {
      return;
    }

    if (this.open === true) {
      this.requestClose('trigger');
      return;
    }

    this.open = true;
  };

  render() {
    return html`
      <slot
        id="anchor" name="trigger"
        @slotchange=${this.handleTriggerSlotChange}
      >
      </slot>

      <${this.scope.tag('popup')}
        class="popup"
        part="popup"
        exportparts="
          base:popup,
          popup:popup__popup
        "
        placement=${this.position}
        strategy=${this.fixedPlacement ? 'fixed' : 'absolute'}
        shift
        shift-padding="4"
        auto-size="both"
        flip
        ?active=${this.open}
        .shiftBoundary=${this.viewportElement}
        .flipBoundary=${this.viewportElement}
        .autoSizeBoundary=${this.viewportElement}
        anchor="anchor"
      >
        ${this.content()}
      </${this.scope.tag('popup')}>
    `;
  }

  content() {
    return html`
      <div
        @transitionend=${this.handlePopupTransition}
        @transitioncancel=${this.handlePopupTransition}
        class="menu-wrapper"
        part="menu-wrapper"
      >
        <slot @slotchange=${this.handleDefaultSlotChange}></slot>
      </div>
    `;
  }

  isAlreadyCheckedMenuItem(target: EventTarget | null): boolean {
    return !!(
      this.open &&
      target &&
      (target as HTMLElement).getAttribute('role') === 'menuitemradio' &&
      (target as MenuItem)?.checked
    );
  }
}

export default Dropdown;
