import { css } from 'lit';
import componentStyles from '../../internal/styles/component.styles';
import { litElevation } from '../../internal/styles/elevation';
import { SystemColors } from '../../utilities/system-colors';

export default css`
  ${componentStyles}

  :host {
    --he-elevation: 64;
    --size: var(--he-panel-size-small);
    --header-padding-y: var(--he-spacing-large);
    --header-padding-x: var(--he-spacing-x-large);
    --header-padding: var(--header-padding-y) var(--header-padding-x);

    --body-padding-y: var(--he-spacing-large);
    --body-padding-x: var(--he-spacing-x-large);
    --body-padding: var(--body-padding-y) var(--body-padding-x);

    --footer-padding-y: var(--he-spacing-large);
    --footer-padding-x: var(--he-spacing-large);
    --footer-padding: var(--footer-padding-y) var(--footer-padding-x);
    color: var(--he-type-primary);
    display: contents;
  }

  :host([size='small']) {
    --size: var(--he-panel-size-small);
  }

  :host([size='medium']) {
    --size: var(--he-panel-size-medium);
  }

  :host([size='large']) {
    --size: var(--he-panel-size-large);
  }

  :host([size='extra-large']) {
    --size: var(--he-panel-size-extra-large);
  }

  .fly-in {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
  }

  .fly-in--contained {
    position: absolute;
    z-index: initial;
  }

  .fly-in--fixed {
    position: fixed;
    z-index: 800;
  }

  .fly-in__panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 2;
    max-width: 100%;
    max-height: 100%;
    background-color: var(--he-color-surface-background);
    ${litElevation};
    overflow: auto;
    pointer-events: all;
  }

  .fly-in__panel:focus {
    outline: none;
  }

  .fly-in--right .fly-in__panel {
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    width: var(--size);
    height: 100%;
  }

  .fly-in--left .fly-in__panel {
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
    width: var(--size);
    height: 100%;
  }

  .fly-in__header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: var(--header-padding);
    position: sticky;
    top: 0;
    background-color: var(--he-color-surface-background);
    z-index: 3;
  }

  .fly-in__header__slots {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: var(--he-spacing-x-small);
  }

  .fly-in__actions {
    display: none;
    gap: var(--he-spacing-2x-small);
  }

  .fly-in--has-actions .fly-in__actions {
    display: flex;
  }

  .fly-in__title {
    font-size: var(--he-font-size-2x-large);
    line-height: var(--he-line-height-2x-large);
    font-weight: var(--he-font-weight-semibold);
    margin: 0;
  }

  .fly-in__close {
    flex: 0 0 auto;
    display: flex;
    font-size: var(--he-font-size-large);
    text-decoration: none;
    cursor: pointer;
  }

  .fly-in__hidden-close {
    position: relative;
    display: block;
  }

  .fly-in__hidden-close .fly-in__close {
    position: absolute;
    right: 0;
    z-index: 1;
  }

  .fly-in__body {
    flex: 1 1 auto;
    padding: var(--body-padding);
    -webkit-overflow-scrolling: touch;
  }

  .fly-in__footer {
    text-align: start;
    padding: var(--footer-padding);
    position: sticky;
    bottom: 0;
    z-index: 3;
    background-color: var(--he-color-surface-background);
  }

  .fly-in__footer ::slotted(.he-button:not(:last-of-type)) {
    margin-inline-end: var(--he-spacing-x-small);
  }

  .fly-in:not(.fly-in--has-footer) .fly-in__footer {
    display: none;
  }

  .fly-in__overlay {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: all;
  }

  .fly-in--contained .fly-in__overlay {
    position: absolute;
  }

  /* High contrast */
  @media screen and (forced-colors: active) {
    :host {
      color: ${SystemColors.CanvasText};
    }

    .fly-in__panel {
      forced-color-adjust: none;
      background: ${SystemColors.Canvas};
      box-shadow: 0 0 0 1px ${SystemColors.CanvasText};
    }
  }
`;
