import { property } from 'lit/decorators.js';
import { html } from 'lit/static-html.js';
import HarmonyElement from '../';
import { HasSlotController } from '../../internal/slot';
import styles from './page-title.styles';

/**
 * @tag he-page-title
 * @since 1.5
 * @status stable
 * @figma https://www.figma.com/file/dRwBPvZFZdYgWdAOCK375K/PC-Toolkit?node-id=395%3A1264
 *
 * @slot - Workspace title.
 * @slot active-title - Active page title.
 *
 * @csspart base - The <h1> component wrapping both slots.
 * @csspart workspace-title - Used to style the wrapper around the default slot.
 * @csspart active-title - Used to style the wrapper around the active-title slot.
 * @csspart divider - Used to style the line between the primary and secondary title.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 */
export class PageTitle extends HarmonyElement {
  static styles = styles;
  static baseName = 'page-title';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
  };

  /** @deprecated */
  @property({ attribute: 'active-title' }) activeTitle: Node[];

  private hasSlotController = new HasSlotController(this, '[default]', 'active-title');

  render() {
    return html`
      <h1 part="base">
        <span class="title" part="workspace-title">
          <slot></slot>
          <span class="divider" part="divider" ?hidden=${!this.hasSlotController.test('active-title')}>|</span>
        </span>
        <span class="active-title" part="active-title" ?hidden=${!this.hasSlotController.test('active-title')}>
          <slot name="active-title"></slot>
        </span>
      </h1>
    `;
  }
}

export default PageTitle;
