export default {
  "back": "Atpakaļ",
  "breadcrumb": "Atpakaļceļš",
  "clear": "Notīrīt",
  "clear_entry": "Notīrīt ievadni",
  "close": "Aizvērt",
  "collapse_task_menu": "Sakļaut uzdevuma izvēlni",
  "collapse": "Sakļaut",
  "commands": "Komandas",
  "complete": "Pabeigt",
  "day": "Diena",
  "dislike": "Nepatīk",
  "error": "Kļūda",
  "expand_task_menu": "Izvērst uzdevuma izvēlni",
  "expand": "Izvērst",
  "filter": "Filtrs",
  "finish": "Pabeigt",
  "first": "Pirmais",
  "go_to_page": "Doties uz lapu {0}",
  "hide_password": "Paslēpt paroli",
  "last": "Pēdējais",
  "like": "Patīk",
  "loading": "Notiek ielāde...",
  "month": "Mēnesis",
  "more_options": "Citas opcijas",
  "navigation": "Navigācija",
  "next": "Tālāk",
  "next_month": "Nākamais mēnesis",
  "not_started": "Nav sākts",
  "off": "Izslēgts",
  "on": "Ieslēgts",
  "open_in_new_window": "Atvērt jaunā logā",
  "options": "Opcijas",
  "pagination": "Lapdale",
  "plus_x_more": "+ vēl {0}",
  "previous": "Atpakaļ",
  "previous_month": "Iepriekšējais mēnesis",
  "progress": "Notiek izpilde",
  "quick_start": "Ātrā sākšana",
  "range_error_message": "Atlasītais datums nav datumu diapazonā",
  "required_error_message": "Lauks ir obligāts",
  "results_found": "Atrasti {0} rezultāti",
  "search": "Meklēt",
  "select_all": "Atlasīt visu",
  "select_row": "Atlasīt rindu",
  "show_calendar": "Rādīt kalendāru",
  "show_password": "Rādīt paroli",
  "step_x_of_y": "{0}. darbība no {1}",
  "today": "Šodien",
  "unavailable_date_error_message": "Atlasītais datums nav pieejams",
  "warning": "Brīdinājums",
  "year": "Gads"
}