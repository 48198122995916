function registerAiInstance(instrumentationKey: string, config?: any) {
  if (window.Microsoft?.ApplicationInsights) {
    const aiConfig = config ? { config: { ...config, instrumentationKey } } : { config: { instrumentationKey } };
    // @ts-expect-error - using a global that's defined in another app
    const init = new Microsoft.ApplicationInsights.Initialization(aiConfig);
    //**NOTE PC is using AppInsights V1 initialization here differs from V2
    const aiInstance = init.loadAppInsights();

    if (!!aiInstance) {
      return aiInstance;
    }
  }
  throw new Error('Registration of secondary AppInsights failed');
}

function trackEvent(event: CustomEvent, instance: any, harmonyVersion: string) {
  instance.trackEvent(event.detail?.metricName || 'HarmonyTelemetry', {
    ...event.detail,
    message: event.detail?.message || '',
    version: harmonyVersion,
    href: window.location.href,
    pathName: window.location.pathname,
    ...event.detail?.customProperties,
  });
}

function trackError(event: CustomEvent, instance: any, harmonyVersion: string) {
  instance.trackException(
    event.detail?.error,
    null,
    {
      ...event.detail,
      version: harmonyVersion,
      href: window.location.href,
      pathName: window.location.pathname,
    },
    null,
    event.detail?.severity || 4
  );
}

function trackPerformance(event: CustomEvent, instance: any, harmonyVersion: string) {
  instance.trackMetric(
    event.detail?.metricName || 'HarmonyPerformance',
    event.detail?.performanceMetrics?.average || 0,
    event.detail?.performanceMetrics?.sampleCount || 0,
    event.detail?.performanceMetrics?.min || 0,
    event.detail?.performanceMetrics?.max || 0,
    {
      ...event.detail,
      version: harmonyVersion,
      href: window.location.href,
      pathName: window.location.pathname,
    }
  );
}

export const AiV1 = {
  trackEvent,
  trackError,
  trackPerformance,
  registerAiInstance,
};
