/**
 * Shared variables that don't change with light/dark theme
 */

import { css } from 'lit';

export default css`
  :root,
  .he-theme-light,
  .he-theme-dark {
    /**
     * Typography
     */

    --he-body-font: var(--he-font-sans-serif);
    --he-body-font-weight: var(--he-font-weight-normal);
    --he-body-line-height: var(--he-line-height-normal);

    --he-heading-font: var(--he-font-sans-serif);
    --he-heading-font-weight: var(--he-font-weight-semibold);

    --he-font-sans-serif: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --he-font-serif: serif;
    --he-font-mono: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;

    --he-font-weight-normal: 400;
    --he-font-weight-semibold: 600;
    --he-font-weight-bold: 700;

    --he-font-size-x-small: 10px;
    --he-font-size-small: 12px;
    --he-font-size-medium: 14px;
    --he-font-size-large: 16px;
    --he-font-size-x-large: 18px;
    --he-font-size-2x-large: 20px;
    --he-font-size-3x-large: 24px;
    --he-font-size-4x-large: 32px;
    --he-font-size-5x-large: 42px;

    --he-line-height-x-small: 14px;
    --he-line-height-small: 16px;
    --he-line-height-medium: 20px;
    --he-line-height-large: 22px;
    --he-line-height-x-large: 24px;
    --he-line-height-2x-large: 28px;
    --he-line-height-3x-large: 32px;
    --he-line-height-4x-large: 40px;
    --he-line-height-5x-large: 52px;

    --he-line-height-dense: 1.125;
    --he-line-height-normal: 1.43;
    --he-line-height-loose: 2;

    --he-letter-spacing-dense: -0.015em;
    --he-letter-spacing-normal: normal;
    --he-letter-spacing-loose: 0.075em;

    /**
     * Elevation
     */

    /** Default elevation. See src/internal/styles/elevation.ts for instructions on elevation. */
    --he-elevation: 4;

    /** Components should always use --he-elevation, but these static vars can be used elsewhere */
    --he-elevation-4: 0px 0.3px 0.9px rgb(0 0 0 / 10%), 0px 1.6px 3.6px rgb(0 0 0 / 13%);
    --he-elevation-8: 0px 0.6px 1.8px rgb(0 0 0 / 10%), 0px 3.2px 7.2px rgb(0 0 0 / 13%);
    --he-elevation-16: 0px 1.2px 3.6px rgb(0 0 0 / 10%), 0px 6.4px 14.4px rgb(0 0 0 / 13%);
    --he-elevation-64: 0px 4.8px 14.4px rgb(0 0 0 / 18%), 0px 25.6px 57.6px rgb(0 0 0 / 22%);

    /**
     * Spacing
     */

    --he-spacing-3x-small: 2px;
    --he-spacing-2x-small: 4px;
    --he-spacing-x-small: 8px;
    --he-spacing-small: 12px;
    --he-spacing-medium: 16px;
    --he-spacing-large: 20px;
    --he-spacing-x-large: 24px;
    --he-spacing-2x-large: 32px;
    --he-spacing-3x-large: 48px;
    --he-spacing-4x-large: 64px;
    --he-spacing-5x-large: 84px;

    /**
     * Border radius
     */

    --he-border-radius-small: 2px;
    --he-border-radius-medium: 4px;
    --he-border-radius-large: 6px;
    --he-border-radius-circle: 50%;
    --he-border-radius-pill: 9999px;

    /**
     * Form controls
     */

    --he-form-control-border-color: var(--he-color-neutral-700);
    --he-form-control-border-color-hover: var(--he-color-neutral-850);
    --he-form-control-border-radius: var(--he-border-radius-small);
    --he-form-control-border-width: 1px;
    --he-form-control-color: var(--he-type-primary);
    --he-form-control-disabled-opacity: 46%;
    --he-form-control-disabled-background: var(--he-color-neutral-300);
    --he-form-control-error-color: var(--he-color-danger-800);
    --he-form-control-focus-ring-color: var(--he-accent-fill-rest);
    --he-form-control-focus-ring: inset 0 0 0 var(--he-focus-ring-width) var(--he-form-control-focus-ring-color);
    --he-form-control-font-family: var(--he-font-family-sans-serif);
    --he-form-control-font-size: var(--he-font-size-medium);
    --he-form-control-height-small: 24px;
    --he-form-control-height: 32px;
    --he-form-control-help-text-font-size: var(--he-font-size-medium);
    --he-form-control-label-font-size: var(--he-font-size-medium);
    --he-form-control-placeholder-color: var(--he-color-neutral-650);
    --he-form-control-required-content-offset: -2px;
    --he-form-control-required-content: '*';
    --he-form-control-required-color: var(--he-form-control-error-color);
    --he-form-control-toggle-border-color: var(--he-color-neutral-800);
    --he-form-control-toggle-focus-ring-color: var(--he-focus-ring-color);
    --he-form-control-toggle-focus-ring: inset 0 0 0 var(--he-focus-ring-width)
      var(--he-form-control-toggle-focus-ring-color);
    --he-form-control-toggle-size: 20px; /* checkboxes, radios, etc. */

    /**
     * Other controls
     */

    --he-menu-control-height: 36px;
    --he-menu-control-border-radius: var(--he-border-radius-small);

    /**
     * Focus
     */

    --he-focus-ring-width: 1px;
    --he-focus-ring-color: var(--he-color-neutral-650);
    --he-focus-ring: 0 0 0 var(--he-focus-ring-width) var(--he-focus-ring-color);

    /**
     * Custom scrollbar
     */

    --he-scrollbar-width: 6px;
    --he-scrollbar-radius: 6px;

    /**
     * Icon sizes
     */
    --he-icon-size: 16px; /* standard icon size */
    --he-chevron-size: max(0.86em, 12px); /* carets in dropdowns, etc */

    /**
     * Panes / Panels
     */
    --he-panel-size-small: 340px;
    --he-panel-size-medium: 644px;
    --he-panel-size-large: 940px;
    --he-panel-size-extra-large: 1280px;

    /*
    * Tooltips
    */
    --he-tooltip-border-radius: var(--he-border-radius-medium);
    --he-tooltip-background-color: var(--he-color-neutral-800);
    --he-tooltip-color: var(--he-color-neutral-0);
    --he-tooltip-font-family: var(--he-font-sans);
    --he-tooltip-font-weight: var(--he-font-weight-normal);
    --he-tooltip-font-size: var(--he-font-size-small);
    --he-tooltip-line-height: var(--he-line-height-dense);
    --he-tooltip-padding: var(--he-spacing-2x-small) var(--he-spacing-x-small);
    --he-tooltip-arrow-size: 4px;
  }
`;
