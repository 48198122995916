import { html } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { defaultValue } from '../../internal/default-value';
import { watch } from '../../internal/watch';
import { FormSubmitController } from '../../internal/form';
import HarmonyElement from '../';
import componentStyles from '../../internal/styles/component.styles';
import formControlStyles from '../../internal/styles/form-control.styles';
import { IHarmonyInput } from '../../internal/interfaces/input-interfaces';
import { HasSlotController } from '../../internal/slot';
import styles from './checkbox.styles';

/**
 * @tag he-checkbox
 * @since 1.3
 * @status stable
 * @figma https://www.figma.com/file/dRwBPvZFZdYgWdAOCK375K/PC-Toolkit?node-id=5%3A890
 *
 * @slot - The checkbox's label.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-blur - Emitted when the control loses focus.
 * @event he-change - Emitted when the control's checked state changes.
 * @event he-focus - Emitted when the control gains focus.
 *
 * @csspart base - The component's internal wrapper.
 * @csspart control - The checkbox control.
 * @csspart checked-icon - The container the wraps the checked icon.
 * @csspart indeterminate-icon - The container that wraps the indeterminate icon.
 * @csspart label - The checkbox label.
 * @csspart control-label-wrapper - The container wrapping control and label parts.
 * @csspart form-control-error-text - The control's error text
 * @csspart form-control - The components outer wrapper
 */
export class Checkbox extends HarmonyElement implements IHarmonyInput {
  static styles = [componentStyles, formControlStyles, styles];
  static baseName = 'checkbox';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeBlur: new CustomEvent('he-blur'),
    onHeChange: new CustomEvent('he-change'),
    onHeFocus: new CustomEvent('he-focus'),
  };

  private _originallyDisabled = false;
  private _disabledByCheckboxGroup = false;

  private readonly formSubmitController = new FormSubmitController(this, {
    value: (control: Checkbox) => (control.checked ? control.value || 'on' : undefined),
    defaultValue: (control: Checkbox) => control.defaultChecked,
    setValue: (control: Checkbox, checked: boolean) => (control.checked = checked),
  });

  private readonly hasSlotController = new HasSlotController(this, '[default]');

  @state()
  private hasFocus = false;

  @state()
  private hadFocus = false;

  @query('input[type="checkbox"]')
  input?: HTMLInputElement;

  /** The checkbox's name attribute. */
  @property({ reflect: true })
  name?: string;

  /** The checkbox's value attribute. */
  @property({ reflect: true })
  value: string = '';

  /** The input's label. Alternatively, you can use the default slot. */
  @property()
  label?: string;

  /** Disables the checkbox. */
  @property({ type: Boolean, reflect: true })
  disabled = false;

  /** Makes the checkbox a required field. */
  @property({ type: Boolean, reflect: true })
  required = false;

  /** Draws the checkbox in a checked state. */
  @property({ type: Boolean, reflect: true })
  checked = false;

  /** Draws the checkbox in an indeterminate state. */
  @property({ type: Boolean, reflect: true })
  indeterminate = false;

  /** This will be true when the control is in an invalid state. Validity is determined by the `required` prop. */
  @property({ type: Boolean, reflect: true })
  invalid = false;

  /** Sets focus on the checkbox on page load. */
  @property({ type: Boolean, reflect: true })
  autofocus: boolean;

  /** Makes the checkbox readonly. */
  @property({ type: Boolean, reflect: true })
  readonly: boolean;

  /** @internal - Used to disable checkbox at a group level */
  @property({ attribute: false, type: Boolean })
  set disabledByCheckboxGroup(disabled: boolean) {
    this._disabledByCheckboxGroup = disabled;
    this.disabled = this._originallyDisabled || this._disabledByCheckboxGroup;
  }

  /** @internal Gets or sets the default value used to reset this element. The initial value corresponds to the one originally specified in the HTML that created this element. */
  @defaultValue('checked')
  defaultChecked = false;

  firstUpdated() {
    this.invalid = !this.input?.checkValidity();
    this._originallyDisabled = this.disabled;
  }

  /** Simulates a click on the checkbox. */
  click() {
    this.input?.click();
  }

  /** Sets focus on the checkbox. */
  focus(options?: FocusOptions) {
    this.input?.focus(options);
    this.handleFocus();
  }

  /** Removes focus from the checkbox. */
  blur() {
    this.input?.blur();
    this.handleBlur();
  }

  /** Checks for validity but doesn't report a validation message when invalid. */
  checkValidity() {
    return this.input?.checkValidity();
  }

  /** Gets the current validation message, if one exists. */
  get validationMessage() {
    return this.input?.validationMessage;
  }

  /** Checks for validity and shows the browser's validation message if the control is invalid. */
  reportValidity() {
    this.hadFocus = true;
    return this.input?.reportValidity();
  }

  /** Sets a custom validation message. If `message` is not empty, the field will be considered invalid. */
  setCustomValidity(message: string) {
    this.hadFocus = true;
    this.input?.setCustomValidity(message);
    this.invalid = !this.input?.checkValidity();
  }

  private handleClick() {
    this.checked = !this.checked;
    this.indeterminate = false;
    this.emit('he-change');
  }

  private handleBlur() {
    this.hasFocus = false;
    this.hadFocus = true;
    this.emit('he-blur');
  }

  @watch('disabled', { waitUntilFirstUpdate: true })
  handleDisabledChange() {
    // Disabled form controls are always valid, so we need to recheck validity when the state changes
    this.input!.disabled = this.disabled;
    this.invalid = !this.input!.checkValidity();

    if (this.disabled && !this._disabledByCheckboxGroup) {
      this._originallyDisabled = true;
    }
  }

  private handleFocus() {
    this.hasFocus = true;
    this.emit('he-focus');
  }

  @watch('checked', { waitUntilFirstUpdate: true })
  @watch('indeterminate', { waitUntilFirstUpdate: true })
  handleStateChange() {
    this.invalid = !this.input?.checkValidity();
  }

  render() {
    return html`
      <div
        part="form-control"
        class=${classMap({
          'form-control': true,
          'form-control--group-item': true,
          'form-control--has-error': this.invalid,
          'form-control--has-interaction': this.hadFocus,
          'form-control--has-label': this.label || this.hasSlotController.test('[default]'),
        })}
      >
        <label
          part="base"
          class=${classMap({
            checkbox: true,
            'checkbox--checked': this.checked,
            'checkbox--disabled': this.disabled,
            'checkbox--focused': this.hasFocus,
            'checkbox--indeterminate': this.indeterminate,
          })}
        >
          <input
            class="checkbox__input"
            type="checkbox"
            name=${ifDefined(this.name)}
            value=${ifDefined(this.value)}
            .indeterminate=${live(this.indeterminate)}
            ?checked=${live(this.checked)}
            ?disabled=${this.disabled}
            ?required=${this.required}
            ?autofocus=${this.autofocus}
            ?readonly=${this.readonly}
            aria-checked=${this.checked ? 'true' : 'false'}
            aria-describedby="error-text"
            @click=${this.handleClick}
            @blur=${this.handleBlur}
            @focus=${this.handleFocus}
          />

          <span part="control-label-wrapper" class="checkbox__control-label-wrapper">
            <span part="control" class="checkbox__control">
              ${this.checked
                ? html`
                    <svg
                      part="checked-icon"
                      class="checkbox__icon checkbox__icon--checked"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 2048 2048"
                      fill="currentColor"
                    >
                      <path d="M1837 557L768 1627l-557-558 90-90 467 466 979-978 90 90z" />
                    </svg>
                  `
                : ''}
              ${!this.checked && this.indeterminate
                ? html`
                    <svg
                      part="indeterminate-icon"
                      class="checkbox__icon checkbox__icon--indeterminate"
                      viewBox="0 0 20 20"
                    >
                      <rect fill="currentColor" x="5" y="5" width="10" height="10" rx="1" fill-rule="evenodd" />
                    </svg>
                  `
                : ''}
            </span>

            <span part="label" class="checkbox__label form-control--group-item__label">
              <slot>${this.label}</slot>
            </span>
          </span>
        </label>

        <div
          part="form-control-error-text"
          id="error-text"
          class="form-control__error-text"
          aria-live="assertive"
          role="alert"
        ></div>
      </div>
    `;
  }
}

export default Checkbox;
