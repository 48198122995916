export const CookieKeys = {
  CorrelationId: 'CorrelationId',
  SessionId: 'Session-Id',
  Locale: 'MarketplaceSelectedLocale',
  FDType: 'FDType',
};

export const CustomEvents = {
  ApiRequest: 'CustomEvent: ApiRequest',
  ApiResponse: 'CustomEvent: ApiResponse',
  PromiseStoreUsed: 'CustomEvent: PromiseStoreUsed',
  TokenAccess: 'CustomEvent: TokenAccess',
  VersionPickerUsed: 'CustomEvent: VersionPickerUsed',
  VersionPickerFallback: 'CustomEvent: VersionPickerFallback',
};

export const ErrorNames = {
  UnexpectedErrorFetch: 'unexpected-error-fetch',
  RetriesExhausted: 'unexpected-error-fetch-retries-exhausted',
};
