export default {
  "ai_disclaimer": "AI-generated content may be incorrect",
  "back": "Back",
  "_back.comment": "Used to label buttons and links to navigate to previous page or state",
  "breadcrumb": "Breadcrumb",
  "_breadcrumb.comment": "labels a breadcrumb navigation component",
  "citation": "Citation {0}",
  "clear": "Clear",
  "clear_entry": "Clear entry",
  "close": "Close",
  "close_message": "Close message",
  "collapse_task_menu": "Collapse task menu",
  "collapse": "Collapse",
  "commands": "Commands",
  "complete": "Complete",
  "data_view": "Data view",
  "day": "Day",
  "dislike": "Dislike",
  "error": "Error",
  "expand_task_menu": "Expand task menu",
  "expand": "Expand",
  "filter": "Filter",
  "finish": "Finish",
  "first": "First",
  "hide_password": "Hide password",
  "last": "Last",
  "like": "Like",
  "loading": "Loading...",
  "month": "Month",
  "more_options": "More Options",
  "navigation": "Navigation",
  "next": "Next",
  "next_month": "Next month",
  "not_started": "Not started",
  "no_results_found": "No results found",
  "off": "Off",
  "on": "On",
  "open": "Open",
  "open_in_new_window": "Open in new window",
  "options": "Options",
  "page": "Page {0}",
  "pagination": "Pagination",
  "plus_x_more": "+{0} more",
  "previous": "Previous",
  "previous_month": "Previous month",
  "progress": "In progress",
  "quick_start": "Quick start",
  "range_error_message": "The date you have selected is not within the date range.",
  "reference": "Reference",
  "references": "References",
  "refresh_suggestions": "Refresh suggestions",
  "remove": "Remove",
  "required_error_message": "This field is required.",
  "results_found": "{0} results found",
  "save_as_image": "Save as image",
  "search": "Search",
  "send": "Send",
  "select_all": "Select all",
  "select_row": "Select row",
  "selected_options": "Selected options",
  "show_calendar": "Show calendar",
  "show_less": "Show less",
  "show_more": "Show more ({0})",
  "show_password": "Show password",
  "skip_to_main": "Skip to main content",
  "step_x_of_y": "Step {0} of {1}",
  "suggested_responses": "Suggested responses",
  "today": "Today",
  "toggle_reference": "Toggle reference {0}",
  "unavailable_date_error_message": "The date you have selected is unavailable.",
  "warning": "Warning",
  "year": "Year",
  "min_checked_error_message": "At least {0} checkboxes must be checked.",
  "max_checked_error_message": "A maximum of {0} checkboxes must be checked."
}
