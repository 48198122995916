import { getTabbableBoundary } from '../utilities/tabbable';

let activeModals: HTMLElement[] = [];

export default class Modal {
  element: HTMLElement;
  tabDirection: 'forward' | 'backward' = 'forward';
  skip?: string;
  tabbableBoundary?: {
    start: HTMLElement | null;
    end: HTMLElement | null;
  };

  constructor(element: HTMLElement, skipSelector?: string) {
    this.element = element;
    this.skip = skipSelector;
    this.handleFocusIn = this.handleFocusIn.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  activate() {
    activeModals.push(this.element);
    document.addEventListener('focusin', this.handleFocusIn);
    document.addEventListener('keydown', this.handleKeyDown);
  }

  deactivate() {
    activeModals = activeModals.filter(modal => modal !== this.element);
    document.removeEventListener('focusin', this.handleFocusIn);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  isActive() {
    // The "active" modal is always the most recent one shown
    return activeModals[activeModals.length - 1] === this.element;
  }

  handleFocusIn(event: Event) {
    const path = event.composedPath();
    const skipElement = this.skip ? this.element.querySelector(this.skip) : undefined;

    // Trap focus so it doesn't go out of the modal's boundary
    if (this.isActive() && (!path.includes(this.element) || (skipElement && path.includes(skipElement)))) {
      if (!this.tabbableBoundary) this.tabbableBoundary = getTabbableBoundary(this.element, this.skip);
      const { start, end } = this.tabbableBoundary;

      const target = this.tabDirection === 'forward' ? start : end;

      if (typeof target?.focus === 'function') {
        target.focus({ preventScroll: true });
      }
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    // Quick hack to determine tab direction
    if (event.key !== 'Tab') {
      return;
    }

    if (event.shiftKey) {
      this.tabDirection = 'backward';
      // If we use 0, this triggers too fast in Firefox and gets the tab position stuck.
      setTimeout(() => (this.tabDirection = 'forward'), 2);
    } else {
      this.tabDirection = 'forward';
    }
  }
}
