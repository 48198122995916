import { AuthWorkspace } from '../connectors/auth-workspace-connector/types/workspace';
import { localizedValue } from '../connectors/localization-connector/localization-connector';

export const localizeWorkspaces = async (workspaces: AuthWorkspace[]) => {
  workspaces.forEach(async workspace => {
    if (workspace.workspaceId === 'home' || workspace.workspaceId.startsWith('home-')) {
      workspace.workspaceName = (await localizedValue(`workspace.home`)) ?? workspace.workspaceName;
    }
    workspace.workspaceName = (await localizedValue(`workspace.${workspace.workspaceId}`)) ?? workspace.workspaceName;
  });

  return workspaces;
};
