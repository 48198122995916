export default {
  "back": "Tagasi",
  "breadcrumb": "Lingirida",
  "clear": "Tühjenda",
  "clear_entry": "Kustuta kirje",
  "close": "Sule",
  "collapse_task_menu": "Ahenda ülesandemenüü",
  "collapse": "Ahenda",
  "commands": "Käsud",
  "complete": "Lõpetatud",
  "day": "Päev",
  "dislike": "Ei meeldi",
  "error": "Tõrge",
  "expand_task_menu": "Laienda ülesandemenüü",
  "expand": "Laienda",
  "filter": "Filter",
  "finish": "Lõpetage",
  "first": "Esimene",
  "go_to_page": "Ava leht {0}",
  "hide_password": "Peida parool",
  "last": "Viimane",
  "like": "Meeldib",
  "loading": "Laadimine …",
  "month": "Kuu",
  "more_options": "Rohkem suvandeid",
  "navigation": "Navigeerimine",
  "next": "Edasi",
  "next_month": "Järgmine kuu",
  "not_started": "Pole alustatud",
  "off": "Väljas",
  "on": "Sees",
  "open_in_new_window": "Ava uues aknas",
  "options": "Suvandid",
  "pagination": "Lehekülgjaotus",
  "plus_x_more": "ja veel {0}",
  "previous": "Eelmine",
  "previous_month": "Eelmine kuu",
  "progress": "Pooleli",
  "quick_start": "Kiirjuhend",
  "range_error_message": "Valitud kuupäev ei jää kuupäevavahemikku",
  "required_error_message": "Väli on nõutud",
  "results_found": "Leiti {0} tulem(it)",
  "search": "Otsing",
  "select_all": "Vali kõik",
  "select_row": "Vali rida",
  "show_calendar": "Kuva kalender",
  "show_password": "Kuva parool",
  "step_x_of_y": "Etapp {0}/{1}",
  "today": "Täna",
  "unavailable_date_error_message": "Valitud kuupäev pole saadaval",
  "warning": "Hoiatus",
  "year": "Aasta"
}