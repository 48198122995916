import * as icon from '../components/icon/icon';

/** Convert slotted content to a string. */
export function slottedContentToString(slottedContent?: Node[]) {
  if (slottedContent == null) return '';
  if (slottedContent.length <= 0) return '';

  return slottedContent
    .map(el => el.textContent?.trim() || '')
    .join(' ')
    .trim();
}

/** Determine if the only visible slotted content is an icon. */
export function isIconOnly(nodes: Node[]): boolean {
  const visibleElements = nodes
    .filter(whitespaceFilter)
    .filter(
      element =>
        !(element instanceof HTMLElement) ||
        (element instanceof HTMLElement && !element.classList.contains('he-visually-hidden') && !element.hidden)
    );

  const nonIconElements = visibleElements.filter(
    element => !(element instanceof SVGElement) && !(element instanceof icon.Icon)
  );

  // if there is 0 or 1 total visible elements and no non-icon elements, element is icon-only
  return visibleElements.length < 2 && nonIconElements.length === 0;
}

/** A method to filter out any whitespace-only nodes. */
export function whitespaceFilter(value: Node, index: number, array: Node[]) {
  return value.nodeType !== Node.TEXT_NODE
    ? true
    : typeof value.nodeValue === 'string' && !!value.nodeValue.trim().length;
}
