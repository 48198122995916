export default {
  "back": "Précédent",
  "breadcrumb": "Barre de navigation",
  "clear": "Effacer",
  "clear_entry": "Effacer l’entrée",
  "close": "Fermer",
  "collapse_task_menu": "Réduire le menu des tâches",
  "collapse": "Réduire",
  "commands": "Commandes",
  "complete": "Terminé",
  "day": "Jour",
  "dislike": "Je n’aime pas",
  "error": "Erreur",
  "expand_task_menu": "Développer le menu des tâches",
  "expand": "Développer",
  "filter": "Filtrer",
  "finish": "Terminer",
  "first": "Premier",
  "go_to_page": "Accéder à la page {0}",
  "hide_password": "Masquer le mot de passe",
  "last": "Dernier",
  "like": "Comme",
  "loading": "Chargement en cours...",
  "month": "Mois",
  "more_options": "Plus d'options",
  "navigation": "Navigation",
  "next": "Suivant",
  "next_month": "Mois suivant",
  "not_started": "Non démarré",
  "off": "Désactivé",
  "on": "Activé",
  "open_in_new_window": "Ouvrir dans une nouvelle fenêtre",
  "options": "Options",
  "pagination": "Pagination",
  "plus_x_more": "+{0} de plus",
  "previous": "Précédent",
  "previous_month": "Mois précédent",
  "progress": "En cours",
  "quick_start": "Démarrage rapide",
  "range_error_message": "La date sélectionnée n’est pas comprise dans la plage de dates",
  "required_error_message": "Ce champ est obligatoire",
  "results_found": "{0} résultats trouvés",
  "search": "Rechercher",
  "select_all": "Tout sélectionner",
  "select_row": "Sélectionner une ligne",
  "show_calendar": "Afficher le calendrier",
  "show_password": "Afficher le mot de passe",
  "step_x_of_y": "Étape {0} sur {1}",
  "today": "Aujourd’hui",
  "unavailable_date_error_message": "La date sélectionnée n’est pas disponible",
  "warning": "Avertissement",
  "year": "Année"
}