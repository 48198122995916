export default {
  "back": "Nazaj",
  "breadcrumb": "Sledenje",
  "clear": "Počisti",
  "clear_entry": "Počisti vnos",
  "close": "Zapri",
  "collapse_task_menu": "Strni meni opravil",
  "collapse": "Strni",
  "commands": "Ukazi",
  "complete": "Dokončano",
  "day": "Dan",
  "dislike": "Ni mi všeč",
  "error": "Napaka",
  "expand_task_menu": "Razširi meni opravil",
  "expand": "Razširi",
  "filter": "Filtriranje",
  "finish": "Dokončaj",
  "first": "Prvi",
  "go_to_page": "Pojdi na stran {0}",
  "hide_password": "Skrij geslo",
  "last": "Zadnji",
  "like": "Všeč mi je",
  "loading": "Nalaganje ...",
  "month": "Mesec",
  "more_options": "Več možnosti",
  "navigation": "Krmarjenje",
  "next": "Naprej",
  "next_month": "Naslednji mesec",
  "not_started": "Se ni začelo",
  "off": "Izklopljeno",
  "on": "Vklopljeno",
  "open_in_new_window": "Odpri v novem oknu",
  "options": "Možnosti",
  "pagination": "Oštevilčevanje strani",
  "plus_x_more": "Še {0}",
  "previous": "Nazaj",
  "previous_month": "Prejšnji mesec",
  "progress": "V teku",
  "quick_start": "Hiter začetek",
  "range_error_message": "Datum, ki ste ga izbrali, ni v datumskem obsegu",
  "required_error_message": "To polje je obvezno",
  "results_found": "Najdenih je bilo toliko rezultatov: {0}",
  "search": "Iskanje",
  "select_all": "Izberi vse",
  "select_row": "Izberite vrstico",
  "show_calendar": "Pokaži koledar",
  "show_password": "Pokaži geslo",
  "step_x_of_y": "Korak {0} od {1}",
  "today": "Danes",
  "unavailable_date_error_message": "Datum, ki ste ga izbrali, ni na voljo",
  "warning": "Opozorilo",
  "year": "Leto"
}