// @ts-ignore
import defaultTranslation from '../translations/modules/en.js';
// @ts-ignore
import locales from '../translations/modules/_manifest.js';
import HarmonyElement from '../components';
import type { ReactiveController, ReactiveControllerHost } from 'lit';

export class LocalizeController implements ReactiveController {
  private host: ReactiveControllerHost & HarmonyElement;
  private _translations: any;
  private _lang: string;
  private _isFetchingTranslation = false;

  constructor(host: ReactiveControllerHost & HarmonyElement) {
    (this.host = host).addController(this);
    this.setTranslations();
  }

  hostConnected() {
    //
  }

  hostDisconnected(): void {
    this.host?.removeController(this);
  }

  hostUpdate(): void {
    if (!this.host || !document || !document.documentElement) {
      // This case should not happen, it means the host has been torn down already, so lets not proceed.
      return;
    }
    this.checkLangForReset();
  }

  /**
   *
   * @param key the key for the translated term
   * @param params an array of values to populate the term placeholders
   * @returns localized term (string)
   */
  public term(key: string, params?: Array<string | number>): string {
    return this.localizeTerm(key, params) as string;
  }

  /**
   *
   * @param key the key for the translated term
   * @param params an array of values to populate the term placeholders
   * @returns localized term (string)
   */
  private localizeTerm(key: string, params?: Array<string | number>): string | void {
    if (!this._translations) {
      return '';
    }

    let term: string = this._translations[key];

    if (term && params?.length) {
      params.forEach((x, i) => (term = term.split(`{${i}}`).join(x?.toString())));
    }

    return term || '';
  }

  /**
   * Updates cached translations
   */
  private async setTranslations() {
    if (this._lang && this.host.lang === this._lang) {
      return;
    }

    if (this.host.lang?.startsWith('en')) {
      this._translations = defaultTranslation;
      this._lang = this.host.lang;
      return;
    }

    if (this._isFetchingTranslation) {
      return;
    }

    this._isFetchingTranslation = true;

    const locale = locales[this.host.lang];
    this._translations = locale ? (await locale())?.default : defaultTranslation;
    this._lang = this.host.lang;
    this._isFetchingTranslation = false;
    this.host.requestUpdate();
  }

  private checkLangForReset() {
    if (this.host.lang === this._lang) {
      return;
    }

    this.setTranslations();
  }
}
