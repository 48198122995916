import { CustomEvents } from '../../helpers/constant';
import { trackError, trackEvent } from '../../services/telemetry-service/library-telemetry-service';
import { ErrorInformation } from '../../services/telemetry-service/types/error-information';
import { VersionPicker } from '../../utilities/version-picker';
import { AuthWorkspace, transformAuthWorkspace } from '../auth-workspace-connector/types/workspace';
import { masterWorkspaceListConnector } from '../master-workspace-list-connector/master-workspace-list-connector';
import { ICurrentWorkspaceConnector } from './i-current-workspace-connector';

let pathUsedForCurrentWorkspace: string | undefined = undefined;
let workspaceInfoResponse: Promise<AuthWorkspace | undefined> | undefined = undefined;
let versionPicker: VersionPicker;

const FailureCurrentWorkspace = 'Failure while trying to retrieve current workspace';
const ErrorSource = 'CurrentWorkspaceConnector';
const ErrorIdentifier = 'current-workspace-failure';

/**
 * @description It is the current workspace connector that is responsible for the current workspace info to the client side.
 *
 * @since 0.0.0
 * @status experimental
 */

export const currentWorkspaceConnector: ICurrentWorkspaceConnector = async (forceNew: boolean = false): Promise<AuthWorkspace | undefined> => {
  // version picker
  if (!versionPicker) {
    versionPicker = VersionPicker.getInstance();
  }

  const versionPickerEventLog = {
    connector: 'currentWorkspaceConnector',
    scope: window.location.origin,
    href: window.location.href,
  };

  const library = await versionPicker?.loadShellLibrary();
  if (library?.currentWorkspaceConnectorCore) {
    trackEvent(CustomEvents.VersionPickerUsed, versionPickerEventLog);
    return library.currentWorkspaceConnectorCore(forceNew);
  } else {
    trackEvent(CustomEvents.VersionPickerFallback, versionPickerEventLog);
  }
  return currentWorkspaceConnectorCore(forceNew);
};

export const currentWorkspaceConnectorCore: ICurrentWorkspaceConnector = async (forceNew: boolean = false): Promise<AuthWorkspace | undefined> => {
  if (workspaceInfoResponse && !forceNew && pathUsedForCurrentWorkspace === window.location.pathname) {
    return workspaceInfoResponse;
  }

  try {
    const masterWorkspaceList = await masterWorkspaceListConnector(forceNew);
    if (!masterWorkspaceList) {
      workspaceInfoResponse = undefined;
      pathUsedForCurrentWorkspace = undefined;
      return undefined;
    }

    let workspaceInfo: AuthWorkspace | undefined = undefined;
    pathUsedForCurrentWorkspace = window.location.pathname;
    Object.keys(masterWorkspaceList.urls).forEach(url => {
      if (pathUsedForCurrentWorkspace!.indexOf(url) >= 0) {
        const workspaceId = masterWorkspaceList.urls[url];
        workspaceInfo = transformAuthWorkspace(masterWorkspaceList.workspaces[workspaceId]);
      }
    });
    workspaceInfoResponse = workspaceInfo;
  } catch (error: any) {
    const message: string = error?.message ?? FailureCurrentWorkspace;

    const errorInformation: ErrorInformation = {
      errorMessage: message,
      source: ErrorSource,
      statusCode: '500',
      errorObject: JSON.stringify(error) ?? '',
    };
    trackError(ErrorIdentifier, errorInformation);
  }

  return workspaceInfoResponse;
};
