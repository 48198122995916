import { css } from 'lit';
import componentStyles from '../../internal/styles/component.styles';

export default css`
  ${componentStyles}

  h1 {
    font-size: var(--he-font-size-3x-large);
    line-height: var(--he-line-height-3x-large);
    color: var(--he-color-neutral-900);
    margin: 0;
    padding: 0;
  }

  .title {
    font-weight: var(--he-font-weight-semibold);
  }

  .active-title,
  .divider {
    font-weight: var(--he-font-weight-normal);
  }

  .title,
  .active-title {
    /* this allows spans to change visual order in rtl */
    display: inline-block;
  }

  [hidden] {
    display: none;
  }

  ::slotted(.he-quick-nav) {
    margin: 0 calc(var(--he-spacing-x-small) * -1);
    --button-font-size: var(--he-font-size-3x-large);
    --button-line-height: var(--he-line-height-3x-large);
  }
`;
