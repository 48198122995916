export default {
  "back": "Înapoi",
  "breadcrumb": "Urmă",
  "clear": "Goliți",
  "clear_entry": "Goliți intrarea",
  "close": "Închidere",
  "collapse_task_menu": "Restrângeți meniul de activitate",
  "collapse": "Restrângere",
  "commands": "Comenzi",
  "complete": "Finalizați",
  "day": "Zi",
  "dislike": "Anulați aprecierea",
  "error": "Eroare",
  "expand_task_menu": "Extindeți meniul de activitate",
  "expand": "Extindere",
  "filter": "Filtrare",
  "finish": "Sfârșit",
  "first": "Primul",
  "go_to_page": "Accesați pagina {0}",
  "hide_password": "Ascundeți parola",
  "last": "Ultimele",
  "like": "Apreciați",
  "loading": "Se încarcă...",
  "month": "Lună",
  "more_options": "Mai multe opțiuni",
  "navigation": "Navigare",
  "next": "Următorul",
  "next_month": "Luna următoare",
  "not_started": "Neîncepute",
  "off": "Dezactivat",
  "on": "Activat",
  "open_in_new_window": "Deschideți într-o fereastră nouă",
  "options": "Opțiuni",
  "pagination": "Paginație",
  "plus_x_more": "+ încă {0}",
  "previous": "Anteriorul",
  "previous_month": "Luna anterioară",
  "progress": "În desfășurare",
  "quick_start": "Pornire rapidă",
  "range_error_message": "Data pe care ați selectat-o nu este în intervalul de date",
  "required_error_message": "Acest câmp este obligatoriu",
  "results_found": "{0} rezultate găsite",
  "search": "Căutare",
  "select_all": "Selectați toate",
  "select_row": "Selectați rândul",
  "show_calendar": "Afișați calendarul",
  "show_password": "Afișați parola",
  "step_x_of_y": "Pasul {0} din {1}",
  "today": "Azi",
  "unavailable_date_error_message": "Data selectată nu este disponibilă",
  "warning": "Avertisment",
  "year": "An"
}