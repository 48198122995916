/**
 * Custom Harmony Connector Errors. We need to ensure that problem error messages are displayed so that it is possible to
 * debug the issue with our partner teams as well as proactively detecting these issues on our end.
 */
export class HarmonyConnectorError extends Error {
  // Indicates which particular source is causing problem.
  public source: string;

  constructor(source: string, message: string) {
    super(message);

    this.name = 'HarmonyConnectorError';
    this.source = source;
  }
}
