export default {
  "back": "الخلف",
  "breadcrumb": "شريط عناوين",
  "clear": "مسح",
  "clear_entry": "مسح الإدخال",
  "close": "إغلاق",
  "collapse_task_menu": "طي قائمة المهام",
  "collapse": "طي",
  "commands": "الأوامر",
  "complete": "مكتمل",
  "day": "يوم",
  "dislike": "تسجيل عدم الإعجاب",
  "error": "خطأ",
  "expand_task_menu": "توسيع قائمة المهام",
  "expand": "توسيع",
  "filter": "عامل تصفية",
  "finish": "إنهاء",
  "first": "الأول",
  "go_to_page": "الانتقال إلى صفحة {0}",
  "hide_password": "إخفاء كلمة المرور",
  "last": "الأخير",
  "like": "تسجيل الإعجاب",
  "loading": "جارٍ التحميل...",
  "month": "شهر",
  "more_options": "خيارات إضافية",
  "navigation": "التنقل",
  "next": "التالي",
  "next_month": "الشهر القادم",
  "not_started": "لم يتم البدء",
  "off": "إيقاف التشغيل",
  "on": "تشغيل",
  "open_in_new_window": "فتح في نافذة جديدة",
  "options": "خيارات",
  "pagination": "فصل الصفحات",
  "plus_x_more": "و{0} من العناصر الإضافية",
  "previous": "السابق",
  "previous_month": "الشهر السابق",
  "progress": "قيد التقدم",
  "quick_start": "تشغيل سريع",
  "range_error_message": "التاريخ الذي حددته ليس ضمن نطاق التاريخ",
  "required_error_message": "يلزم ملء هذا الحقل",
  "results_found": "تم العثور على {0} من النتائج",
  "search": "بحث",
  "select_all": "تحديد الكل",
  "select_row": "تحديد صف",
  "show_calendar": "إظهار التقويم",
  "show_password": "إظهار كلمة المرور",
  "step_x_of_y": "الخطوة {0} من {1}",
  "today": "اليوم",
  "unavailable_date_error_message": "التاريخ الذي حددته غير متوفر",
  "warning": "تحذير",
  "year": "العام"
}