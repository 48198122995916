import { property, query, queryAsync, state } from 'lit/decorators.js';
import { html } from 'lit/static-html.js';
import { TemplateResult } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { styleMap } from 'lit/directives/style-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { getScope } from '../../utilities/scope';
import icon from '../icon/icon';
import checkbox from '../checkbox/checkbox';
import skeleton from '../skeleton/skeleton';
import dropdown, { Dropdown } from '../dropdown/dropdown';
import menu from '../menu/menu';
import menuItem from '../menu-item/menu-item';
import { Checkbox } from '../checkbox/checkbox';
import radio from '../radio/radio';
import HarmonyElement from '..';
import { watch } from '../../internal/watch';
import styles from './data-grid.styles';

export interface Column {
  field: string;
  content: string;
  sortable?: boolean;
  display?: {
    hideAt?: number;
    width?: string;
    minWidth?: string;
    maxWidth?: string;
    lineClamp?: number;
    noWrap?: boolean;
  };
}

export interface Row<CustomData = unknown> {
  id: string | number;
  cells: Record<string, string>;
  selected?: boolean;
  disabled?: boolean;
  customData?: CustomData;
}

export interface BulkSelectOption {
  name: string;
  content: string;
}

export interface SortBy {
  sortBy: string;
  isAscending: boolean;
}

/**
 * @tag he-data-grid
 * @since 5.0.0
 * @status stable
 * @design approved
 * @figma https://www.figma.com/file/dRwBPvZFZdYgWdAOCK375K/Harmony-Toolkit?node-id=687%3A18
 *
 * @dependency he-icon
 * @dependency he-checkbox
 * @dependency he-radio
 * @dependency he-skeleton
 * @dependency he-dropdown
 * @dependency he-menu
 * @dependency he-menu-item
 *
 * @slot data-grid-controls - This will position the command bar and search controls above the data-grid.
 * @slot pagination - This will pagination control below the data-grid.
 * @slot no-records - This is a placeholder for the content displayed when no items are displayed in the data-grid.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event {boolean} he-select-all-change - Emitted when "select all" checkbox is toggled".
 * @event {Row} he-row-select-change - Emitted when selectable row is toggled".
 * @event {SortBy} he-sort - Emitted when a sortable column header is clicked".
 * @event {string} he-bulk-select - Emitted when a bulk-select option is clicked".
 *
 * @csspart table-head - Allows for custom styles in data grid table heading.
 * @csspart table-body - Allows for custom styles in data grid table body.
 * @csspart no-records - Controls styles for empty records message container.
 */
export class DataGrid extends HarmonyElement {
  static styles = styles;
  static baseName = 'data-grid';
  static reactEvents = {
    onHeReady: new CustomEvent<true>('he-ready'),
    onHeSort: new CustomEvent<SortBy>('he-sort'),
    onHeRowSelectChange: new CustomEvent<Row>('he-row-select-change'),
    onHeSelectAllChange: new CustomEvent<true>('he-select-all-change'),
    onHeBulkSelect: new CustomEvent<string>('he-bulk-select'),
  };

  private scope = getScope(this);
  private resizeObserver: ResizeObserver;

  /** Fixes the table headings to the top of the screen when table is scrolled. */
  @property({ attribute: 'fixed-heading', type: Boolean })
  public fixedHeading: boolean = false;

  /** Fixes the first column to the left of the screen when table is scrolled. */
  @property({ attribute: 'fixed-column', type: Boolean })
  public fixedColumn: boolean = false;

  /** Puts table in a loading state. */
  @property({ type: Boolean })
  public loading: boolean = false;

  /** Shows checkbox or radio control on each row and a "select all" checkbox in header if 'multiple' is selected. */
  @property()
  public select?: 'single' | 'multiple';

  /** Field that data is sorted by. */
  @property({ attribute: 'sort-by' })
  public sortBy?: string;

  /** Indicates if field is sorted ascending. */
  @property({ attribute: 'sort-ascending', type: Boolean })
  public sortAscending: boolean = true;

  /** Toggles the "select all" checkbox when `select` is set to "multiple". */
  @property({ attribute: 'select-all', type: Boolean, reflect: true })
  public selectAll: boolean = false;

  /** Set the "select all" checkbox to indeterminate state. */
  @property({ attribute: 'select-all-indeterminate', type: Boolean, reflect: true })
  public selectAllIndeterminate: boolean = false;

  /** Used to provide screen readers with a label for the data grid. */
  @property({ reflect: true })
  public label?: string;

  @watch('selectAllIndeterminate', { waitUntilFirstUpdate: true })
  async handleSelectAllIndeterminateChange() {
    const checkbox = await this.selectAllCheckbox;
    if (!checkbox) return;
    checkbox.indeterminate = this.selectAllIndeterminate;
  }

  /** Turns off internal scrollbars. Use this if you want to handle overflow scrolling outside of the table. Make sure to handle both vertical and horizontal scrolling. */
  @property({ attribute: 'no-scroll', type: Boolean, reflect: true })
  public noScroll: boolean = false;

  /** Hide the "select all" checkbox. */
  @property({ attribute: 'hide-select-all', type: Boolean })
  public hideSelectAll: boolean = false;

  /** Enable this option to prevent the select options dropdown from being clipped when the component is placed inside a container with `overflow: auto|hidden|scroll`. */
  @property({ type: Boolean, attribute: 'fixed-placement', reflect: true }) fixedPlacement = false;

  /** The table columns and their configuration. */
  @property({ type: Object, attribute: false })
  public columns: Column[] = [];

  /** The table rows and their configuration. */
  @property({ type: Object, attribute: false })
  public rows: Row[] | unknown[] = [];

  @state()
  public bulkSelectOptions: BulkSelectOption[] = [];

  @state()
  private componentWidth?: number;

  @state()
  private columnCount: number = 0;

  @state()
  private isMultiSelect: boolean = false;

  @state()
  private isSingleSelect: boolean = false;

  @query('thead')
  private tableHead: HTMLTableSectionElement;

  @queryAsync('.data-grid__select-all')
  private selectAllCheckbox: Promise<Checkbox | null>;

  @query('.data-grid__bulk-select')
  private bulkSelectDropdown: Dropdown;

  /** Gets all rows that are currently selected. */
  get selectedRows(): Row[] {
    return this.rows.filter(x => (x as Row).selected) as Row[];
  }

  @watch('selectAll', { waitUntilFirstUpdate: true })
  async handleSelectAllChange() {
    if (this.isMultiSelect) {
      const checkbox = await this.selectAllCheckbox;

      if (!checkbox) {
        return;
      }

      checkbox.checked = this.selectAll;
      this.updateSelectedRowsBasedOnSelectAll();
    }
  }

  @watch('select')
  handleSelectChange() {
    this.isSingleSelect = this.select === 'single';
    this.isMultiSelect = this.select === 'multiple';
  }

  @watch('columns')
  handleColumnsChange() {
    this.initResizeObserver();
  }

  @watch('rows')
  async handleRowsChange() {
    if (!this.select) {
      return;
    }

    this.shadowRoot?.querySelectorAll('.data-grid__select-row').forEach((checkbox: Checkbox, index: number) => {
      const row = this.rows[index] as Row;
      if (row?.disabled === true) {
        return;
      }

      checkbox.checked = row?.selected || false;
      this.toggleGridRowSelection(checkbox);
    });

    const emptyRowCount = this.rows.length - this.selectedRows.length;

    if (emptyRowCount !== 0 && this.selectAll) {
      const checkbox = await this.selectAllCheckbox;
      if (checkbox) {
        checkbox.indeterminate = true;
        checkbox.checked = false;
      }
    }
  }

  constructor() {
    super();
    this.scope.registerComponent(icon, checkbox, radio, skeleton, dropdown, menu, menuItem);
  }

  disconnectedCallback() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this);
      this.resizeObserver.disconnect();
    }
    super.disconnectedCallback();
  }

  private handleSort(field: string) {
    if (field === this.sortBy) {
      this.sortAscending = !this.sortAscending;
    } else {
      this.sortBy = field;
      this.sortAscending = true;
    }

    this.emitSort();
  }

  private handleBulkSelect(name: string) {
    this.emit('he-bulk-select', { detail: name });
    this.bulkSelectDropdown.hide();
  }

  private async handleSelectAll(e: Event) {
    const key = (e as any).key;
    if (key && key !== ' ') {
      return;
    }

    const checkbox = e.target as Checkbox;
    await this.updateComplete;
    this.selectAll = checkbox.checked;
    this.toggleRows(checkbox.checked);
    this.emit('he-select-all-change', { detail: checkbox.checked });
  }

  private async handleSelectRow(row: Row, e: Event) {
    const checkbox = e.target as Checkbox;
    await this.updateComplete;

    if (!this.isMultiSelect) {
      this.toggleRowById(row.id);
    }

    if (this.isMultiSelect) {
      const selectedRow = this.rows.find((x: Row) => x.id === row.id) as Row;
      selectedRow.selected = checkbox.checked;
      this.toggleGridRowSelection(checkbox);
      const selectAllCheckbox = await this.selectAllCheckbox;
      if (selectAllCheckbox) {
        selectAllCheckbox.checked = this.selectAll;
      }
      if (this.selectedRows.length < this.rows.length || this.selectAllIndeterminate) {
        if (selectAllCheckbox) {
          selectAllCheckbox.indeterminate = true;
        }
        this.selectAll = false;
      }
    }

    this.emit('he-row-select-change', { detail: row });
  }

  private updateSelectedRowsBasedOnSelectAll() {
    if (this.selectAll) {
      this.rows = this.rows.map((x: Row) => {
        if (!x.disabled) {
          x.selected = true;
        }
        return x;
      });
    }
  }

  private emitSort() {
    const sortBy: SortBy = {
      sortBy: this.sortBy as string,
      isAscending: this.sortAscending as boolean,
    };

    this.emit('he-sort', { detail: sortBy });
  }

  private toggleRows(checked: boolean) {
    this.toggleRowData(checked);
    this.toggleRowCheckboxes(checked);
  }

  private toggleRowData(checked: boolean) {
    this.rows = this.rows.map((row: Row) => {
      if (!row.disabled) {
        row.selected = checked;
      }
      return row;
    });
  }

  private toggleRowById(id: string | number) {
    this.rows = this.rows.map((row: Row) => {
      if (!row.disabled) {
        row.selected = row.id === id;
      }

      return row;
    });
  }

  private toggleRowCheckboxes(checked: boolean) {
    this.shadowRoot?.querySelectorAll('.data-grid__select-row').forEach((checkbox: Checkbox) => {
      if (checkbox.disabled) {
        return;
      }

      checkbox.checked = checked;
      this.toggleGridRowSelection(checkbox);
    });
  }

  private toggleGridRowSelection(checkbox: Checkbox) {
    checkbox.parentElement?.parentElement?.parentElement?.setAttribute('aria-selected', checkbox.checked.toString());
  }

  private initResizeObserver() {
    this.columnCount = this.columns?.length;
    const hasResponsiveColumns = this.columns && this.columns.some(x => x?.display?.hideAt);
    if (!hasResponsiveColumns) {
      return;
    }

    this.resizeObserver = new ResizeObserver(() => {
      this.componentWidth = this.clientWidth;
      this.columnCount = this.columns.filter(
        x => !x?.display?.hideAt || (this.componentWidth as number) > x.display.hideAt
      ).length;
    });

    this.resizeObserver.observe(this);
  }

  render() {
    return html`
      <div
        class=${classMap({
          'data-grid': true,
          'data-grid--fixed-heading': this.fixedHeading,
          'data-grid--fixed-column': this.fixedColumn,
          'data-grid--no-scroll': this.noScroll,
        })}
      >
        <div class="data-grid__controls">
          <slot name="data-grid-controls"></slot>
        </div>

        <div class="data-grid__responsive-table he-responsive-table">
          <table
            role="grid"
            aria-rowcount="${this.rows.length}"
            aria-colcount="${this.columnCount}"
            aria-busy="${this.loading}"
            aria-multiselectable="${this.isMultiSelect}"
            aria-label=${ifDefined(this.label)}
            class=${classMap({
              'data-grid__table': true,
              'he-table': true,
              'he-table--hover': true,
              'he-table--fixed-heading': this.fixedHeading,
              'he-table--fixed-column': this.fixedColumn,
            })}
          >
            <thead role="rowgroup" part="table-head">
              <tr role="row">
                ${this.columns.map((x, i) => this.headingTemplate(x, i))}
              </tr>
            </thead>
            <tbody role="rowgroup" part="table-body">
              ${this.rows.map((x: Row) => this.rowTemplate(x))}
            </tbody>
          </table>
        </div>

        <div class="data-grid__no-records" aria-live="polite">
          ${!this.rows || this.rows.length === 0
            ? html`
                <div class="data-grid__no-records__wrapper" part="no-records">
                  <slot name="no-records"></slot>
                </div>
              `
            : ''}
        </div>

        <div class="data-grid__pagination">
          <slot name="pagination"></slot>
        </div>
      </div>
    `;
  }

  private loadingTemplate = () => html`
    <${this.scope.tag('skeleton')}
      class="data-grid__loading"
      shape="rect"
      shimmer
    >
    </${this.scope.tag('skeleton')}>
  `;

  private getHeadingContents = (index: number, selectAllCheckbox: TemplateResult) => {
    if (index !== 0) {
      return '';
    }

    if (this.isSingleSelect) {
      return html`<span class="data-grid__heading-selector-spacer"></span>`;
    }

    if (this.isMultiSelect) {
      return this.hideSelectAll ? html`<span class="data-grid__heading-selector-spacer"></span>` : selectAllCheckbox;
    }

    return '';
  };

  private headingTemplate = (column: Column, index: number) => {
    const sortIcon = html`
      <${this.scope.tag('icon')}
        name="${this.sortAscending ? 'sortup' : 'sortdown'}"
        class=${classMap({
          'data-grid__sort-icon': true,
          'data-grid__sort-icon--sorted': this.sortBy === column?.field,
        })}
      ></${this.scope.tag('icon')}>
    `;

    const sortButton = html`
      <button class="data-grid__sort" @click=${(event: MouseEvent) => this.handleSort(column?.field)}>
        ${column?.content} ${sortIcon}
      </button>
    `;

    const selectAllCheckbox = html`
      <${this.scope.tag('checkbox')}
        class="data-grid__selector data-grid__select-all"
        ?indeterminate=${this.selectAllIndeterminate}
        @keyup=${(event: KeyboardEvent) => this.handleSelectAll(event)}
        @he-change=${(event: MouseEvent) => this.handleSelectAll(event)}
      >
        <span class="visually-hidden">${this.localize.term('select_all')}</span>
      </${this.scope.tag('checkbox')}>
    `;

    const bulkOptionsDropdown = html`
      <${this.scope.tag('dropdown')} class="data-grid__bulk-select" ?fixed-placement=${this.fixedPlacement}>
        <button slot="trigger" class="data-grid__bulk-select__trigger" >
          <${this.scope.tag('icon')}
            name="chevrondown"
            label="${this.localize.term('more_options')}"
            class="data-grid__bulk-select__icon"
          >
            </${this.scope.tag('icon')}>
        </button>
        <${this.scope.tag('menu')}>
          ${this.bulkSelectOptions.map(
            x => html`
              <${this.scope.tag('menu-item')}
                class="data-grid__bulk-select__options"
                @click=${() => this.handleBulkSelect(x.name)}
              >
                ${x.content}
              </${this.scope.tag('menu-item')}>
            `
          )}
        </${this.scope.tag('menu')}>
      </${this.scope.tag('dropdown')}>
    `;

    return html`
      <th
        role="columnheader"
        class=${classMap({
          'data-grid__table-header': true,
          'data-grid__table-header--sortable': column?.sortable ? true : false,
        })}
        style=${styleMap({
          display:
            column?.display?.hideAt && column.display.hideAt >= (this.componentWidth as number) ? 'none' : 'table-cell',
          width: column?.display?.width,
          'min-width': column?.display?.minWidth,
          'max-width': column?.display?.maxWidth,
        })}
        aria-sort="${ifDefined(
          this.sortBy === column?.field ? (this.sortAscending ? 'ascending' : 'descending') : undefined
        )}"
      >
        ${this.loading && !column
          ? this.loadingTemplate()
          : html`
              <span class="data-grid__table-header__content">
                ${this.getHeadingContents(index, selectAllCheckbox)}
                ${index === 0 && this.isMultiSelect && this.bulkSelectOptions?.length ? bulkOptionsDropdown : ''}
                <span class="data-grid__column__content"> ${column.sortable ? sortButton : column.content} </span>
                <slot name="${column.field}-column-header" class="data-grid__table-header__slot"></slot>
              </span>
            `}
      </th>
    `;
  };

  private cellTemplate = (column: Column, row: Row, index: number) => {
    const checkbox = html`
      <${this.scope.tag('checkbox')}
        id="${row?.id}"
        class="data-grid__selector data-grid__select-row"
        aria-label="${this.localize.term('select_row')}"
        ?checked="${row?.selected}"
        ?disabled=${row?.disabled}
        @he-change="${(event: MouseEvent) => this.handleSelectRow(row, event)}"
      >
        <span class="visually-hidden">${this.localize.term('select_row')}</span>
      </${this.scope.tag('checkbox')}>
      ${this.select && this.bulkSelectOptions?.length ? html`<span class="data-grid__bulk-select__spacer"></span>` : ''}
    `;

    const radio = html`
      <${this.scope.tag('radio')}
        id="${row?.id}"
        class="data-grid__selector data-grid__select-row"
        aria-label="${this.localize.term('select_row')}"
        ?checked="${row?.selected}"
        ?disabled=${row?.disabled}
        @keydown="${(event: KeyboardEvent) => event.key === ' ' && event.preventDefault()}"
        @he-selected="${(event: CustomEvent) => this.handleSelectRow(row, event)}"
      </${this.scope.tag('radio')}>
    `;

    const cellData = row?.cells[column?.field] || '';
    const cellClasses = classMap({
      'data-grid__cell-content': true,
      'data-grid__line-clamp': column?.display?.lineClamp ? true : false,
    });
    const cellStyles = `display: ${
      column?.display?.hideAt && column?.display?.hideAt >= (this.componentWidth as number) ? 'none' : 'table-cell'
    }`;
    const cellContentStyles = styleMap({
      '-webkit-line-clamp': column?.display?.lineClamp?.toString(),
      'white-space': column?.display?.noWrap ? 'nowrap' : 'normal',
    });
    const cellContent = html`<span class="${cellClasses}" style="${cellContentStyles}">
      <slot name="${column?.field}-${row?.id}">${cellData}</slot>
    </span>`;

    return index === 0
      ? html`<th scope="row" role="rowheader" class="data-grid__row-header" style="${cellStyles}">
          <span class="data-grid__row-header__content">
            ${this.select === 'multiple' && !this.loading ? checkbox : ''}
            ${this.select === 'single' && !this.loading ? radio : ''}
            ${this.loading ? this.loadingTemplate() : cellContent}
          </span>
        </th>`
      : html`
          <td class="data-grid__cell" style=${cellStyles}>${this.loading ? this.loadingTemplate() : cellContent}</td>
        `;
  };

  private rowTemplate = (row: Row) => html`
    <tr
      role="row"
      class="data-grid__row"
      aria-selected="${ifDefined(this.select && !this.loading ? row.selected === true : undefined)}"
    >
      ${this.columns.map((x, i) => this.cellTemplate(x, row, i))}
    </tr>
  `;
}

export default DataGrid;
