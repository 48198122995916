import { css } from 'lit';
import componentStyles from '../../internal/styles/component.styles';
import table from '../../utilities/css/table.styles';
import { SystemColors } from '../../utilities/system-colors';

export default css`
  ${componentStyles}
  ${table}

  :host {
    display: block;
  }

  :host,
  .data-grid,
  .data-grid__responsive-table {
    height: 100%;
  }

  .data-grid__responsive-table {
    overflow: auto;
  }

  .data-grid--no-scroll .data-grid__responsive-table {
    overflow: visible;
  }

  .data-grid__controls {
    margin-bottom: var(--he-spacing-small);
  }

  .data-grid__table {
    min-width: 100%;
  }

  .data-grid__loading {
    border-radius: 4px;
    height: 16px;
    width: 100%;
  }

  .data-grid__sort {
    background-color: transparent;
    color: var(--he-type-primary);
    outline-offset: var(--he-spacing-2x-small);
    cursor: pointer;
    display: flex;
    align-items: baseline;
  }

  .data-grid__sort:hover .data-grid__sort-icon {
    visibility: visible;
  }

  .data-grid__sort-icon {
    font-size: var(--he-font-size-small);
    margin-inline-start: var(--he-spacing-x-small);
    visibility: hidden;
  }

  .data-grid__sort-icon--sorted {
    visibility: visible;
  }

  .data-grid__selector {
    margin: 0px;
    margin-inline-end: var(--he-spacing-medium);
  }

  .data-grid__tooltip-icon {
    margin-inline-start: var(--he-spacing-2x-small);
    color: var(--he-accent-foreground-rest);
  }

  .data-grid__tooltip-icon:focus-visible {
    outline: solid var(--he-focus-ring-width) var(--he-form-control-toggle-focus-ring-color);
    outline-offset: 2px;
  }

  .data-grid__table-header__content {
    display: flex;
    align-items: center;
    position: relative;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
  }

  .data-grid__column__content {
    font-weight: var(--he-font-weight-semibold);
  }

  .data-grid__row-header__content {
    display: flex;
    align-content: start;
  }

  .data-grid__table-header {
    font-weight: unset !important;
  }

  .data-grid__table-header__slot::slotted(*) {
    margin-top: -0.4em;
    margin-inline-start: var(--he-spacing-2x-small);
  }

  .data-grid__line-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .data-grid__no-records__wrapper {
    min-height: 150px;
    padding: var(--he-spacing-medium);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .data-grid__bulk-select {
    margin-inline-start: calc(var(--he-spacing-small) * -1);
    margin-inline-end: var(--he-spacing-2x-small);
  }

  .data-grid__bulk-select__trigger {
    background-color: transparent;
    color: var(--he-type-primary);
  }

  .data-grid__bulk-select__icon {
    padding: var(--he-spacing-2x-small);
    font-size: var(--he-font-size-medium);
  }

  .data-grid__bulk-select__trigger:focus-visible {
    outline: solid var(--he-focus-ring-width) var(--he-form-control-toggle-focus-ring-color);
    outline-offset: 2px;
  }

  .data-grid__bulk-select__options::part(content) {
    font-weight: var(--he-font-weight-normal);
  }

  .data-grid__bulk-select__spacer {
    width: var(--he-spacing-medium);
  }

  .data-grid__heading-selector-spacer {
    width: 20px;
    margin-inline-end: var(--he-spacing-medium);
  }

  /* High contrast */
  @media screen and (forced-colors: active) {
    .data-grid__sort,
    .data-grid__bulk-select__trigger {
      background-color: ${SystemColors.ButtonFace};
      color: ${SystemColors.ButtonText};
    }

    [class*='icon'] {
      fill: ${SystemColors.ButtonText};
    }
  }
`;
