export default {
  "back": "后退",
  "breadcrumb": "痕迹",
  "clear": "清除",
  "clear_entry": "清除条目",
  "close": "关闭",
  "collapse_task_menu": "折叠任务菜单",
  "collapse": "折叠",
  "commands": "命令",
  "complete": "完成",
  "day": "日",
  "dislike": "不喜欢",
  "error": "错误",
  "expand_task_menu": "展开任务菜单",
  "expand": "展开",
  "filter": "筛选",
  "finish": "完成",
  "first": "首值",
  "go_to_page": "转到第 {0} 页",
  "hide_password": "隐藏密码",
  "last": "末值",
  "like": "类似",
  "loading": "正在加载...",
  "month": "月",
  "more_options": "更多选项",
  "navigation": "导航",
  "next": "下一步",
  "next_month": "下个月",
  "not_started": "未开始",
  "off": "关",
  "on": "开",
  "open_in_new_window": "在新窗口中打开",
  "options": "选项",
  "pagination": "分页",
  "plus_x_more": "另外 {0} 个",
  "previous": "上一页",
  "previous_month": "上个月",
  "progress": "正在进行",
  "quick_start": "快速启动",
  "range_error_message": "你选择的日期不在日期范围内",
  "required_error_message": "此字段为必填项",
  "results_found": "找到 {0} 个结果",
  "search": "搜索",
  "select_all": "全选",
  "select_row": "选择行",
  "show_calendar": "显示日历",
  "show_password": "显示密码",
  "step_x_of_y": "第 {0} 步(共 {1} 步)",
  "today": "今天",
  "unavailable_date_error_message": "你选择的日期不可用",
  "warning": "警告",
  "year": "年"
}