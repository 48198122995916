import { css } from 'lit';
import componentStyles from '../../internal/styles/component.styles';

export default css`
  ${componentStyles}

  :host {
    display: block;
    box-sizing: content-box;
    height: 0;
    margin: var(--he-spacing-2x-small) 0;
    border: none;
    border-top: var(--he-form-control-border-width) solid var(--he-color-body-divider);
  }

  :host([orientation='vertical']) {
    height: 100%;
    margin: 0 var(--he-spacing-2x-small);
    border: none;
    border-left: var(--he-form-control-border-width) solid var(--he-color-body-divider);
  }
`;
