import { localizeWorkspaces } from '../../helpers/localization-helper';
import { httpRequestRetryStore } from '../../services/http-request-store';
import { HttpFunctions } from '../../services/http-service';
import { trackError } from '../../services/telemetry-service/library-telemetry-service';
import { ErrorInformation } from '../../services/telemetry-service/types/error-information';
import { ConnectorPromiseStore } from '../../services/types/window-connectors';
import { HarmonyConnectorError } from '../connector-error';
import { localeConnector } from '../locale-connector/locale-connector';
import { loadLocalizationResource } from '../localization-connector/localization-connector';
import { AuthWorkspace } from './types/workspace';

let authWorkspace: AuthWorkspace[] | undefined;
const authWorkspaceUrl = '/api/user/authworkspaceinfoFd';
const ErrorSource = 'AuthWorkspaceConnectorPartnerPortal';
const FailureAuthWorkspaceMessage = 'Failure while trying to retreive Auth Workspaces';

// This is the unique config Id for auth workspace connector. You need to change this if there is a change in design.
const CONFIG_ID = 'V1';

/**
 * @description It is the auth workspace connector that is responsible for providing list of authorized workspaces
 * on the client side in Partner Portal. (Related to Classic FD)
 *
 * @since 0.0
 * @status experimental
 */
export const authWorkspaceConnectorPartnerPortal = async (): Promise<AuthWorkspace[]> => {
  if (authWorkspace) {
    return authWorkspace;
  }

  const locale = localeConnector();
  const baseUrl = `${window.location.origin}/${locale}`;
  const url = `${baseUrl}${authWorkspaceUrl}`;
  const connectorPromiseStoreConfig: ConnectorPromiseStore = {
    connectorType: 'authWorkspace',
    uniqueId: url + CONFIG_ID,
  };

  try {
    const authWorkspacePromise = httpRequestRetryStore<AuthWorkspace[]>(connectorPromiseStoreConfig, url, HttpFunctions.GET, null, null, 'same-origin');

    // If we fail to get the resource file, we fallback to workspaceName from auth workspaces
    try {
      const resourcePromise = loadLocalizationResource();
      authWorkspace = await Promise.all([authWorkspacePromise, resourcePromise]).then(async values => {
        const authWorkspaceResponse = values[0];
        if (authWorkspaceResponse) {
          return await localizeWorkspaces(authWorkspaceResponse);
        }
      });
    }
    catch (error: any) {
      trackError('workspace-localization-failure', error);
    }
    
    if (authWorkspace) {
      return authWorkspace;
    }

    throw new Error('Undefined Auth Workspace Response');
  } catch (error: any) {
    const message: string = error?.message ?? FailureAuthWorkspaceMessage;

    const errorInformation: ErrorInformation = {
      errorMessage: message,
      source: ErrorSource,
      statusCode: '500',
      errorObject: JSON.stringify(error) ?? '',
    };
    trackError('auth-context-failure', errorInformation);
    throw new HarmonyConnectorError(ErrorSource, FailureAuthWorkspaceMessage);
  }
};
