export default {
  "back": "Ke belakang",
  "breadcrumb": "Jejak",
  "clear": "Kosongkan",
  "clear_entry": "Kosongkan entri",
  "close": "Tutup",
  "collapse_task_menu": "Runtuhkan menu tugas",
  "collapse": "Runtuh",
  "commands": "Perintah",
  "complete": "Selesai",
  "day": "Hari",
  "dislike": "Tidak suka",
  "error": "Ralat",
  "expand_task_menu": "Kembangkan menu tugas",
  "expand": "Kembangkan",
  "filter": "Penapis",
  "finish": "Selesai",
  "first": "Pertama",
  "go_to_page": "Pergi ke halaman {0}",
  "hide_password": "Sembunyikan kata laluan",
  "last": "Terakhir",
  "like": "Suka",
  "loading": "Memuatkan...",
  "month": "Bulan",
  "more_options": "Lebih Banyak Pilihan",
  "navigation": "Navigasi",
  "next": "Seterusnya",
  "next_month": "Bulan depan",
  "not_started": "Belum bermula",
  "off": "Mati",
  "on": "Hidup",
  "open_in_new_window": "Buka dalam tetingkap baharu",
  "options": "Opsyen",
  "pagination": "Penghalamanan",
  "plus_x_more": "+{0} lagi",
  "previous": "Sebelumnya",
  "previous_month": "Bulan lepas",
  "progress": "Sedang berjalan",
  "quick_start": "Mula cepat",
  "range_error_message": "Tarikh yang anda pilih tidak berada dalam julat tarikh",
  "required_error_message": "Medan ini diperlukan",
  "results_found": "{0} hasil ditemui",
  "search": "Cari",
  "select_all": "Pilih semua",
  "select_row": "Pilih baris",
  "show_calendar": "Tunjukkan kalendar",
  "show_password": "Tunjukkan kata laluan",
  "step_x_of_y": "Langkah {0} daripada {1}",
  "today": "Hari ini",
  "unavailable_date_error_message": "Tarikh yang anda pilih tidak tersedia",
  "warning": "Amaran",
  "year": "Tahun"
}