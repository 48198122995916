import { CustomEvents, ErrorNames } from '../helpers/constant';
import { trackError, trackEvent } from './telemetry-service/library-telemetry-service';
import { ErrorInformation } from './telemetry-service/types/error-information';

const LoggingSource = 'http-service';

export enum HttpFunctions {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

const timeout = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const sleep = async (fn: any, ...args: any) => {
  await timeout(1000);
  return fn(...args);
};

/**
 * It makes a fetch request using the provided information, it wil be making the request along with handling the retry behavior.
 * @param requestUrl Request Url
 * @param httpFunction Request Method
 * @param headers Headers to be passed while making the request
 * @param body Any Body that needs to be attached.
 * @param credentials
 * @param retriesLeft Determines the number of retries left.
 * @returns
 */
export const httpRequestRetry = async <T>(
  requestUrl: string,
  httpFunction: HttpFunctions,
  headers?: HeadersInit | null,
  body?: BodyInit | null,
  credentials?: RequestCredentials | null,
  retriesLeft: number = 3
): Promise<T | null | undefined> => {
  let response: Response | undefined;
  try {
    const requestInit: RequestInit = { method: 'GET' };
    if (httpFunction) {
      requestInit.method = httpFunction;
    }
    if (headers) {
      requestInit.headers = headers;
    }
    if (body) {
      requestInit.body = body;
    }
    if (credentials) {
      requestInit.credentials = credentials;
    }

    const eventLog = {
      method: httpFunction,
      url: requestUrl,
      headers: headers,
      body: body,
      credentials: credentials,
      retriesLeft: retriesLeft,
    };

    trackEvent(CustomEvents.ApiRequest, eventLog);

    response = await fetch(requestUrl, requestInit);

    if (response && response.ok) {
      /** Returning null for Responses with status Code as it is expected. */
      if (response.status === 204 || requestInit.method?.toUpperCase() === HttpFunctions.PUT) {
        return null;
      }

      let data = null;
      if (response.headers.get('Content-Type') === 'image/jpeg') {
        data = (await response.blob()) as unknown as T;
      } else {
        data = (await response.json()) as unknown as T;
      }

      if (!data) {
        const errorMessage = `Data undefined after getting the json from response, response status - ${response?.status ?? 'Undefined status'}`;
        throw new Error(errorMessage);
      }

      const eventLog = {
        method: httpFunction,
        url: requestUrl,
        headers: headers,
        data: data,
        status: response?.status ?? 'Response Status Undefined',
        retriesLeft: retriesLeft,
      };

      trackEvent(CustomEvents.ApiResponse, eventLog);

      return data;
    } else {
      const status = response.status;

      /** Retrying on Http Server Errors */
      if (status > 499) {
        if (retriesLeft > 1) {
          return await sleep(httpRequestRetry, requestUrl, httpFunction, headers, body, credentials, retriesLeft - 1);
        }
      }

      throw new Error('The Api call responded with a Bad Response');
    }
  } catch (error: any) {
    const errorMessage = error?.message ?? 'fetch-call-failed';
    const errorStack = error?.stack ?? 'No error Stack detected';

    error.statusCode = response?.status ? response.status.toString() : '500';

    const errorInformation: ErrorInformation = {
      errorMessage: errorMessage,
      errorStack: errorStack,
      errorObject: JSON.stringify(error),
      method: httpFunction,
      url: requestUrl,
      source: LoggingSource,
      statusCode: response?.status ? response.status.toString() : '500',
      headers: headers,
      retriesLeft: retriesLeft,
    };
    //Don't retry for 4xx
    if (response && response.status < 500) {
      trackError(ErrorNames.UnexpectedErrorFetch, errorInformation);
      throw error;
    }

    //Retry if possible
    if (retriesLeft > 1) {
      trackError(ErrorNames.UnexpectedErrorFetch, errorInformation);
      return await sleep(httpRequestRetry, requestUrl, httpFunction, headers, body, credentials, retriesLeft - 1);
    }

    //Retries exhausted
    if (retriesLeft === 1) {
      trackError(ErrorNames.RetriesExhausted, errorInformation);
      throw error;
    }
  }
};
