export default {
  "back": "Назад",
  "breadcrumb": "Шлях",
  "clear": "Очистити",
  "clear_entry": "Очистити запис",
  "close": "Закрити",
  "collapse_task_menu": "Згорнути меню завдань",
  "collapse": "Згорнути",
  "commands": "Команди",
  "complete": "Завершити",
  "day": "День",
  "dislike": "Не подобається",
  "error": "Помилка",
  "expand_task_menu": "Розгорнути меню завдань",
  "expand": "Розгорнути",
  "filter": "Фільтр",
  "finish": "Готово",
  "first": "Перш.",
  "go_to_page": "Перейдіть на сторінку {0}",
  "hide_password": "Приховати пароль",
  "last": "Останн.",
  "like": "Подобається",
  "loading": "Завантаження…",
  "month": "Місяць",
  "more_options": "Додаткові параметри",
  "navigation": "Навігація",
  "next": "Далі",
  "next_month": "Наступний місяць",
  "not_started": "Не розпочато",
  "off": "Вимкнути",
  "on": "Увімкнути",
  "open_in_new_window": "Відкрити в новому вікні",
  "options": "Параметри",
  "pagination": "Розбиття на сторінки",
  "plus_x_more": "Ще {0}",
  "previous": "Назад",
  "previous_month": "Попередній місяць",
  "progress": "Виконується",
  "quick_start": "Швидкий початок",
  "range_error_message": "Вибрана дата виходить за межі проміжку часу",
  "required_error_message": "Потрібно заповнити це поле.",
  "results_found": "Знайдено результатів: {0}",
  "search": "Пошук",
  "select_all": "Вибрати все",
  "select_row": "Вибрати рядок",
  "show_calendar": "Показати календар",
  "show_password": "Показати пароль",
  "step_x_of_y": "Крок {0} з {1}",
  "today": "Сьогодні",
  "unavailable_date_error_message": "Вибрана дата недоступна",
  "warning": "Попередження",
  "year": "Рік"
}