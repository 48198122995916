import { css } from 'lit';
import componentStyles from '../../internal/styles/component.styles';
import { SystemColors } from '../../utilities/system-colors';

export default css`
  ${componentStyles}

  :host {
    --x-padding: var(--he-spacing-x-small);
    --icon-slot-size: 32px;

    position: relative;
    display: block;
    overflow: visible;
    outline: none;
    height: var(--he-menu-control-height);
    padding: 0;
    margin: 0;
    white-space: nowrap;
    color: var(--he-neutral-foreground);
    fill: currentColor;
    cursor: pointer;
    font-size: var(--he-font-size-medium);
    line-height: var(--he-line-height-normal);
    user-select: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .menu-item {
    display: grid;
    grid-template-columns: minmax(var(--x-padding), auto) auto 1fr minmax(var(--x-padding), auto) auto;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    height: 100%;
  }

  .menu-item--indent-1 {
    grid-template-columns: var(--icon-slot-size) auto 1fr minmax(var(--x-padding), auto) auto;
  }

  .menu-item--indent-2 {
    grid-template-columns: var(--icon-slot-size) var(--icon-slot-size) 1fr minmax(var(--x-padding), auto) auto;
  }

  :host(:focus-visible) .menu-item {
    box-shadow: inset var(--he-focus-ring);
  }

  :host(:hover:not([disabled])) {
    background-color: var(--he-neutral-fill-stealth-hover);
  }

  :host(:active:not([disabled])),
  :host([expanded]) {
    background-color: var(--he-neutral-fill-stealth-active);
  }

  :host([disabled]) {
    cursor: 'not-allowed';
    opacity: var(--he-form-control-disabled-opacity);
  }

  :host([disabled]:hover) .start,
  :host([disabled]:hover) .end,
  :host([disabled]:hover)::slotted(svg) {
    fill: currentColor;
  }

  .content {
    grid-column-start: 3;
    justify-self: start;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .start,
  .end {
    display: flex;
    justify-content: center;
    pointer-events: none;
  }

  .start {
    color: var(--he-accent-foreground-rest);
  }

  .end {
    color: var(--he-type-secondary);
  }

  .menu-item--has-end .end {
    min-width: var(--icon-slot-size);
    margin-inline-start: var(--he-spacing-2x-small);
  }

  .expand-collapse-glyph-container {
    min-width: var(--icon-slot-size);
    justify-content: center;
    pointer-events: none;
  }

  ::slotted([slot='expand-collapse-indicator']),
  .expand-collapse-glyph {
    font-size: var(--he-chevron-size);
    fill: var(--he-color-neutral-600);
    color: var(--he-color-neutral-600);
  }

  .menu-item--rtl .expand-collapse-glyph {
    transform: rotate(180deg);
  }

  :host(:hover) .start,
  :host(:hover)::slotted(.he-icon[slot='start']) {
    color: var(--he-accent-foreground-hover);
  }

  :host(:active) .start,
  :host(:active)::slotted(.he-icon[slot='start']) {
    color: var(--he-accent-foreground-active);
  }

  ::slotted(svg) {
    width: var(--he-icon-size);
    height: var(--he-icon-size);
    display: flex;
  }

  ::slotted(.he-icon) {
    contain: size;
  }

  :host(:hover)::slotted(svg[slot='start']) {
    fill: var(--he-accent-foreground-hover);
  }

  :host(:active)::slotted(svg[slot='start']) {
    fill: var(--he-accent-foreground-active);
  }

  .input-container,
  .expand-collapse-glyph-container {
    display: none;
  }

  :host([role='menuitemcheckbox']) .input-container,
  :host([role='menuitemradio']) .input-container {
    display: grid;
  }

  .menu-item--has-submenu .expand-collapse-glyph-container {
    display: grid;
  }

  .menu-item--has-submenu .end,
  :host([role='menuitemcheckbox']) .end,
  :host([role='menuitemradio']) .end {
    grid-column-start: 4;
  }

  .expand-collapse,
  .checkbox,
  .radio {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    outline: none;
  }

  .radio {
    border-radius: 999px;
    position: relative;
    background-color: var(--he-color-surface-background);
    border: var(--he-form-control-border-width) solid var(--he-color-neutral-600);
  }

  :host(:hover) .radio {
    border-color: var(--he-color-neutral-700);
  }

  :host(:active) .radio {
    border-color: var(--he-color-neutral-800);
  }

  .checkbox-indicator,
  .radio-indicator,
  ::slotted([slot='checkbox-indicator']),
  ::slotted([slot='radio-indicator']),
  ::slotted([slot='expand-collapse-indicator']) {
    display: none;
  }

  :host([aria-checked='true']) .checkbox-indicator,
  :host([aria-checked='true']) ::slotted([slot='checkbox-indicator']) {
    width: var(--he-icon-size);
    height: var(--he-icon-size);
    display: block;
    fill: var(--he-color-neutral-800);
    color: var(--he-color-neutral-800);
    pointer-events: none;
  }

  :host([aria-checked='true']) .radio {
    border-color: var(--he-accent-fill-rest);
  }

  :host([aria-checked='true']:hover) .radio {
    border-color: var(--he-accent-fill-hover);
  }

  :host([aria-checked='true']:active) .radio {
    border-color: var(--he-accent-fill-active);
  }

  :host([aria-checked='true']) .radio-indicator {
    position: absolute;
    inset: 4px;
    border-radius: 999px;
    display: inline-block;
    background: var(--he-accent-fill-rest);
    pointer-events: none;
  }

  :host([aria-checked='true']:hover) .radio-indicator {
    background: var(--he-accent-fill-hover);
  }

  :host([aria-checked='true']:active) .radio-indicator {
    background: var(--he-accent-fill-active);
  }

  :host([aria-checked='true']) ::slotted([slot='radio-indicator']) {
    display: block;
    pointer-events: none;
  }

  :host(:not([disabled]):hover) .menu-item--quicknav,
  :host(:not([disabled]):hover) .menu-item--link {
    color: var(--he-accent-foreground-rest);
  }

  :host(:not([disabled]):active) .menu-item--quicknav,
  :host(:not([disabled]):active) .menu-item--link {
    color: var(--he-neutral-foreground);
  }

  :host([aria-checked='true']) .menu-item--quicknav,
  :host([current]) .menu-item--link {
    background-color: var(--he-neutral-fill-stealth-active);
    position: relative;
  }

  :host([aria-checked='true']) .menu-item--quicknav::after,
  :host([current]) .menu-item--link::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2px;
    background: var(--he-accent-foreground-rest);
  }

  .menu-item--quicknav.menu-item--rtl::after,
  .menu-item--link.menu-item--rtl::after {
    right: -1px;
    left: auto;
  }

  .menu-item--quicknav .input-container {
    display: none;
  }

  @media screen and (forced-colors: active) {
    :host,
    .popup {
      forced-color-adjust: none;
    }

    :host {
      background-color: ${SystemColors.ButtonFace};
      color: ${SystemColors.ButtonText};
      border-color: ${SystemColors.ButtonText};
    }

    ::slotted(*) {
      color: ${SystemColors.ButtonText};
      fill: currentColor;
    }

    :host(:hover:not([disabled])),
    :host(:active:not([disabled])),
    :host(:hover:not([disabled])) .he-icon,
    :host(:active:not([disabled])) .he-icon,
    :host(:hover:not([disabled])) ::slotted(*),
    :host(:active:not([disabled])) ::slotted(*) {
      background-color: ${SystemColors.Highlight};
      color: ${SystemColors.HighlightText};
    }

    :host([expanded]),
    :host([expanded]) .he-icon,
    :host([expanded]) ::slotted(*) {
      background-color: ${SystemColors.Highlight};
      border-color: ${SystemColors.Highlight};
      color: ${SystemColors.HighlightText};
    }

    :host(:focus-visible) {
      box-shadow: 0 0 0 var(--he-focus-ring-width) inset ${SystemColors.ButtonText};
    }

    :host(:focus-visible),
    :host(:focus-visible) .he-icon,
    :host(:focus-visible) ::slotted(*) {
      background: ${SystemColors.Highlight};
      color: ${SystemColors.HighlightText};
    }

    :host([disabled]),
    :host([disabled]) .he-icon,
    :host([disabled]) ::slotted(*) {
      background: ${SystemColors.Canvas};
      color: ${SystemColors.GrayText};
      opacity: 1;
    }

    .radio {
      border-color: ${SystemColors.ButtonText};
    }

    :host([aria-checked='true']),
    :host([aria-checked='true']) .he-icon,
    :host([aria-checked='true']) .radio,
    :host([aria-checked='true']) ::slotted(*) {
      background: ${SystemColors.Highlight};
      color: ${SystemColors.HighlightText};
    }

    :host(:hover) .radio,
    :host(:focus-visible) .radio,
    :host([aria-checked='true']) .radio {
      border-color: ${SystemColors.HighlightText};
    }

    :host([aria-checked='true']) .radio-indicator,
    :host([aria-checked='true']:hover) .radio-indicator {
      background: ${SystemColors.HighlightText};
    }

    :host([aria-checked='true']) .checkbox-indicator,
    :host([aria-checked='true']) ::slotted([slot='checkbox-indicator']),
    :host([aria-checked='true']) ::slotted([slot='radio-indicator']) {
      color: ${SystemColors.HighlightText};
    }
  }
`;
