import { CustomEvents } from '../../helpers/constant';
import { determinePortalConfig, PortalConfig } from '../../services/portal-config';
import { trackEvent } from '../../services/telemetry-service/library-telemetry-service';
import { VersionPicker } from '../../utilities/version-picker';
import { authWorkspaceConnectorHarmonyPortal } from './auth-workspace-connector-harmony-portal';
import { authWorkspaceConnectorPartnerPortal } from './auth-workspace-connector-partner-portal';
import { IAuthWorkspaceConnector } from './i-auth-workspace-connector';
import { AuthWorkspace } from './types/workspace';

let preFetchPromise: Promise<AuthWorkspace[]> | undefined = undefined;
let versionPicker: VersionPicker;

/**
 * @description It is the auth workspace connector that is responsible for providing list of authorized workspaces on the client side.
 *
 * @since 0.0.0
 * @status experimental
 */
export const authWorkspaceConnector: IAuthWorkspaceConnector = async (forceNew: boolean = false): Promise<AuthWorkspace[]> => {
  // version picker
  if (!versionPicker) {
    versionPicker = VersionPicker.getInstance();
  }

  const versionPickerEventLog = {
    connector: 'authWorkspaceConnector',
    scope: window.location.origin,
    href: window.location.href,
  };

  const library = await versionPicker?.loadShellLibrary();
  if (library?.authWorkspaceConnectorCore) {
    trackEvent(CustomEvents.VersionPickerUsed, versionPickerEventLog);
    return library.authWorkspaceConnectorCore(forceNew);
  } else {
    trackEvent(CustomEvents.VersionPickerFallback, versionPickerEventLog);
  }

  return authWorkspaceConnectorCore(forceNew);
};

export const authWorkspaceConnectorCore: IAuthWorkspaceConnector = async (forceNew: boolean = false): Promise<AuthWorkspace[]> => {
  if (preFetchPromise && !forceNew) {
    return preFetchPromise;
  }
  const portalConfig = await determinePortalConfig();

  if (portalConfig === PortalConfig.PartnerPortal) {
    preFetchPromise = authWorkspaceConnectorPartnerPortal();
    return preFetchPromise;
  }

  preFetchPromise = authWorkspaceConnectorHarmonyPortal();
  return preFetchPromise;
};
