import { property } from 'lit/decorators.js';
import HarmonyElement from '../';
import { watch } from '../../internal/watch';
import styles from './divider.styles';

/**
 * @tag he-divider
 * @since 1.3
 * @status stable
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 */
export class Divider extends HarmonyElement {
  static styles = styles;
  static baseName = 'divider';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
  };

  /** Set to true to render the divider as a presentational element instead of a separator.*/
  @property({ type: Boolean, reflect: true }) presentation = false;

  /** The divider's orientation. */
  @property({ reflect: true }) orientation: 'horizontal' | 'vertical' = 'horizontal';

  @watch('orientation')
  @watch('presentation')
  syncAttributes() {
    if (this.presentation) {
      this.setAttribute('role', 'presentation');
      this.removeAttribute('aria-orientation');
    } else {
      this.setAttribute('role', 'separator');
      this.setAttribute('aria-orientation', this.orientation);
    }
  }
}

export default Divider;
