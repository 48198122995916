import { property, queryAssignedElements } from 'lit/decorators.js';
import { html } from 'lit/static-html.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import HarmonyElement from '../';
import { getScope } from '../../utilities/scope';
import styles from './button-group.styles';

const BUTTON_CHILDREN = ['button', 'filter-pill'];

/**
 * @tag he-button-group
 * @since 5.5
 * @status stable
 * @design pending
 * @figma https://www.figma.com/file/dRwBPvZFZdYgWdAOCK375K/Harmony-Toolkit?node-id=5%3A889
 *
 * @slot - One or more `<he-button>` or `<he-filter-pill>` elements to display in the button group.
 *
 * @csspart base - The component's base wrapper.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 */
export class ButtonGroup extends HarmonyElement {
  static styles = styles;
  static baseName = 'button-group';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
  };

  private scope = getScope(this);

  /** A label to use for the button group's aria-label attribute. */
  @property() label?: string;

  connectedCallback() {
    super.connectedCallback();
  }

  @queryAssignedElements({ flatten: true }) private slottedElements!: Array<HTMLElement>;

  private setClasses() {
    this.slottedElements.forEach(el => {
      const index = this.slottedElements.indexOf(el);
      const button = this.findButton(el);

      if (button !== null) {
        button.classList.add('he-button-group__button');
        button.classList.toggle('he-button-group__button--first', index === 0);
        button.classList.toggle('he-button-group__button--inner', index > 0 && index < this.slottedElements.length - 1);
        button.classList.toggle('he-button-group__button--last', index === this.slottedElements.length - 1);
      }
    });
  }

  private findButton(el: HTMLElement) {
    const buttonChildren = BUTTON_CHILDREN.map(tag => this.scope.tagName(tag));
    return buttonChildren.includes(el.tagName.toLowerCase()) ? el : el.querySelector(buttonChildren.join(','));
  }

  protected render() {
    return html`
      <div part="base" class="button-group" role="group" aria-label=${ifDefined(this.label)}>
        <slot @slotchange=${() => this.setClasses()}></slot>
      </div>
    `;
  }
}

export default ButtonGroup;
