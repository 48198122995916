export default {
  "back": "ย้อนกลับ",
  "breadcrumb": "การแสดงเส้นทาง",
  "clear": "ล้าง",
  "clear_entry": "ล้างรายการ",
  "close": "ปิด",
  "collapse_task_menu": "ยุบเมนูงาน",
  "collapse": "ยุบ",
  "commands": "คำสั่ง",
  "complete": "เสร็จสมบูรณ์",
  "day": "วัน",
  "dislike": "ไม่ชอบ",
  "error": "ข้อผิดพลาด",
  "expand_task_menu": "ขยายเมนูงาน",
  "expand": "ขยาย",
  "filter": "ตัวกรอง",
  "finish": "เสร็จสิ้น",
  "first": "ครั้งที่หนึ่ง",
  "go_to_page": "ไปที่หน้า {0}",
  "hide_password": "ซ่อนรหัสผ่าน",
  "last": "สุดท้าย",
  "like": "ชอบ",
  "loading": "กำลังโหลด...",
  "month": "เดือน",
  "more_options": "ตัวเลือกเพิ่มเติม",
  "navigation": "การนำทาง",
  "next": "ถัดไป",
  "next_month": "เดือนถัดไป",
  "not_started": "ยังไม่ได้เริ่ม",
  "off": "ปิด",
  "on": "บน",
  "open_in_new_window": "เปิดในหน้าต่างใหม่",
  "options": "ตัวเลือก",
  "pagination": "การแบ่งหน้า",
  "plus_x_more": "+ อีก {0} รายการ",
  "previous": "ก่อนหน้า",
  "previous_month": "เดือนก่อนหน้า",
  "progress": "กำลังดำเนินการ",
  "quick_start": "เริ่มต้นใช้งานด่วน",
  "range_error_message": "วันที่ที่คุณเลือกไม่อยู่ในช่วงวันที่",
  "required_error_message": "ช่องที่ต้องกรอก",
  "results_found": "พบผลลัพธ์ {0} รายการ",
  "search": "ค้นหา",
  "select_all": "เลือกทั้งหมด",
  "select_row": "เลือกแถว",
  "show_calendar": "แสดงปฏิทิน",
  "show_password": "แสดงรหัสผ่าน",
  "step_x_of_y": "ขั้นตอนที่ {0} จาก {1}",
  "today": "วันนี้",
  "unavailable_date_error_message": "วันที่ที่คุณเลือกไม่พร้อมใช้งาน",
  "warning": "คำเตือน",
  "year": "ปี"
}