export default {
  "ar-SA": () => import("./ar-SA.js"),
  "bg-BG": () => import("./bg-BG.js"),
  "ca-ES": () => import("./ca-ES.js"),
  "cs-CZ": () => import("./cs-CZ.js"),
  "da-DK": () => import("./da-DK.js"),
  "de-DE": () => import("./de-DE.js"),
  "el-GR": () => import("./el-GR.js"),
  "es-ES": () => import("./es-ES.js"),
  "et-EE": () => import("./et-EE.js"),
  "eu-ES": () => import("./eu-ES.js"),
  "fi-FI": () => import("./fi-FI.js"),
  "fr-FR": () => import("./fr-FR.js"),
  "gl-ES": () => import("./gl-ES.js"),
  "he-IL": () => import("./he-IL.js"),
  "hi-IN": () => import("./hi-IN.js"),
  "hr-HR": () => import("./hr-HR.js"),
  "hu-HU": () => import("./hu-HU.js"),
  "id-ID": () => import("./id-ID.js"),
  "it-IT": () => import("./it-IT.js"),
  "ja-JP": () => import("./ja-JP.js"),
  "kk-KZ": () => import("./kk-KZ.js"),
  "ko-KR": () => import("./ko-KR.js"),
  "lt-LT": () => import("./lt-LT.js"),
  "lv-LV": () => import("./lv-LV.js"),
  "ms-MY": () => import("./ms-MY.js"),
  "nb-NO": () => import("./nb-NO.js"),
  "nl-NL": () => import("./nl-NL.js"),
  "pl-PL": () => import("./pl-PL.js"),
  "pt-BR": () => import("./pt-BR.js"),
  "pt-PT": () => import("./pt-PT.js"),
  "ro-RO": () => import("./ro-RO.js"),
  "ru-RU": () => import("./ru-RU.js"),
  "sk-SK": () => import("./sk-SK.js"),
  "sl-SI": () => import("./sl-SI.js"),
  "sr-Latn-RS": () => import("./sr-Latn-RS.js"),
  "sv-SE": () => import("./sv-SE.js"),
  "th-TH": () => import("./th-TH.js"),
  "tr-TR": () => import("./tr-TR.js"),
  "uk-UA": () => import("./uk-UA.js"),
  "vi-VN": () => import("./vi-VN.js"),
  "zh-CN": () => import("./zh-CN.js"),
  "zh-TW": () => import("./zh-TW.js"),
};
