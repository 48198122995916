export default {
  "back": "Quay lại",
  "breadcrumb": "Đánh dấu đường dẫn",
  "clear": "Xóa",
  "clear_entry": "Xóa mục nhập",
  "close": "Đóng",
  "collapse_task_menu": "Thu gọn menu tác vụ",
  "collapse": "Thu gọn",
  "commands": "Lệnh",
  "complete": "Hoàn thành",
  "day": "Ngày",
  "dislike": "Không thích",
  "error": "Lỗi",
  "expand_task_menu": "Bung rộng menu tác vụ",
  "expand": "Mở rộng",
  "filter": "Bộ lọc",
  "finish": "Hoàn tất",
  "first": "Đầu tiên",
  "go_to_page": "Truy nhập trang {0}",
  "hide_password": "Ẩn mật khẩu",
  "last": "Cuối cùng",
  "like": "Thích",
  "loading": "Đang tải...",
  "month": "Tháng",
  "more_options": "Xem thêm tùy chọn",
  "navigation": "Dẫn hướng",
  "next": "Tiếp theo",
  "next_month": "Tháng tới",
  "not_started": "Chưa bắt đầu",
  "off": "Tắt",
  "on": "Bật",
  "open_in_new_window": "Mở trong cửa sổ mới",
  "options": "Tùy chọn",
  "pagination": "Dàn trang",
  "plus_x_more": "+{0} khác",
  "previous": "Trước",
  "previous_month": "Tháng trước",
  "progress": "Đang diễn ra",
  "quick_start": "Bắt đầu nhanh",
  "range_error_message": "Ngày bạn đã chọn không nằm trong phạm vi ngày",
  "required_error_message": "Trường này là bắt buộc",
  "results_found": "Đã tìm thấy {0} kết quả",
  "search": "Tìm kiếm",
  "select_all": "Chọn tất cả",
  "select_row": "Chọn hàng",
  "show_calendar": "Hiện lịch",
  "show_password": "Hiện mật khẩu",
  "step_x_of_y": "Bước {0} trên {1}",
  "today": "Hôm nay",
  "unavailable_date_error_message": "Không có sẵn ngày bạn đã chọn",
  "warning": "Cảnh báo",
  "year": "Năm"
}