export default {
  "back": "Назад",
  "breadcrumb": "Трасиране",
  "clear": "Изчистване",
  "clear_entry": "Изчистване на записа",
  "close": "Затвори",
  "collapse_task_menu": "Свиване на менюто за задачи",
  "collapse": "Сгъване",
  "commands": "Команди",
  "complete": "Завършено",
  "day": "Ден",
  "dislike": "Не харесвам",
  "error": "Грешка",
  "expand_task_menu": "Разгъване на менюто за задачи",
  "expand": "Разгъване",
  "filter": "Филтриране",
  "finish": "Завършване",
  "first": "Първи",
  "go_to_page": "Към страница {0}",
  "hide_password": "Скриване на парола",
  "last": "Последен",
  "like": "Харесвам",
  "loading": "Зареждане...",
  "month": "Месец",
  "more_options": "Още опции",
  "navigation": "Навигация",
  "next": "Напред",
  "next_month": "Следващ месец",
  "not_started": "Не е стартирано",
  "off": "Изключено",
  "on": "Включено",
  "open_in_new_window": "Отваряне в нов прозорец",
  "options": "Опции",
  "pagination": "Страниране",
  "plus_x_more": "+ още {0}",
  "previous": "Назад",
  "previous_month": "Предишен месец",
  "progress": "В ход",
  "quick_start": "Бърз старт",
  "range_error_message": "Датата, която сте избрали, не е в диапазона от дати",
  "required_error_message": "Това поле е задължително",
  "results_found": "Намерени са {0} резултати",
  "search": "Търсене",
  "select_all": "Избор на всички",
  "select_row": "Избор на ред",
  "show_calendar": "Показване на календара",
  "show_password": "Показване на парола",
  "step_x_of_y": "Стъпка {0} от {1}",
  "today": "Днес",
  "unavailable_date_error_message": "Избраната от вас дата е недостъпна",
  "warning": "Предупреждение",
  "year": "Година"
}