function registerAiInstance(instrumentationKey: string, config?: any) {
  if (window.Microsoft?.ApplicationInsights) {
    const aiConfig = config ? { config: { ...config, instrumentationKey } } : { config: { instrumentationKey } };

    const aiInstance = new window.Microsoft.ApplicationInsights.ApplicationInsights(aiConfig);
    aiInstance.loadAppInsights();

    if (!!aiInstance) {
      return aiInstance;
    }
  }
  throw new Error('Registration of secondary AppInsights failed');
}

function trackEvent(event: CustomEvent, instance: any, harmonyVersion: string) {
  instance.trackEvent(
    {
      name: event.detail?.metricName || 'HarmonyTelemetry',
      properties: {
        ...event.detail,
        ...event.detail?.customProperties,
        version: harmonyVersion,
        href: window.location.href,
        pathName: window.location.pathname,
      },
    },
    { ...event.detail }
  );
}

function trackError(event: CustomEvent, instance: any, harmonyVersion: string) {
  instance.trackException({
    exception: event.detail?.error,
    severityLevel: event.detail?.severity || 4,
    properties: {
      ...event.detail,
      version: harmonyVersion,
      href: window.location.href,
      pathName: window.location.pathname,
    },
  });
}

function trackPerformance(event: CustomEvent, instance: any, harmonyVersion: string) {
  instance.trackMetric({
    name: event.detail?.metricName || 'HarmonyPerformance',
    properties: {
      ...event.detail,
      version: harmonyVersion,
      href: window.location.href,
      pathName: window.location.pathname,
    },
    ...event.detail?.performanceMetrics,
  });
}

export const AiV2 = {
  trackEvent,
  trackError,
  trackPerformance,
  registerAiInstance,
};
