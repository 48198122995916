import {
  ApplicationInsights,
  ICustomProperties,
  IDependencyTelemetry,
  IEventTelemetry,
  IExceptionTelemetry,
  IMetricTelemetry,
  IPageViewPerformanceTelemetry,
  IPageViewTelemetry,
  ITraceTelemetry,
  Snippet,
} from '@microsoft/applicationinsights-web';
import { IApplicationInsightsPortalLogger } from './types/i-appinsights-portal-logger';

enum AppInsightsSupportedTraceTypes {
  Trace,
  Event,
  Exception,
  PageView,
  PageViewPerformance,
  Dependency,
  Metric,
}

type TrackData = {
  type: AppInsightsSupportedTraceTypes;
  data: ITraceTelemetry | IExceptionTelemetry | IEventTelemetry;
  properties: ICustomProperties | undefined;
};

/**
 * @description It is the Appinsights Logger that allows team to ensure that logging can happen parallely without it
 * blocking the loading of the app.
 *
 * @external
 * @since 0.0.0
 * @status experimental
 */
export default class AppInsightsPortalLogger implements IApplicationInsightsPortalLogger {
  private aiInstance: ApplicationInsights | undefined;
  private config: Snippet | undefined;
  private parentId: string | undefined;
  private traceId: string | undefined;

  // Manages queue to handle the logging request while the appinsights object is getting initialized.
  // This allows us to handle the request on behalf of the user.
  private logQueue: TrackData[] = [];

  // This is to prevent the load of appinsights with the initial app load.
  // We want to use idle time on the thread to load app insights.
  // The reason we are not using idleRequestedCallback is because it is critical action to load
  // app insights.
  private delayInLoadAppInsightsMs: number = 100;
  private appInsightsLoaded: boolean = false;

  // Indicates the timeout if we are using requestIdleCallback.
  // We should not use requestIdleCallback without timeout for telemetry as these are still critical data.
  private requestIdleTimeout: number = 500;
  private batchesAppInsightsBulkLog: number = 5;

  public registerAiConfig = (instrumentationKey: string, parentId?: string, traceId?: string, aiConfig?: Snippet) => {
    this.config = aiConfig ? { config: { ...aiConfig, instrumentationKey } } : { config: { instrumentationKey } };
    this.parentId = parentId;
    this.traceId = traceId;

    setTimeout(() => {
      this.aiInstance = new ApplicationInsights(this.config as Snippet);
      this.loadAiInstance();
    }, this.delayInLoadAppInsightsMs);
  };

  public trackEvent(event: IEventTelemetry, customProperties?: ICustomProperties | undefined): void {
    this.trackData(AppInsightsSupportedTraceTypes.Event, event, customProperties);
  }

  public trackTrace(trace: ITraceTelemetry, customProperties?: ICustomProperties | undefined): void {
    this.trackData(AppInsightsSupportedTraceTypes.Trace, trace, customProperties);
  }

  public trackException(exception: IExceptionTelemetry, customProperties?: ICustomProperties | undefined): void {
    this.trackData(AppInsightsSupportedTraceTypes.Exception, exception, customProperties);
  }

  public trackPageView(pageView: IPageViewTelemetry): void {
    this.trackData(AppInsightsSupportedTraceTypes.PageView, pageView);
  }

  public trackPageViewPerformance(pageViewPerf: IPageViewPerformanceTelemetry): void {
    this.trackData(AppInsightsSupportedTraceTypes.PageViewPerformance, pageViewPerf);
  }

  public trackDependency(dependency: IDependencyTelemetry): void {
    this.trackData(AppInsightsSupportedTraceTypes.Dependency, dependency);
  }

  public trackMetric(metric: IDependencyTelemetry, customProperties?: ICustomProperties | undefined): void {
    this.trackData(AppInsightsSupportedTraceTypes.Dependency, metric, customProperties);
  }

  private loadAiInstance = () => {
    if (this.aiInstance) {
      setTimeout(() => {
        this.aiInstance?.loadAppInsights();
        (this.aiInstance as ApplicationInsights).context.telemetryTrace.parentID = this.parentId;
        (this.aiInstance as ApplicationInsights).context.telemetryTrace.traceID = this.traceId;
        this.appInsightsLoaded = true;
        this.cleanupTracesQueue();
      }, this.delayInLoadAppInsightsMs);
    }
  };

  public getAiInstance(): ApplicationInsights | null {
    if (this.aiInstance) {
      return this.aiInstance;
    }
    return null;
  }

  private cleanupTracesQueue = () => {
    // This is the additional timeout we have for the current Batch.
    // We will add timeout after the batch
    this.logQueue.forEach((trackData: TrackData, index: number) => {
      const currentBatchTimeout = Math.floor(index / this.batchesAppInsightsBulkLog) * this.delayInLoadAppInsightsMs;
      this.trackData(trackData.type, trackData.data, trackData.properties, currentBatchTimeout);
    });
  };

  private trackData = (
    type: AppInsightsSupportedTraceTypes,
    data: ITraceTelemetry | IExceptionTelemetry | IEventTelemetry | IPageViewTelemetry | IPageViewPerformanceTelemetry | IDependencyTelemetry | IMetricTelemetry,
    customProperties?: ICustomProperties | undefined,
    additionalTimeOut: number = 0
  ) => {
    if (!this.appInsightsLoaded) {
      const trackData: TrackData = {
        data: data,
        properties: customProperties,
        type: type,
      };
      this.logQueue.push(trackData);
      return;
    }

    const callbackTrace = () => {
      switch (type) {
        case AppInsightsSupportedTraceTypes.Trace:
          this.aiInstance?.trackTrace(data as ITraceTelemetry, customProperties);
          break;
        case AppInsightsSupportedTraceTypes.Event:
          this.aiInstance?.trackEvent(data as IEventTelemetry, customProperties);
          break;
        case AppInsightsSupportedTraceTypes.Exception:
          this.aiInstance?.trackException(data as IExceptionTelemetry, customProperties);
          break;
        case AppInsightsSupportedTraceTypes.PageView:
          this.aiInstance?.trackPageView(data as IPageViewTelemetry);
          break;
        case AppInsightsSupportedTraceTypes.PageViewPerformance:
          this.aiInstance?.trackPageViewPerformance(data as IPageViewPerformanceTelemetry);
          break;
        case AppInsightsSupportedTraceTypes.Dependency:
          this.aiInstance?.trackDependencyData(data as IDependencyTelemetry);
          break;
        case AppInsightsSupportedTraceTypes.Metric:
          this.aiInstance?.trackMetric(data as IMetricTelemetry, customProperties);
          break;
      }
    };
    this.idleCallback(callbackTrace, additionalTimeOut);
  };

  private idleCallback = (callback: IdleRequestCallback, additionalTimeOut: number) => {
    // Request Idle method queues a function to be called during a browser's idle periods.
    // Functions are generally called in first-in-first-out order; however, callbacks which have a timeout
    // specified may be called out-of-order if necessary in order to run them before the timeout elapses.
    // RequestIdleCallback is not supported in Safari.
    if ('requestIdleCallback' in window) {
      requestIdleCallback(callback, { timeout: this.requestIdleTimeout + additionalTimeOut });
    } else {
      setTimeout(callback, this.requestIdleTimeout + additionalTimeOut);
    }
  };
}
