import { html } from 'lit/static-html.js';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { createRef, Ref, ref } from 'lit/directives/ref.js';
import { HasSlotController } from '../../internal/slot';
import { watch } from '../../internal/watch';
import { animateTo, stopAnimations } from '../../internal/animations';
import { getScope } from '../../utilities/scope';
import { waitForEvent } from '../../utilities/wait-for-event';
import button from '../button/button';
import icon from '../icon/icon';
import HarmonyElement from '../';
import styles from './message-bar.styles';

const KEY_FRAMES = {
  right: {
    open: [
      { opacity: 0, transform: 'translateX(100%)' },
      { opacity: 1, transform: 'translateX(0)' },
    ],
    close: [
      { opacity: 1, transform: 'translateX(0)' },
      { opacity: 0, transform: 'translateX(100%)' },
    ],
  },
  left: {
    open: [
      { opacity: 0, transform: 'translateX(-100%)' },
      { opacity: 1, transform: 'translateX(0)' },
    ],
    close: [
      { opacity: 1, transform: 'translateX(0)' },
      { opacity: 0, transform: 'translateX(-100%)' },
    ],
  },
};

/**
 * @tag he-message-bar
 * @since 2.1
 * @status stable
 * @figma https://www.figma.com/file/dRwBPvZFZdYgWdAOCK375K/PC-Toolkit?node-id=13%3A1144
 *
 * @slot - The message content of the message bar.
 * @slot icon - Optional icon to override the built-in icons.
 * @slot actions - Optional action buttons to display for the message bar.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-show - Emitted when the message bar is opened.
 * @event he-hide - Emitted when the message bar is closed.
 * @event he-after-show - Emitted after the message bar is opened and the transitions are complete.
 * @event he-after-hide - Emitted after the message bar is closed and the transitions are complete.
 *
 * @csspart base - The component's base wrapper.
 * @csspart content - The "content" of the collapsed or single line message.
 * @csspart footer - The footer portion of the message only displayed when expanded.
 * @csspart icon - The icon of the message bar.
 * @csspart message - The base of the message portion of the bar.
 * @csspart actions - The base of the actions slot's content.
 *
 * @dependency he-button
 * @dependency he-icon
 */
export class MessageBar extends HarmonyElement {
  static styles = styles;
  static baseName = 'message-bar';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeShow: new CustomEvent('he-show'),
    onHeHide: new CustomEvent('he-hide'),
    onHeAfterShow: new CustomEvent('he-after-show'),
    onHeAfterHide: new CustomEvent('he-after-hide'),
  };

  private scope = getScope(this);
  private resizeObserver: ResizeObserver;
  private closeTimeout: null | number = null;
  base: Ref<HTMLElement> = createRef();
  messageContainer: Ref<HTMLElement> = createRef();
  messageContent: Ref<HTMLElement> = createRef();
  private hasSlotController = new HasSlotController(this, '[default]', 'actions', 'icon');

  /**
   * The direction the message bar will open and close from.
   *
   * Note: "right" and "left" will be deprecated in a future version. Please use the logical properties "end" or
   * "start", which are based on language direction.
   */
  @property() direction: 'end' | 'start' | 'right' | 'left' = 'end';

  // direction converted to right or left based on direction
  @state()
  private get _direction() {
    switch (this.direction) {
      case 'start':
        return this.dir === 'rtl' ? 'right' : 'left';
      case 'end':
        return this.dir === 'rtl' ? 'left' : 'right';
      default:
        return this.direction;
    }
  }

  /** Indicates the transition speed for animations in ms. */
  @property({ attribute: 'transition-speed' }) transitionSpeed = 400;

  /** Expands the message if it is a multi line message. */
  @property({ type: Boolean, reflect: true }) expanded: boolean = false;

  /** Hides the close button (message will still auto close). */
  @property({ type: Boolean, reflect: true, attribute: 'no-close-button' }) noCloseButton: boolean = false;

  /**
   * The time in milliseconds before the message automatically closes. By default, the message bar will remain open
   * indefinitely.
   */
  @property() duration = Infinity;

  /** Indicates the type and appearance of the message bar. */
  @property() appearance: 'info' | 'notice' | 'success' | 'warning' | 'error' = 'info';

  @watch('appearance')
  handleAppearanceChange(oldValue: string, newValue: string) {
    if (oldValue !== newValue) {
      this.iconName = this.getIconName(newValue);
    }
  }

  /** Indicates if the message is open. */
  @property({ type: Boolean, reflect: true }) open: boolean = false;

  @state() iconName: string = 'info';

  /** Allows developers to manually control the multiline behavior */
  @property({ type: Boolean, reflect: true }) expandable?: boolean;
  @watch('expandable')
  handleExpandableChange() {
    this.setMultiline();
  }

  @state()
  private multiline: boolean = false;

  constructor() {
    super();
    this.scope.registerComponent(button, icon);
  }

  connectedCallback() {
    super.connectedCallback();
    this.initResizeObserver();
  }

  firstUpdated() {
    if (this.open) {
      this.hidden = false;
      this.startDuration();
      this.setMultiline();
    } else {
      this.hidden = true;
    }
  }

  disconnectedCallback() {
    this.resizeObserver.unobserve(this);
    this.resizeObserver.disconnect();
    super.disconnectedCallback();
  }

  get keyFrames() {
    switch (this._direction) {
      case 'left':
        return KEY_FRAMES.left;
      default:
        return KEY_FRAMES.right;
    }
  }

  startDuration() {
    // Reset the timer
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }

    if (this.duration > 0 && this.duration < Infinity) {
      this.closeTimeout = window.setTimeout(() => {
        if (this.open) {
          this.hide();
        }
      }, this.duration);
    }
  }

  handleSlotChange() {
    this.setMultiline();
  }

  setMultiline() {
    if (this.expandable !== undefined) {
      this.multiline = this.expandable;
    } else if (this.expanded) {
      this.multiline = true;
    } else {
      const isTooWide = this.messageContainer?.value?.offsetWidth === this.messageContent?.value?.offsetWidth;
      const isTooTall = this.messageContent?.value!.scrollHeight > this.messageContent?.value!.clientHeight;
      this.multiline = isTooWide || isTooTall;
    }
  }

  private initResizeObserver() {
    this.resizeObserver = new ResizeObserver(() => this.setMultiline());
    this.resizeObserver.observe(this);
  }

  /** Toggle the expanded attribute to expand or collapse multi line message. */
  public toggleExpand() {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.messageContent?.value?.focus();
    }
  }

  private getIconName(type: string) {
    switch (type) {
      case 'success':
        return 'completed';
      case 'warning':
        return 'warning';
      case 'error':
        return 'errorbadge';
      case 'notice':
      default:
        return 'info';
    }
  }

  /** Hide/Close the message. */
  public async hide() {
    if (!this.open) {
      return;
    }

    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }

    this.open = false;
    this.expanded = false;

    return waitForEvent(this, 'he-after-hide');
  }

  /** Open/Show the message. */
  public async show() {
    if (this.open) {
      return;
    }
    this.open = true;
    return waitForEvent(this, 'he-after-show');
  }

  @watch('open', { waitUntilFirstUpdate: true })
  async openChange() {
    if (!this.base.value) {
      return;
    }

    if (this.open) {
      this.emit('he-show');
      await stopAnimations(this.base.value);
      this.hidden = false;
      this.setMultiline();
      await animateTo(this.base.value, this.keyFrames.open, { duration: this.transitionSpeed, easing: 'ease' });
      this.startDuration();
      this.emit('he-after-show');
    } else {
      this.emit('he-hide');
      await animateTo(this.base.value, this.keyFrames.close, { duration: this.transitionSpeed, easing: 'ease' });
      this.hidden = true;
      this.closeTimeout = null;
      this.emit('he-after-hide');
    }
  }

  render() {
    return html`
      <div class="message-bar__wrapper">
        <div
          part="base"
          class=
          ${classMap({
            'message-bar': true,
            'message-bar--open': this.open,
            'message-bar--collapsed': !this.expanded,
            'message-bar--expanded': this.expanded,
            [`message-bar--${this.appearance}`]: true,
            'message-bar--has-actions': this.hasSlotController.test('actions'),
          })}
          aria-live="assertive"
          aria-atomic="true"
          ${ref(this.base)}
        >
          <div part="content" class="message-bar__content">
            <div part="icon" class="message-bar-icon">
              <slot name="icon">
                <${this.scope.tag('icon')} name="${this.iconName}"> </${this.scope.tag('icon')}>
              </slot>
            </div>
            <div part="message" class='message-bar__message-container' ${ref(this.messageContainer)}>
              <div ${ref(this.messageContent)} class="message-bar__message-inner-container" tabindex="-1">
                <slot @slotchange=${this.handleSlotChange}></slot>
              </div>
            </div>
            <div part="actions" class="message-bar__actions">
              ${!this.expanded ? html`<slot name="actions"></slot>` : ''}
              ${
                this.multiline
                  ? html`
                    <${this.scope.tag('button')}
                      class="message-bar__button message-bar__multiline__button"
                      appearance="stealth"
                      @click=${this.toggleExpand}
                    >
                      ${
                        this.expanded
                          ? html`
                            <${this.scope.tag('icon')}
                              name="doublechevronup"
                              label="${this.localize.term('collapse')}"
                            >
                            </${this.scope.tag('icon')}>`
                          : html`
                            <${this.scope.tag('icon')}
                              name="doublechevrondown"
                              label="${this.localize.term('expand')}"
                            >
                            </${this.scope.tag('icon')}>
                          `
                      }
                    </${this.scope.tag('button')}>
                  `
                  : ''
              }
              ${
                !this.noCloseButton
                  ? html`
                    <${this.scope.tag('button')}
                      class="message-bar__button"
                      appearance="stealth"
                      @click=${this.hide}
                    >
                      <${this.scope.tag('icon')} name="cancel" label=${this.localize.term('close_message')}>
                      </${this.scope.tag('icon')}>
                    </${this.scope.tag('button')}>
                  `
                  : ''
              }
            </div>
          </div>
          ${
            this.expanded
              ? html`
                  <div part="footer" class="message-bar__footer">
                    <slot name="actions"></slot>
                  </div>
                `
              : ''
          }
        </div>
      </div>
    `;
  }
}

export default MessageBar;
