/**
 * Listens for an event and resolves with this event object after it was fired.
 *
 * @param eventTarget Target of the event, usually an Element
 * @param eventName Name of the event
 * @returns {Promise} Promise to await until the event has been fired
 */
export function waitForEvent(eventTarget: EventTarget, eventName: string): Promise<void> {
  return new Promise<void>(resolve => {
    function done(event: Event) {
      if (event.target === eventTarget) {
        eventTarget.removeEventListener(eventName, done);
        resolve();
      }
    }

    eventTarget.addEventListener(eventName, done);
  });
}
