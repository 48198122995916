/**
 * Helper function to identify if the string is undefined or empty.
 * @param str The string value that needs to be checked.
 * @returns true if the string is empty or undefined or null.
 * @internal
 */
export const isEmptyOrSpaces = (value: string): boolean => {
  if (value === undefined) return true;
  if (value === null) return true;
  if (value === '') return true;
  if (value.match(/^ *$/) !== null) return true;

  return false;
};
