export default {
  "back": "뒤",
  "breadcrumb": "이동 경로",
  "clear": "지우기",
  "clear_entry": "항목 지우기",
  "close": "닫기",
  "collapse_task_menu": "작업 메뉴 축소",
  "collapse": "축소",
  "commands": "명령",
  "complete": "완료",
  "day": "일",
  "dislike": "싫어요",
  "error": "오류",
  "expand_task_menu": "작업 메뉴 확장",
  "expand": "확장",
  "filter": "필터",
  "finish": "마침",
  "first": "첫 번째",
  "go_to_page": "{0} 페이지로 이동",
  "hide_password": "암호 숨기기",
  "last": "마지막",
  "like": "유사",
  "loading": "로드 중...",
  "month": "월",
  "more_options": "기타 옵션",
  "navigation": "탐색",
  "next": "다음",
  "next_month": "다음 달",
  "not_started": "시작 안 됨",
  "off": "끄기",
  "on": "켜기",
  "open_in_new_window": "새 창에서 열기",
  "options": "옵션",
  "pagination": "페이지 매김",
  "plus_x_more": "+{0}개 더",
  "previous": "이전",
  "previous_month": "이전 달",
  "progress": "진행 중",
  "quick_start": "빠른 시작",
  "range_error_message": "선택한 날짜가 날짜 범위 내에 있지 않습니다.",
  "required_error_message": "이 필드는 필수입니다.",
  "results_found": "결과 {0}개 찾음",
  "search": "검색",
  "select_all": "모두 선택",
  "select_row": "행 선택",
  "show_calendar": "달력 표시",
  "show_password": "암호 표시",
  "step_x_of_y": "{0}/{1} 단계",
  "today": "오늘",
  "unavailable_date_error_message": "선택한 날짜를 사용할 수 없습니다.",
  "warning": "경고",
  "year": "년"
}