import { CookieKeys } from '../../helpers/constant';
import { deriveValueFromCookie } from '../../helpers/cookie-helper';
import { SupportedLocaleEnums } from '../../utilities/supported-locale-enums';
import { deriveCurrentEnvironment, PortalEnvironment } from './environment-helper';
import { deriveCurrentLocale } from './locale-helper';

const packageVersion = '3.0.0';

export default class LibraryUsageContext {
  public static instance: LibraryUsageContext;

  private correlationId: string | undefined;
  private sessionId: string | undefined;
  private fdType: string | undefined;
  private locale: SupportedLocaleEnums | undefined;
  private environment: PortalEnvironment | undefined;

  public static getInstance = () => {
    if (!LibraryUsageContext.instance) {
      LibraryUsageContext.instance = new LibraryUsageContext();
    }
    return LibraryUsageContext.instance;
  };

  get CorrelationId() {
    if (!this.correlationId) {
      this.correlationId = deriveValueFromCookie(CookieKeys.CorrelationId) ?? '';
    }
    return this.correlationId;
  }

  get Locale() {
    if (!this.locale) {
      this.locale = deriveCurrentLocale();
    }
    return this.locale;
  }

  get Environment() {
    if (!this.environment) {
      this.environment = deriveCurrentEnvironment();
    }
    return this.environment;
  }

  get Version() {
    return packageVersion;
  }

  get SessionId() {
    if (!this.sessionId) {
      this.sessionId = deriveValueFromCookie(CookieKeys.SessionId) ?? '';
    }

    return this.sessionId;
  }

  get FDType() {
    if (!this.fdType) {
      this.fdType = deriveValueFromCookie(CookieKeys.FDType) ?? '';
    }
    return this.fdType;
  }
}
