import { css } from 'lit';
import { visuallyHiddenStyles } from './visually-hidden';

export default css`
  :host,
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  :host {
    font-family: var(--he-body-font);
    forced-color-adjust: auto;
  }

  [hidden] {
    display: none !important;
  }

  :host([hidden]) {
    display: none !important;
  }

  input,
  button,
  select,
  textarea {
    margin: 0;
    padding: 0;
    border: none;
    letter-spacing: inherit;
    font-size: inherit;
    font-family: inherit;
  }

  .visually-hidden {
    ${visuallyHiddenStyles}
  }

  ::-webkit-scrollbar {
    width: var(--he-scrollbar-width);
    height: var(--he-scrollbar-width);
  }
  ::-webkit-scrollbar-track {
    background-color: var(--he-color-neutral-100);
    border-radius: var(--he-scrollbar-radius);
  }
  ::-webkit-scrollbar-thumb {
    display: block;
    min-height: 40px;
    border-radius: var(--he-scrollbar-radius);
    background-color: var(--he-color-neutral-300);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--he-color-neutral-400);
  }
`;
