import { StaticValue, unsafeStatic } from 'lit/static-html.js';
import HarmonyElement from '../components';
import { trackComponent } from '../internal/track-usage';
import type { CSSResult } from 'lit';

export const harmonyEnablersScope = {
  // This becomes true when the default scope has been registered
  hasDefaultScopeBeenRegistered: false,
  // This map identifies a component by its base name (to allow easier importing in the docs)
  baseNameMap: new Map<string, typeof HarmonyElement>(),
  // This map identifies root elements that already have scopes
  scopeMap: new WeakMap<Element, string>(),
  // This map identifies the appropriate scope from a tag name
  tagMap: new Map<string, HarmonyEnablerScope>(),
} as const;

// In case it doesn't exist...
if (!window.HarmonyEnablers) {
  window.HarmonyEnablers = {};
}

// We use a global object to track scope collisions since users may be using different origins and versions
window.HarmonyEnablers.scope = harmonyEnablersScope;
const { baseNameMap, scopeMap, tagMap } = window.HarmonyEnablers.scope;

// Default to the latest CDN version so users don't have to bother setting it
export const defaultBasePath = 'https://harmony.azureedge.net/npm/@harmony/enablers/6.22.0';

export type ScopeOptions = {
  basePath?: string;
  components?: Array<typeof HarmonyElement>;
  prefix?: string;
  reactInstance?: any;
  rootElement?: Element;
  styles?: CSSResult[];
  /** @deprecated This has been replaced with the `styles` property  */
  theme?: CSSResult;
};

/**
 * Creates a custom Harmony Enabler scope, optionally applying themes and registering components. Once a scope is
 * created, it cannot be destroyed without a page reload because custom elements cannot be unregistered.
 *
 * @example
 * import { theme } from '@harmony/enablers/themes/partner-center';
 * import cssUtilities from '@harmony/enablers/utilities/css';
 * import { button } from '@harmony/enablers/components/button/button';
 * import { card } from '@harmony/enablers/components/card/card';
 *
 * const scope = createScope(
 *  rootElement: myElement,
 *  prefix: 'support',
 *  styles: [theme, cssUtilities]
 *  components: [button, card],
 * );
 */
export function createScope(options: ScopeOptions) {
  return new HarmonyEnablerScope(options);
}

/**
 * Returns a custom element's scope using an element instance or its tag name.
 *
 * @example
 * const scope = getScope(el);
 * const tagName = scope.tagName('button'); // outputs "he-button" or "prefix_he-button"
 */
export function getScope(elementOrTagName: Element | string): HarmonyEnablerScope {
  const tagName = typeof elementOrTagName === 'string' ? elementOrTagName : elementOrTagName.tagName.toLowerCase();
  return tagMap.get(tagName)!;
}

export class HarmonyEnablerScope {
  protected reactInstance: any;

  private basePath: string;
  private prefix: string;
  private rootElement: Element;

  constructor(options: ScopeOptions) {
    const components = options?.components ?? [];
    this.rootElement = options.rootElement ?? document.documentElement;
    this.basePath = options?.basePath ?? defaultBasePath;
    this.prefix = options?.prefix ?? 'he';
    this.reactInstance = options?.reactInstance;

    /** Add scope attribute to root element */
    if (this.prefix !== 'he') {
      this.rootElement.setAttribute(`scope-${options.prefix}`, '');
    }

    // Prefixes must begin with a letter and contain only letters and numbers thereafter. This is our rule to enforce
    // both custom element naming rules and a "prefix_he-basename" convention. The default prefix "he" is also allowed.
    if (!/^[a-z]([a-z0-9]+)?/.test(this.prefix)) {
      throw new Error(
        `Cannot create a Harmony Enabler scope with the "${this.prefix}" prefix. ` +
          'Prefixes must contain only letters and numbers.'
      );
    }

    // If we're not using the default prefix, enforce the "prefix_he-basename" convention.
    if (this.prefix !== 'he') {
      this.prefix += '_he';
    }

    // Each design system instance must have a unique root element.
    if (scopeMap.has(this.rootElement)) {
      const existingPrefix = scopeMap.get(this.rootElement);
      // If the scope has already been registered with the same prefix, stop here. We do this to prevent HMR from
      // blowing up with the following error. TL;DR - we only want to error when more than one prefix is scoped to the
      // same element.
      if (existingPrefix !== this.prefix) {
        throw new Error(
          'Cannot create a Harmony Enabler scope with the specified root element. ' +
            `Another scope with the "${existingPrefix}" prefix has already been created. ` +
            'You should set the root element to an element that is guaranteed to be unique to your application.'
        );
      }
    } else {
      scopeMap.set(this.rootElement, this.prefix);
    }

    // Register themes
    if (options.theme) {
      this.applyTheme(options.theme);
    }

    if (options.styles) {
      this.applyStyles(options.styles);
    }

    // Register components
    components.map(component => this.registerComponent(component));

    // If the root element isn't <html>, watch for theme class changes and sync them. This ensures feature developers
    // don't have to do anything special to support dark mode aside from applying the theme to their feature.
    if (this.rootElement !== document.documentElement) {
      const syncTheme = () => {
        const isDark = document.documentElement.classList.contains('he-theme-dark');
        this.rootElement.classList.toggle('he-theme-dark', isDark);
        this.rootElement.classList.toggle('he-theme-light', !isDark);
      };

      const observer = new MutationObserver(() => syncTheme());
      observer.observe(document.documentElement, {
        attributes: true,
        attributeFilter: ['class'],
      });

      syncTheme();
    }
  }

  /** @deprecated Applies a theme to the root element. */
  applyTheme(theme: CSSResult) {
    const targetElement = this.rootElement === document.documentElement ? document.head : this.rootElement;
    const style = document.createElement('style');
    this.rootElement.classList.add('he-theme-light');
    style.textContent = this.getScopedStyles([theme]);
    targetElement.prepend(style);
  }

  /** Applies styles to the root element. */
  applyStyles(styles: CSSResult[], rootElement = this.rootElement) {
    const targetElement = rootElement === document.documentElement ? document.head : this.rootElement;
    const style = document.createElement('style');
    rootElement.classList.add('he-theme-light');
    style.textContent = this.getScopedStyles(styles);
    targetElement.prepend(style);
  }

  /** Registers a component in the current scope. */
  registerComponent(...components: Array<typeof HarmonyElement>) {
    components.forEach(component => {
      const baseName = component.baseName;
      const tagName = `${this.prefix}-${baseName}`;

      // If the component has already been registered, we're done here
      if (tagMap.has(tagName)) {
        return;
      }

      if (!baseName) {
        console.error(`
					No basename set for: ${tagName}.
					The component does not have: static baseName = '<name>' in its class definition.`);
        return;
      }

      // Register the component
      tagMap.set(tagName, this);

      baseNameMap.set(baseName, component);

      if (!customElements.get(tagName)) customElements.define(tagName, class extends component {});

      trackComponent(this.prefix, component);
    });
  }

  /** Gets a registered component. */
  getComponent(baseName: string) {
    return baseNameMap.get(baseName);
  }

  /** Returns the base name of any scoped element. */
  getBaseName(elementOrTagName: Element | string) {
    const tagName = elementOrTagName instanceof Element ? elementOrTagName.tagName.toLowerCase() : elementOrTagName;
    return tagName.replace(/^((.*)_)?he-/i, '');
  }

  /** Generates a React-wrapped component. A "reactInstance" must be set before calling this method. */
  forReact<T extends typeof HarmonyElement>(_component: T) {
    throw new Error(
      "Please use the '@harmony/enablers/react' entrypoint if you are going to be using the React wrappers."
    );
  }

  reactInstanceMustBeSet() {
    if (!this.reactInstance) {
      throw new Error(
        'To use React-wrapped components, you must provide an instance of React to the "reactInstance" property when you create the scope.'
      );
    }
  }

  /** Sets the React instance. Useful if you can't set it during instantiation. */
  setReactInstance(reactInstance: any) {
    this.reactInstance = reactInstance;
  }

  /** Returns the library's base path. If a path is provided, it will be joined to form a full path to the asset. */
  makePath(path?: string) {
    const basePath = this.basePath.replace(/\/$/, '');
    return path ? basePath + '/' + path : basePath;
  }

  /**
   * Converts a base name such as "button" to a scoped tag name such as "he-button" or "prefix_he-button". Useful for
   * generating selectors for scoped tags for use in JavaScript and CSS. Avoid using this function inside templates.
   * Instead, use scope.tag() to generate a static Lit template tag.
   */
  tagName(baseName: string): string {
    if (baseName === null) {
      throw Error('No base name given');
    }

    if (baseName.startsWith('he-')) {
      console.error(
        `Invalid base name: "${baseName}". Avoid using the "he-" prefix when calling scope.tag() or scope.tagName().`
      );
      baseName = baseName.replace(/^he-/, '');
    }

    return `${this.prefix}-${baseName}`;
  }

  /**
   * Converts a base name such as "button" to a scoped static template tag that can be used inside templates. Use this
   * instead of tagName() when composing templates, because Lit doesn't allow strings to be interpolated as tag names.
   */
  tag(baseName: string): StaticValue {
    return unsafeStatic(this.tagName(baseName)) as StaticValue;
  }

  /** If components are scoped, it returns a scoped version of the selected theme. */
  private getScopedStyles(styles: CSSResult[]): string {
    const prefix = this.prefix?.replace('_he', '') || '';

    return styles
      .map(x => {
        /** return if there are not style to scope */
        if (!x) {
          return '';
        }

        const minifiedStyles = x
          .toString()
          .replace(/*JSBlockComments*/ /\/\*[\S\s]*?\*\//gm, '')
          .replace(/\n/g, '')
          .replace(/\s\s/g, '')
          .trim();

        /** return the default styles if no scope is being used */
        if (!prefix || prefix === 'he') {
          return minifiedStyles;
        }

        /** scope component styles */
        return (
          minifiedStyles
            .toString()
            .replace(/:root,/g, '')
            .replace(/.he-theme-light/g, `[scope-${prefix}].he-theme-light`)
            .replace(/.he-theme-dark/g, `[scope-${prefix}].he-theme-dark`) || ''
        );
      })
      .join('');
  }
}

//
// Partner Center has a pattern of applying dark mode using <body class="darkMode"> instead of the preferred syntax
// <html class="he-theme-dark">. For compatibility reasons, we sync their class to ours in that environment.
//
function isPartnerCenter(): boolean {
  const origin = window.location.origin.toLowerCase();
  return (
    origin.includes('partner.microsoft-ppe.com') ||
    origin.includes('partner.microsoft-int.com') ||
    origin.includes('partner.microsoft-tst.com') ||
    origin.includes('partner.microsoft.com') ||
    origin.includes('partner.partnercenter.microsoftonline.cn')
  );
}

if (isPartnerCenter()) {
  const syncTheme = () => {
    const isDark = document.body.classList.contains('darkMode');
    document.documentElement.classList.toggle('he-theme-dark', isDark);
    document.documentElement.classList.toggle('he-theme-light', !isDark);
  };

  const observer = new MutationObserver(() => syncTheme());
  observer.observe(document.body, {
    attributes: true,
    attributeFilter: ['class'],
  });

  // Initial sync
  if (document.readyState === 'complete') {
    syncTheme();
  } else {
    window.addEventListener('DOMContentLoaded', syncTheme);
  }
}
