export default {
  "back": "Anterior",
  "breadcrumb": "Trilho de navegação",
  "clear": "Limpar",
  "clear_entry": "Limpar entrada",
  "close": "Fechar",
  "collapse_task_menu": "Fechar menu de tarefas",
  "collapse": "Fechar",
  "commands": "Comandos",
  "complete": "Concluído",
  "day": "Dia",
  "dislike": "Não Gosto",
  "error": "Erro",
  "expand_task_menu": "Expandir menu de tarefas",
  "expand": "Expandir",
  "filter": "Filtrar",
  "finish": "Concluir",
  "first": "Primeiro",
  "go_to_page": "Ir para a página {0}",
  "hide_password": "Ocultar palavra-passe",
  "last": "Último",
  "like": "Gosto",
  "loading": "A carregar...",
  "month": "Mês",
  "more_options": "Mais Opções",
  "navigation": "Navegação",
  "next": "Seguinte",
  "next_month": "Mês seguinte",
  "not_started": "Não iniciado",
  "off": "Desligado",
  "on": "Ligado",
  "open_in_new_window": "Abrir numa janela nova",
  "options": "Opções",
  "pagination": "Paginação",
  "plus_x_more": "+{0}",
  "previous": "Anterior",
  "previous_month": "Mês anterior",
  "progress": "Em curso",
  "quick_start": "Início rápido",
  "range_error_message": "A data que selecionou não está dentro do intervalo de datas",
  "required_error_message": "Este campo é obrigatório",
  "results_found": "{0} resultados encontrados",
  "search": "Procurar",
  "select_all": "Selecionar tudo",
  "select_row": "Selecionar linha",
  "show_calendar": "Mostrar calendário",
  "show_password": "Mostrar palavra-passe",
  "step_x_of_y": "Passo {0} de {1}",
  "today": "Hoje",
  "unavailable_date_error_message": "A data que selecionou não está disponível",
  "warning": "Aviso",
  "year": "Ano"
}