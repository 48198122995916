import { getPortalTypeIdentifier } from './context/environment-helper';
import LibraryUsageContext from './context/library-usage-context';

const partnerPortal = 'partner';
const vNextFDType = 'vnext';
const FDV1Type = 'v1';

/**
 * It is used to determine the portal config. Right now we are supporting 2 portal config that is partner portal and multi portal
 * FD. This is based on the fact that there are
 */
export enum PortalConfig {
  PartnerPortal,
  // Multiportal FD
  HarmonyPortal,
}

/**
 * This is used to determine the Portal Config.
 * [IMP]: This is meant to be an internal implementation and should not be exposed to teams.
 * No matter what is the requirement of these teams.
 *
 * @unstable
 */
export const determinePortalConfig = async () => {
  const domain = await getPortalTypeIdentifier();
  switch (domain) {
    case partnerPortal: {
      const fdType = LibraryUsageContext.getInstance().FDType?.toLowerCase();
      if (fdType === FDV1Type || fdType === vNextFDType) {
        return PortalConfig.PartnerPortal;
      }
    }
    default: {
      return PortalConfig.HarmonyPortal;
    }
  }
};
