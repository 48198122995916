export default {
  "back": "Nazad",
  "breadcrumb": "Putanja",
  "clear": "Obriši",
  "clear_entry": "Obriši stavku",
  "close": "Zatvori",
  "collapse_task_menu": "Skupi meni zadatka",
  "collapse": "Skupi",
  "commands": "Komande",
  "complete": "Dovršite",
  "day": "Dan",
  "dislike": "Ne sviđa mi se",
  "error": "Greška",
  "expand_task_menu": "Razvij meni zadatka",
  "expand": "Razvij",
  "filter": "Filtriraj",
  "finish": "Završi",
  "first": "Prvo",
  "go_to_page": "Idi na stranicu {0}",
  "hide_password": "Sakrij lozinku",
  "last": "Poslednje",
  "like": "Sviđa mi se",
  "loading": "Učitavanje...",
  "month": "Mesec",
  "more_options": "Još opcija",
  "navigation": "Navigacija",
  "next": "Dalje",
  "next_month": "Sledeći mesec",
  "not_started": "Nije započeto",
  "off": "Isključeno",
  "on": "Uključeno",
  "open_in_new_window": "Otvori u novom prozoru",
  "options": "Opcije",
  "pagination": "Numerisanje stranica",
  "plus_x_more": "i još {0}",
  "previous": "Prethodno",
  "previous_month": "Prethodni mesec",
  "progress": "U toku",
  "quick_start": "Brzi start",
  "range_error_message": "Datum koji ste izabrali nije u opsegu datuma",
  "required_error_message": "Ovo polje je obavezno",
  "results_found": "Pronađeno je ovoliko rezultata: {0}",
  "search": "Pretraži",
  "select_all": "Izaberi sve",
  "select_row": "Izaberite red",
  "show_calendar": "Prikaži kalendar",
  "show_password": "Prikaži lozinku",
  "step_x_of_y": "Korak {0} od {1}",
  "today": "Danas",
  "unavailable_date_error_message": "Datum koji ste izabrali nije dostupan",
  "warning": "Upozorenje",
  "year": "Godina"
}