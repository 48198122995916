import { html } from 'lit/static-html.js';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import icon from '../../components/icon/icon';
import { defaultValue } from '../../internal/default-value';
import { getScope } from '../../utilities/scope';
import { FormSubmitController } from '../../internal/form';
import { HasSlotController } from '../../internal/slot';
import { watch } from '../../internal/watch';
import HarmonyElement from '../';
import { IHarmonyInput } from '../../internal/interfaces/input-interfaces';
import styles from './search-box.styles';

/**
 * @tag he-search-box
 * @since 3.0
 * @status stable
 * @figma https://www.figma.com/file/dRwBPvZFZdYgWdAOCK375K/PC-Toolkit?node-id=5%3A896
 *
 * @dependency he-icon
 *
 * @slot label - The input's label. Alternatively, you can use the label prop.
 * @slot help-text - Help text that describes how to use the input. Alternatively, you can use the `help-text` attribute.
 *
 * @event he-ready - Emitted when the component has completed its initial render.
 * @event he-change - Emitted when an alteration to the control's value is committed. This event also fires when the control is cleared or the `Enter` key is pressed.
 * @event he-search - Emitted when the user presses the `Enter` key or when the the clear button is used.
 * @event he-clear - Emitted when the clear button is activated.
 * @event he-input - Emitted when the control receives input and its value changes.
 * @event he-focus - Emitted when the control gains focus.
 * @event he-blur - Emitted when the control loses focus.
 *
 * @csspart form-control - The form control that wraps the label, input, and help-text.
 * @csspart form-control-label - The label's wrapper.
 * @csspart form-control-input - The input's wrapper.
 * @csspart form-control-help-text - The help text's wrapper.
 * @csspart form-control-error-text - The form control's error text
 * @csspart base - The component's internal wrapper.
 * @csspart input - The input control.
 * @csspart search-icon - The input prefix container.
 * @csspart clear-button - The clear button.
 */
export class SearchBox extends HarmonyElement implements IHarmonyInput {
  static styles = styles;
  static baseName = 'search-box';
  static reactEvents = {
    onHeReady: new CustomEvent('he-ready'),
    onHeBlur: new CustomEvent('he-blur'),
    onHeChange: new CustomEvent('he-change'),
    onHeClear: new CustomEvent('he-clear'),
    onHeFocus: new CustomEvent('he-focus'),
    onHeInput: new CustomEvent('he-input'),
    onHeSearch: new CustomEvent('he-search'),
  };

  @query('.search-box__control') input?: HTMLInputElement;

  private readonly formSubmitController = new FormSubmitController(this);
  private readonly hasSlotController = new HasSlotController(this, 'help-text', 'label');
  private scope = getScope(this);

  @state() private hasFocus = false;
  @state() private hadFocus = false;

  /** The input's type. */
  @property({ reflect: true }) type:
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'time'
    | 'url' = 'text';

  /** The input's name attribute. */
  @property() name: string;

  /** The input's value attribute. */
  @property() value = '';

  /** @internal Gets or sets the default value used to reset this element. The initial value corresponds to the one originally specified in the HTML that created this element. */
  @defaultValue()
  defaultValue = '';

  /** The input's label. Alternatively, you can use the label slot. */
  @property() label = '';

  /** The input's help text. Alternatively, you can use the help-text slot. */
  @property({ attribute: 'help-text' }) helpText = '';

  /** The input's placeholder text. */
  @property() placeholder: string;

  /** Disables the input. */
  @property({ type: Boolean, reflect: true }) disabled = false;

  /** Makes the input readonly. */
  @property({ type: Boolean, reflect: true }) readonly = false;

  /** The minimum length of input that will be considered valid. */
  @property({ type: Number }) minlength: number;

  /** The maximum length of input that will be considered valid. */
  @property({ type: Number }) maxlength: number;

  /** A pattern to validate input against. */
  @property() pattern: string;

  /** Makes the input a required field. */
  @property({ type: Boolean, reflect: true }) required = false;

  /**
   * This will be true when the control is in an invalid state. Validity is determined by props such as `type`,
   * `required`, `minlength`, `maxlength`, and `pattern` using the browser's constraint validation API.
   */
  @property({ type: Boolean, reflect: true }) invalid = false;

  /** Controls whether and how text input is automatically capitalized as it is entered/edited by the user. */
  @property() autocapitalize: 'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters';

  /**
   * (Non-standard - Safari only). A string which indicates whether to activate automatic correction while the user
   * is editing this field.
   */
  @property() autocorrect: string;

  /**
   * Permission the user agent has to provide automated assistance in filling out form field values and the type of
   * information expected in the field.
   */
  @property() autocomplete: string;

  /** Focus on the input on page load. */
  @property({ type: Boolean, reflect: true }) autofocus: boolean;

  /**
   * Used to customize the label or icon of the Enter key on virtual keyboards.
   */
  @property() enterkeyhint: 'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send';

  /** Enables spell checking on the input. */
  @property({ type: Boolean, reflect: true }) spellcheck: boolean;

  /** Hints at the type of data that might be entered by the user while editing the element or its contents. */
  @property() inputmode: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';

  constructor() {
    super();
    this.scope.registerComponent(icon);

    // Set a default, localized placeholder if one hasn't been provided
    if (!this.placeholder) {
      this.placeholder = this.localize.term('search');
    }
  }

  firstUpdated() {
    this.invalid = !this.input?.checkValidity();
  }

  /** Sets focus on the input. */
  focus(options?: FocusOptions) {
    this.input?.focus(options);
  }

  /** Removes focus from the input. */
  blur() {
    this.input?.blur();
  }

  /** Selects all the text in the input. */
  select() {
    this.input?.select();
  }

  /** Sets the start and end positions of the text selection (0-based). */
  setSelectionRange(
    selectionStart: number,
    selectionEnd: number,
    selectionDirection: 'forward' | 'backward' | 'none' = 'none'
  ) {
    this.input?.setSelectionRange(selectionStart, selectionEnd, selectionDirection);
  }

  /** Replaces a range of text with a new string. */
  setRangeText(
    replacement: string,
    start: number,
    end: number,
    selectMode: 'select' | 'start' | 'end' | 'preserve' = 'preserve'
  ) {
    this.input?.setRangeText(replacement, start, end, selectMode);

    if (this.input && this.value !== this.input?.value) {
      this.value = this.input.value;
      this.emit('he-input');
      this.emit('he-change');
    }
  }

  /** Checks for validity but doesn't report a validation message when invalid. */
  checkValidity() {
    return this.input?.checkValidity();
  }

  /** Gets the current validation message, if one exists. */
  get validationMessage() {
    return this.input?.validationMessage;
  }

  /** Checks for validity and shows the browser's validation message if the control is invalid. */
  reportValidity() {
    return this.input?.reportValidity();
  }

  /** Sets a custom validation message. If `message` is not empty, the field will be considered invalid. */
  setCustomValidity(message: string) {
    this.hadFocus = true;
    this.input?.setCustomValidity(message);
    this.invalid = !this.input?.checkValidity();
  }

  private handleBlur() {
    this.hasFocus = false;
    this.emit('he-blur');
  }

  private handleChange() {
    if (this.input && this.value !== this.input.value) {
      this.value = this.input.value;
      this.emit('he-change');
    }
  }

  private handleClearClick(event: MouseEvent) {
    this.value = '';
    this.emit('he-clear');
    this.emit('he-input');
    this.emit('he-change');
    this.emit('he-search');
    this.input?.focus();

    event.stopPropagation();
  }

  @watch('disabled', { waitUntilFirstUpdate: true })
  handleDisabledChange() {
    // Disabled form controls are always valid, so we need to recheck validity when the state changes
    this.input!.disabled = this.disabled;
    this.invalid = !this.input!.checkValidity();
  }

  private handleFocus() {
    this.hasFocus = true;
    this.hadFocus = true;
    this.emit('he-focus');
  }

  private handleInput() {
    this.value = this.input!.value;
    this.emit('he-input');
  }

  private handleInvalid() {
    this.invalid = true;
  }

  private handleKeyDown(event: KeyboardEvent) {
    const hasModifier = event.metaKey || event.ctrlKey || event.shiftKey || event.altKey;

    // Pressing enter when focused on an input should submit the form like a native input, but we wait a tick before
    // submitting to allow users to cancel the keydown event if they need to
    if (event.key === 'Enter' && !hasModifier) {
      this.emit('he-change');
      this.emit('he-search');
      setTimeout(() => {
        if (!event.defaultPrevented) {
          this.formSubmitController.submit();
        }
      });
    }
  }

  @watch('value', { waitUntilFirstUpdate: true })
  handleValueChange() {
    this.invalid = !this.input?.checkValidity();
  }

  render() {
    const hasLabelSlot = this.hasSlotController.test('label');
    const hasHelpTextSlot = this.hasSlotController.test('help-text');
    const hasLabel = this.label ? true : !!hasLabelSlot;
    const hasHelpText = this.helpText ? true : !!hasHelpTextSlot;
    const hasClearIcon = !this.disabled && !this.readonly && (typeof this.value === 'number' || this.value.length > 0);

    return html`
      <div
        part="form-control"
        class=${classMap({
          'form-control': true,
          'form-control--has-label': hasLabel,
          'form-control--has-help-text': hasHelpText,
          'form-control--has-error': this.invalid,
          'form-control--has-interaction': this.hadFocus,
        })}
      >
        <label
          part="form-control-label"
          class="form-control__label"
          for="input"
          aria-hidden=${hasLabel ? 'false' : 'true'}
        >
          <slot name="label">${this.label}</slot>
        </label>
        <div part="form-control-input" class="form-control-input">
          <div
            part="base"
            class=${classMap({
              'search-box': true,
              'search-box--disabled': this.disabled,
              'search-box--focused': this.hasFocus,
              'search-box--empty': !this.value,
              'search-box--invalid': this.invalid,
            })}
          >
            <span part="search-icon" class="search-box__search-icon">
              <${this.scope.tag('icon')} name="search">
              </${this.scope.tag('icon')}>
            </span>
            <input
              part="input"
              id="input"
              class="search-box__control"
              type="search"
              name=${ifDefined(this.name)}
              ?disabled=${this.disabled}
              ?readonly=${this.readonly}
              ?required=${this.required}
              placeholder=${ifDefined(this.placeholder)}
              minlength=${ifDefined(this.minlength)}
              maxlength=${ifDefined(this.maxlength)}
              .value=${live(this.value)}
              autocapitalize=${ifDefined(this.autocapitalize)}
              autocomplete=${ifDefined(this.autocomplete)}
              autocorrect=${ifDefined(this.autocorrect)}
              ?autofocus=${this.autofocus}
              spellcheck=${ifDefined(this.spellcheck)}
              pattern=${ifDefined(this.pattern)}
              enterkeyhint=${ifDefined(this.enterkeyhint)}
              inputmode=${ifDefined(this.inputmode)}
              aria-describedby="help-text"
              aria-invalid=${this.invalid ? 'true' : 'false'}
              @change=${this.handleChange}
              @input=${this.handleInput}
              @invalid=${this.handleInvalid}
              @keydown=${this.handleKeyDown}
              @focus=${this.handleFocus}
              @blur=${this.handleBlur}
            />
            ${
              hasClearIcon
                ? html`
                  <button
                    part="clear-button"
                    class="search-box__clear"
                    type="button"
                    aria-label=${this.localize.term('clear_entry')}
                    @click=${this.handleClearClick}
                    tabindex="-1"
                  >
                    <${this.scope.tag('icon')} name="clear" library="system" class="search-box__clear-icon">
                    </${this.scope.tag('icon')}>
                  </button>
                `
                : ''
            }
          </div>
        </div>
        <div
          part="form-control-help-text"
          id="help-text"
          class="form-control__help-text"
          aria-hidden=${hasHelpText ? 'false' : 'true'}
        >
          <slot name="help-text">${this.helpText}</slot>
        </div>
        <div
          part="form-control-error-text"
          id="error-text"
          class="form-control__error-text"
          aria-live="assertive"
          role="alert"
        ></div>
      </div>
    `;
  }
}

export default SearchBox;
